<template>
    <div class="container-fluid my-3 py-3">
        <div class="row">
            <div class="col-12 mb-3">
                <div class="form-floating">
                    <input id="inputUsername" type="text" name="username" class="form-control" @keyup.enter="submitForm" v-model="username" placeholder="name@examp" required>
                    <label for="inputUsername" class="form-label" >{{ $t("username") }}</label>                
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputFirstname" type="text" name="first_name" class="form-control" @keyup.enter="submitForm" v-model="first_name" placeholder="name@examp" required>
                    <label for="inputFirstname" class="form-label" >{{ $t("first_name") }}</label>                
                </div>
            </div>
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputFirstname" type="text" name="last_name" class="form-control" @keyup.enter="submitForm" v-model="last_name" placeholder="name@examp" required>
                    <label for="inputFirstname" class="form-label" >{{ $t("last_name") }}</label>                
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inpuEmail" type="email" name="username" class="form-control" @keyup.enter="submitForm" v-model="email" placeholder="name@examp" required>
                    <label for="inputEmail" class="form-label" >{{ $t("email") }}</label>                
                </div>
            </div>
            <div class="col-6">
                <select class="form-select " aria-label="Default select example" v-model="authgroup">
                    <option  v-for="group in this.authgroups.authgroups" 
                        v-bind:key="group.id" v-bind:value="group.id">{{group.grp_name}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-3">
            <div class="input-group">
                <div class="form-floating form-floating-group flex-grow-1">
                    <input ref="password" id="inputPassword" @keyup.enter="submitForm" :type="passwordFieldType" name="password" class="form-control" v-model="password" aria-describedby="hideUnhide" placeholder="test">
                    <label for="inputPassword" class="form-label">{{ $t("Passwort") }}</label>
                </div>
                <span class="input-group-text" id="hideUnhide" @click="switchVisibility">
                    <i class="fa-solid fa-eye" v-if='this.passwordFieldType === "password"'></i>
                    <i class="fa-solid fa-eye-slash" v-if='this.passwordFieldType === "text"'></i>
                </span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputPhone" type="text" name="username" class="form-control" @keyup.enter="submitForm" v-model="phone_number" placeholder="name@examp" required>
                    <label for="inputPhone" class="form-label" >{{ $t("phone") }}</label>
                </div>
            </div>
        </div>
        <div class="text-danger" v-if="errors.length">
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'AddUser',
    props: {
        organisation: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            username: '',
            email: '',
            password: '',
            passwordFieldType: "password",
            errors: [],
            authgroups: [],
            authgroup: '',
            phone_number: '',
            first_name: '',
            last_name: '',
        }
    },
    mounted(){
        this.getAuthgroups()
    },
    methods: {
        getAuthgroups(){
            console.log('entry getAuthgroups')
            axios
                .get(`/api/v1/organisation/${this.organisation.id}/get_authgroups_user/`)
                .then(response => {
                    this.authgroups = response.data
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }

                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getAuthgroups')
        },
        submitForm() {
            this.errors = []
            const formData = {
                username: this.username,
                email: this.email,
                password: this.password,
                organisation: this.organisation.id,
                grp_name: this.authgroup,
                phone_number: this.phone_number,
                last_name: this.last_name,
                first_name: this.first_name
            }
            axios
                .post("/api/v1/users/", formData)
                .then(response => {
                    
                    createToast({text: this.$t("successNewUser")
                   ,type: 'success'})
                    this.username = ''
                    this.email= ''
                    this.password= ''
                    this.authgroup= ''
                    this.phone_number= ''
                    this.first_name= ''
                    this.last_name= ''
                   this.$emit('closeModal')
                })
                .catch(error => {
                    this.loading = false
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }                       
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            
        },
        switchVisibility(){
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            this.$refs.password.focus();
        }
    }
}
</script>