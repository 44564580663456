<template>
    <div class="page-organisation">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="breadcrumb-item "><router-link to="/settings/masterdata/organisations" aria-current="true">{{ $t("organisations") }}</router-link></li>
                <li class="breadcrumb-item active"><router-link :to="{ name: 'Organisation', params: { id: this.$route.params.id }}" aria-current="true">{{ organisation.name }}</router-link></li>
            </ol>
        </nav>

        <div class="">
            <div class="">
                <h1 class="">{{ organisation.name}}</h1>

                
            </div>
        </div>

        <EditOrganisation
            v-bind:organisation="organisation"
        >
        </EditOrganisation>
        
        <div class="row mt-3">
            <div class="col-6">
                <table class="table table-striped table-hover clickable table-sm">
                    <thead>
                        <tr>
                            <th>{{ $t("user") }}</th>
                            <th>{{ $t("userRole") }}</th>
                            <th> <button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modal" @click="addUser()">{{ $t("newUser") }}</button></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="user in organisation.users"
                            v-bind:key="user.id"
                            @click="editUser(user)"
                            data-bs-toggle="modal" data-bs-target="#modal"
                        >
                            <td>{{ user.username }}</td>
                            <td>{{ user.grp_name_bez }}</td>
                            <td>
                                
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-6" v-if="organisation">
                <PlantPriceOverview
                    v-bind:organisation_id="this.$route.params.id"
                >
                </PlantPriceOverview>
            </div>
        </div>

        <OrganisationKpiConfig v-bind:organization="this.organisation" v-if="this.organisation.plants"></OrganisationKpiConfig>

    </div>

    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                <button type="button" id="modalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body modal-textarea">
                <component v-if="currentComponent" :is="currentComponent" v-bind="currentProperties" v-on:closeModal="closeModal"></component>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
            </div>
        </div>
      </div>
    </div>
</template>

<script>    
import axios from 'axios'

import EditOrganisation from '@/components/masterdata/EditOrganisation.vue'
import PlantPriceOverview from './PlantPriceOverview.vue'
import AddUser from '@/components/masterdata/AddUser.vue'
import EditUser from '@/components/masterdata/EditUser.vue'
import AddPlant from '@/components/masterdata/AddPlant.vue'
import OrganisationKpiConfig from '@/components/kpi/OrganisationKpiConfig.vue'

export default {
    name: 'Organisation',
    components: {
        EditOrganisation,
        AddUser,
        AddPlant,
        EditUser,
        PlantPriceOverview,
        OrganisationKpiConfig
    },
    data() {
        return {
            currentComponent: "",
            organisation: {},
            currentUser: ''
        }
    },
    async mounted(){
        await this.getOrganisation()
    },
    methods: {
        update_plant_price(plant_price){
            console.log('entry update_plant_price', plant_price)
            axios
            .patch(`/api/v1/plant_price_overview/${plant_price.id}/`,plant_price)
            .then(response => {
                console.log('price updated')               
            })
            .catch(error => {
                console.log(JSON.stringify(error))
            })
            console.log('exit update_plant_price', plant_price)
        },
        editUser(user){
            console.log('entry editUser')
            this.currentComponent = "EditUser"
            this.currentUser = user
            console.log('exit editUser')
        },
        addUser(){
            console.log('entry addUser')
            this.currentComponent = "AddUser"
            console.log('exit addUser')
        },
        closeModal(){
            console.log("closeModal")
            document.getElementById('modalClose').click();
            console.log("reload")
            this.getOrganisation()
        },
        getOrganisation() {
            const organisationID = this.$route.params.id

            axios
                .get(`/api/v1/allOrganisations/${organisationID}/`)
                .then(response => {
                    this.organisation = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },

    },
    computed:{
        currentProperties(){
            return {organisation: this.organisation,
                    user: this.currentUser}
        },
        getModalName(){
            
        }
    }
}
</script>