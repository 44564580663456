<template>
    <p class="mt-3 text-danger">Bitte beachten Sie, dass das Betriebstagebuch nächtlich aktualisiert wird. Eine Anpassung ist somit erst am nächsten Tag ersichtlich</p>
     <div class="row mt-3">
        <div class="col-4">
            <ul class="list-group ">
                <li :class="{'active': this.activeHeader === header}" class="list-group-item" v-for="header in plantSystemJournal.journal_headers"
                v-bind:key="header.id" >
                    <div class="row">
                        <div class="col-8 cursor-pointer" @click="setActiveHeader(header)">
                            {{header.name}}
                        </div>
                        <div class="col-1">
                            <i class="fa-solid fa-arrow-up" @click="moveHeaderUp(header)"></i>
                        </div>
                        <div class="col-1">
                            <i class="fa-solid fa-arrow-down" @click="moveHeaderDown(header)"></i>
                        </div>
                        <div class="col-1">
                            <i class="fa-solid fa-pencil" @click="editHeader(header)" data-bs-toggle="modal" data-bs-target="#modalHeader"></i>
                        </div>
                        <div class="col-1">
                            <i class="fa-solid fa-trash" @click="deleteHeader(header)"></i>
                        </div>
                    </div>
                        
                </li>
                <li class="list-group-item cursor-pointer" @click="setNewHeader()"
                            data-bs-toggle="modal" data-bs-target="#modalHeader">
                    <i class="fa-solid fa-plus" ></i>
                </li>
            </ul>
        </div>
        <div class="col-6">
            <EditPlantJournalPositions v-if="this.activeHeader" v-bind="currentProperties" />
        </div>
    </div>
    <div class="modal fade" id="modalHeader" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                    <button type="button" id="modalHeaderClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component v-if="this.currentComponent" :is="currentComponent" v-bind="currentPropertiesHeaders" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
import EditPlantJournalPositions from '@/components/PlantJournals/EditPlantJournalPositions.vue'
import AddPlantJournalHeader from '@/components/PlantJournals/AddPlantJournalHeader.vue'
import EditPlantJournalHeader from '@/components/PlantJournals/EditPlantJournalHeader.vue'
export default {
    name: 'EditPlantJournalForSystem',
    emits: [],
    components: {
        EditPlantJournalPositions,
        AddPlantJournalHeader,
        EditPlantJournalHeader
        },
    props: {
        plantSystemJournalId: {
            type: Number,
            required: true,
        },
        
    },
    data() {
        return {
            activeHeader: null,
            currentComponent: "",
            plantSystemJournal: {},
            plantSystemJournalIdOld: 0
        }
    },
    mounted(){
        this.getPlantSystemJournalFromBackend()
    },
    updated(){
        console.log('entry updated')
        if(this.plantSystemJournalIdOld === 0){
            this.plantSystemJournalIdOld = this.plantSystemJournalId
        }
        if(this.plantSystemJournalIdOld != this.plantSystemJournalId){
            this.getPlantSystemJournalFromBackend()
            this.plantSystemJournalIdOld = this.plantSystemJournalId
            this.activeHeader = null
        }
        console.log('exit updated')
    },
    methods: {
        getPlantSystemJournalFromBackend(){
            console.log('entry getPlantSystemJournalFromBackend')
            axios
                .get(`/api/v1/plantsystemjournals/${this.plantSystemJournalId}/`)
                .then(response => {
                    console.log(response)
                    this.plantSystemJournal = response.data
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantSystemJournalFromBackend')
        },
        closeModal(action, element){
            console.log("closeModal")
            document.getElementById('modalHeaderClose').click();
            if(action === 'add'){
                this.plantSystemJournal.journal_headers.push(element)
            }
        },
        sortHeaderArray(){
            console.log('entry sortHeaderArray')
            this.plantSystemJournal.journal_headers = this.plantSystemJournal.journal_headers.sort((a,b) => {
                if(a.position < b.position){
                    return -1
                }
                if (a.position > b.position){
                    return 1
                }
                return 0
            })
            this.updateHeadersBackend()
            console.log('exit sortHeaderArray')
        },
        setNewHeader(){
            console.log('entry setNewHeader')
            this.currentComponent = "AddPlantJournalHeader",
            console.log('exit setNewHeader')
        },
        editHeader(header){
            console.log('entry editHeader')
            this.currentComponent = 'EditPlantJournalHeader'
            this.activeHeader = header
            console.log('exit editHeader')
        },
        deleteHeader(header){
            console.log('entry deleteHeader')
            axios
                .delete(`/api/v1/plant_system_journal_headers/${header.id}/`)
                .then(response => {
                    createToast({text: this.$t("header deleted")
                    ,type: 'success'})
                    this.deleteHeaderFromFrontend(header)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            this.activeHeader = ''
            console.log('exit deleteHeader')
        },
        deleteHeaderFromFrontend(header){
            console.log('entry deleteHeaderFromFrontend')
            let position = 1
            let pos_delete = this.plantSystemJournal.journal_headers.map(e => e.id).indexOf(header.id)
            console.log('pos_delete: ' + pos_delete)
            this.plantSystemJournal.journal_headers.splice(pos_delete, 1)
            this.plantSystemJournal.journal_headers.forEach(element => {
                element.position = position
                position++
                axios
                    .patch(`/api/v1/plant_system_journal_headers/${element.id}/`, element)
                    .then(response => {
                        console.log('response')
                        console.log(response.data)
                    })
                    .catch(error => {
                        if (error.response) {
                            if ('detail' in error.response.data){
                                createToast({text: this.$t(error.response.data['detail'])
                                            ,type: 'error'})
                            }                        
                            console.error(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            console.error(JSON.stringify(error.message))
                        } else {
                            console.error(JSON.stringify(error))
                        }
                    })
            })
            console.log('exit deleteHeaderFromFrontend')
        },
        moveHeaderUp(header){
            console.log('entry moveHeaderUp')
            if(header.position < 2){
                return
            }
            let header_to_switch = this.plantSystemJournal.journal_headers.filter(array_header => array_header.position === header.position -1)

            header_to_switch.forEach((value,index) =>{
                value.position = header.position
            })
            header.position--
            this.sortHeaderArray()
            console.log('exit moveHeaderUp')
        },
        moveHeaderDown(header){
            console.log('entry moveHeaderDown')
            if(header.position >= this.plantSystemJournal.journal_headers.length){
                return
            }
            let header_to_switch = this.plantSystemJournal.journal_headers.filter(array_header => array_header.position === header.position +1)

            header_to_switch.forEach((value,index) =>{
                value.position = header.position
            })
            header.position++
            this.sortHeaderArray()
            console.log('exit moveHeaderDown')
        },
        setActiveHeader(header){
            console.log('entry setActiveHeader')
            this.activeHeader = header
            console.log('exit setActiveHeader')
        },
        updateHeadersBackend(){
            console.log('entry updateHeadersBackend')
            this.plantSystemJournal.journal_headers.forEach(element => {
                axios
                    .patch(`/api/v1/plant_system_journal_headers/${element.id}/`, element)
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        if (error.response) {
                            if ('detail' in error.response.data){
                                createToast({text: this.$t(error.response.data['detail'])
                                            ,type: 'error'})
                            }                        
                            console.error(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            console.error(JSON.stringify(error.message))
                        } else {
                            console.error(JSON.stringify(error))
                        }
                    })
                })
            console.log('exit updateHeadersBackend')
        }
        
    },
    computed:{
        currentProperties(){
                return {plantSystemHeaderId: this.activeHeader.id,
                    organisation: this.$store.state.organisation, }
            },
        currentPropertiesHeaders(){
            
            return {plantSystemJournal: this.plantSystemJournal, 
                    position: this.plantSystemJournal.journal_headers.length +1,
                    plantJournalHeader: this.activeHeader
                }
        },
    }
}
</script>