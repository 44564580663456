<template>
   <div class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
        <NavPlantSystemsSidebar v-if="allPlantSystems" v-bind="currentPlantSystemsProperties" 
            v-on:setSystem="setSystem" v-on:newSystem="newSystem" v-on:editSystem="editSystem"></NavPlantSystemsSidebar>
    </div>
    <div class="col-md-9 ms-sm-auto col-lg-10">
        <component :is="currentTabComponent" v-if="this.currentSystem" v-bind="currentProperties"></component>
    </div>
    <button id="modalOpen" style="display:none;" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modalPlantSystem" aria-hidden="true">hidden</button>
    <div class="modal fade" id="modalPlantSystem" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                    <button type="button" id="modalPlantSystemClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component v-if="allPlantSystems" :is="currentComponent" v-bind="currentModalProperties" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
import NavPlantSystemsSidebar from '@/components/navs/NavPlantSystemsSidebar.vue'
import AddSystem from '@/components/PlantSystems/AddSystem'
import EditSystemOrganisationMapping from '@/components/PlantSystems/EditSystemOrganisationMapping'
import EditSystem from '@/components/PlantSystems/EditSystem'
import axios from 'axios'
export default {
    name: 'PlantSystems',
    components: {
        EditSystemOrganisationMapping,
        NavPlantSystemsSidebar,
        AddSystem,
        EditSystem
    },
    data() {
        return {
            currentSystem: "",
            currentTabComponent: "EditSystemOrganisationMapping",
            currentComponent: "AddSystem"
        }
    },
    mounted(){
        this.checkAndLoadPlantSystems()
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
        
    },
    methods: {
        editSystem(system){
            console.log('entry editSystem')
            this.currentSystem = system
            this.currentComponent = "EditSystem"
            document.getElementById('modalOpen').click();
            console.log('exit editSystem')
        },
        closeModal(action, data){
            console.log('entry closeModal with action: ' + action)
            document.getElementById('modalPlantSystemClose').click();

            if(action === 'add'){
                if(this.currentComponent === 'AddSystem'){
                    this.$store.commit('addPlantSystem', data)
                }
            }
            console.log('exit closeModal')
        },
        newSystem(){
            console.log('entry newSystem')
            this.currentComponent = "AddSystem"
            document.getElementById('modalOpen').click();
            console.log('exit newSystem')
        },
        setSystem(system){
            console.log('entry setSystem')
            this.currentSystem = system
            console.log('exit setSystem')
        },  
        checkAndLoadPlantSystems(){
            console.log('entry checkAndLoadPlantSystems')
            axios
                .get('/api/v1/plantsystems/')
                .then(response => {
                    console.log('plantsystems loaded')
                    console.log(response.data)
                    this.$store.commit('setPlantSystems', response.data)
                    
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            
            console.log('exit checkAndLoadPlantSystems')
        } 
         
    },
    computed:{
        plantsLoaded(){
            if(this.$store.getters.getPlants){
                return true
            }
            return false
        },
        organisation(){
            return this.$store.getters.getOrganisation
        },
        
        currentProperties(){
            return {plant: this.currentPlant}
        },
        currentPlantsProperties(){
                return {plants: this.$store.getters.getPlants
                    ,showPlantComponents: false
                    ,editPlantComponents: false
                    ,editPlants: false}
        },
        allPlantSystems(){
            return this.$store.getters.getPlantSystems
        },
        
        currentPlantSystemsProperties(){
                return {plantSystems: this.$store.getters.getPlantSystems,
                    editPlantSystem: true}
            },
        currentProperties(){
                return {plantSystem: this.currentSystem}
            },
        currentModalProperties(){
                return {plantSystem: this.currentSystem,}
            },
    }
}
</script>
