<template>
  <DashboardKpi
    :plants="store.getters.getPlants"
    :organisation="store.getters.getOrganisation"
    :viewMode="currentTabComponent"
    :searchQuery="filterPlant"
    :activeFilter="activeFilter"
    :allAvailableTypes="allAvailableTypes"
    @updateViewMode="updateViewMode"
    @updateSearch="updateSearch"
    @updateTypeFilter="setTypeFilter"
  >
  </DashboardKpi>
  
  <template v-if="store.getters.getPlants">
    <component :is="currentTabComponent" v-bind="currentProperties"></component>
  </template>
</template>

<script>
import { computed, watch, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useResponsive } from '../composables/useResponsive';
import GoogleMaps from "@/components/dashboard/GoogleMaps.vue";
import ListDashboard from "@/components/dashboard/ListDashboard.vue";
import ListDashboardNew from "@/components/dashboard/ListDashboardNew.vue";
import TileDashboard from "@/components/dashboard/TileDashboard.vue";
import DashboardKpi from "@/components/kpi/DashboardKpi.vue";

export default {
  name: "Dashboard",
  components: {
    GoogleMaps,
    ListDashboard,
    ListDashboardNew,
    TileDashboard,
    DashboardKpi,
  },

  setup() {
    const store = useStore();
    // Use the shared responsive composable
    const { isMobile } = useResponsive();
    const me = computed(() => store.state.me);
    // Reaktiver Zustand
    const currentTabComponent = ref("")
    const userSelectedViewMode = ref("")
    const hasUserChangedTab = ref(false);
    const activeFilter = ref([]);
    const filterPlant = ref("");

    // Handle view mode updates from header
    function updateViewMode(mode) {
      currentTabComponent.value = mode;
    }

    // Handle search updates from header
    function updateSearch(query) {
      filterPlant.value = query;
    }
    onMounted(() => {
      console.log("Dashboard Mounted");
      if(isMobile.value){
        currentTabComponent.value = 'ListDashboard'
      }else if(me.value.standard_dashboard){
        currentTabComponent.value = me.value.standard_dashboard
      }else {
        currentTabComponent.value = 'TileDashboard'
      }
      userSelectedViewMode.value = currentTabComponent.value
    });

    // Dashboard-Ansicht anpassen, falls der User nichts geändert hat
    watch(me, (newMe) => {
      if (newMe && !hasUserChangedTab.value && !isMobile) {
        currentTabComponent.value = newMe.standard_dashboard || "TileDashboard";
        console.log("Konfiguration geladen:", newMe);
      }
    });

    // Watch for changes in screen size and update view mode
    watch(isMobile, (newIsMobile, oldIsMobile) => {
      // If transitioning to mobile, switch to list view
      if (!oldIsMobile && newIsMobile) {
        currentTabComponent.value = 'ListDashboard';
      } 
      // If transitioning back to desktop, restore user's selected view
      else if (oldIsMobile && !newIsMobile) {
        currentTabComponent.value = userSelectedViewMode.value;
      }
    });

    watch(currentTabComponent, () => {
      //hasUserChangedTab.value = true;
    });

    // Watch for user changes to viewMode and update userSelectedViewMode
    watch(currentTabComponent, (newValue) => {
      // Only update userSelectedViewMode if we're not on mobile
      // This prevents the list view (forced on mobile) from becoming the user's selection
      if (!isMobile.value) {
        userSelectedViewMode.value = newValue;
      }
    });

    // Berechnete Eigenschaften
    const allAvailableTypes = computed(() =>
      Array.from(new Set(store.getters.getPlants.map((plant) => plant.type))).filter((n) => n)
    );

    const currentProperties = computed(() => {
      const filterPlantLower = filterPlant.value.toLowerCase();
      console.log("filterPlantLower:", filterPlantLower);

      let plants = store.getters.getPlants.filter((plant) =>
        plant.name.toLowerCase().includes(filterPlantLower)
      );

      if (activeFilter.value.length > 0) {
        plants = plants.filter((plant) => activeFilter.value.includes(plant.type));
      }

      return { plants };
    });

    // Methoden
    const setTypeFilter = (typ) => {
      console.log("entry setTypeFilter");

      if (activeFilter.value.includes(typ)) {
        const pos_delete = activeFilter.value.indexOf(typ);
        console.log("pos_delete:", pos_delete);
        activeFilter.value.splice(pos_delete, 1);
      } else {
        activeFilter.value.push(typ);
      }
      console.log("this.activeFilter", activeFilter.value);
      console.log("exit setTypeFilter");
    };

    return {
      store,
      currentTabComponent,
      activeFilter,
      filterPlant,
      allAvailableTypes,
      currentProperties,
      setTypeFilter,
      isMobile,
      updateSearch,
      updateViewMode
    };
  },
};
</script>

<style scoped>
.filter-selected {
  border: 2px solid #007bff;
  border-radius: 5px;
}
</style>
