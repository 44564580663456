<template>
    <div class="page-config-live-data">
        <div class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
            <NavPlantsSidebar v-if="plantsLoaded" v-bind="currentPlantsProperties" 
                v-on:setPlant="setAnalysePlant" v-on:setComponent="setAnalysePlantComponent"></NavPlantsSidebar>
        </div>
        <div class="col-md-9 ms-sm-auto col-lg-10">
            <template v-if="this.currentPlantComponent">
                <component :is="currentTabComponent" v-bind="currentProperties"></component>
            </template>
        </div>
    </div>
</template>

<script>
    
import AnalyseForPlant from '@/components/analyse/AnalyseForPlant.vue'
import NavPlantsSidebar from '@/components/navs/NavPlantsSidebar.vue'
export default {
    name: 'Analyse',
    components: {
        AnalyseForPlant,
        NavPlantsSidebar
    },
    data() {
        return {
            currentPlant: '',
            currentPlantComponent: '',
            currentTabComponent: ''
        }
    },
    methods: {
        setAnalysePlant(plant){
            console.log('entry setAnalysePlant')
            this.currentPlant = plant
            this.currentPlantComponent = ""
            this.currentTabComponent = 'AnalyseForPlant'
            console.log('exit setAnalysePlant')
        },   
        setAnalysePlantComponent(plant, component){
            console.log('entry setAnalysePlantComponent')
            this.currentPlant = plant
            this.currentPlantComponent = component
            this.currentTabComponent = 'AnalyseForPlant'
            console.log('exit setAnalysePlantComponent')
        },       
    },
    computed:{
        plantsLoaded(){
            if(this.$store.getters.getPlants){
                return true
            }
            return false
        },
        organisation(){
            return this.$store.getters.getOrganisation
        },
        
        currentProperties(){
            return {plant: this.currentPlant, component: this.currentPlantComponent}
        },
        currentPlantsProperties(){
                return {plants: this.$store.getters.getPlants
                    ,showPlantComponents: true
                    ,editPlantComponents: false
                    ,editPlants: false}
            },
    }
}
</script>
