<template>
    <div class="reset-password mt-2">
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
                <div class="card shadow-sm">
                    <div class="card-header text-center pt-4">
                        <h5>{{ $t("Reset Password") }}</h5>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submitForm">

                            <div class="mb-3">
                                <div class="input-group">
                                    <div class="form-floating form-floating-group flex-grow-1">
                                        <input ref="password" id="inputPassword" :type="passwordFieldType" name="password" class="form-control" v-model="password" aria-describedby="hideUnhide" placeholder="test">
                                        <label for="inputPassword" class="form-label">{{ $t("Passwort") }}</label>
                                    </div>
                                    <span class="input-group-text" id="hideUnhide" @click="switchVisibility">
                                        <i class="fa-solid fa-eye" v-if='this.passwordFieldType === "password"'></i>
                                        <i class="fa-solid fa-eye-slash" v-if='this.passwordFieldType === "text"'></i>
                                    </span>
                                </div>
                                
                            </div>
    	                    <div class="mb-3">
                                <div class="input-group">
                                    <div class="form-floating form-floating-group flex-grow-1">
                                        <input ref="passwordRetype" id="inputPasswordRetype" :type="passwordRetypeFieldType" name="passwordRetype" class="form-control" v-model="passwordRetype" aria-describedby="hideUnhide" placeholder="test">
                                        <label for="inputPasswordRetype" class="form-label">{{ $t("PasswortRetype") }}</label>
                                    </div>
                                    <span class="input-group-text" id="hideUnhide" @click="switchVisibilityRetype">
                                        <i class="fa-solid fa-eye" v-if='this.passwordRetypeFieldType === "password"'></i>
                                        <i class="fa-solid fa-eye-slash" v-if='this.passwordRetypeFieldType === "text"'></i>
                                    </span>
                                </div>
                            </div>

                            <div class="notification is-danger" v-if="errors.length">
                                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                            </div>

                            <div class="text-center">
                                <button class="btn btn-success w-100">{{ $t("Confirm") }}</button>
                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
import { useHead } from '@vueuse/head'
export default {
    name: 'ActivateAccount',
    setup () {
      useHead({
        meta: [
          {
            name: `robot`,
            content: 'noindex',
          },
        ],
      })
    },
    data() {
        return {
            uid: this.$route.params.uid,
            token: this.$route.params.token,
            errors: [],
            password: '',
            passwordFieldType: "password",
            passwordRetype: '',
            passwordRetypeFieldType: "password",
        }
    },
    mounted(){
    },
    methods: {
      async submitForm(e) {
            this.errors= []
            
            const formData = {
                uid: this.uid,
                token: this.token,
                new_password: this.password,
                re_new_password: this.passwordRetype
            }

            let hasError = false
            await axios
                .post("/api/v1/users/reset_password_confirm/", formData)
                .then(response => {
                    createToast({text: this.$t("passwordChanged")
                        ,title: this.$t("Reset Password")
                        ,type: 'success'})
                    this.$router.push('/log-in')
                })
                .catch(error => {
                    
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }

                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.log(JSON.stringify(error.message))
                    } else {
                        console.log(JSON.stringify(error))
                    }
                    hasError = true
                })
            if(hasError){
                return
            }
        },
        switchVisibility(){
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
        },
        switchVisibilityRetype(){
            this.passwordRetypeFieldType = this.passwordRetypeFieldType === "password" ? "text" : "password";
        }
    }
}
</script>