<template>
    <ul class="list-group ">
        <li :class="{'active': this.activePosition === position}" class="list-group-item" v-for="position in organisationDailyReportSubHeader.daily_report_positions"
        v-bind:key="position.id" >
            <div class="row">
                <div class="col-7 cursor-pointer" @click="setActivePosition(position)">
                    {{position.name}} 
                </div>
                <div class="col-1">
                    <i class="fa-solid fa-arrow-up" @click="movePositionUp(position)"></i>
                </div>
                <div class="col-1">
                    <i class="fa-solid fa-arrow-down" @click="movePositionDown(position)"></i>
                </div>
                <div class="col-1">
                    <i class="fa-solid fa-pencil" @click="editPosition(position)" data-bs-toggle="modal" data-bs-target="#modalPositions"></i>
                </div>
                <div class="col-1">
                    <i class="fa-solid fa-trash" @click="deletePosition(position)"></i>
                </div>
            </div>
                        
        </li>
        <li class="list-group-item cursor-pointer" @click="setNewPosition()"
                    data-bs-toggle="modal" data-bs-target="#modalPositions">
            <i class="fa-solid fa-plus" ></i>
        </li>
    </ul>


    <div class="modal fade" id="modalPositions" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                    <button type="button" id="modalPositionsClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component v-if="this.currentComponent" :is="currentComponent" v-bind="currentProperties" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
import AddOrganisationDailyReportPosition from '@/components/dailyreport/AddOrganisationDailyReportPosition.vue'
import EditOrganisationDailyReportPosition from '@/components/dailyreport/EditOrganisationDailyReportPosition.vue'

export default {
    name: 'EditOrganisationDailyReportPositions',
    emits: ['addPositionToSubHeader'],
    components: {
        AddOrganisationDailyReportPosition,
        EditOrganisationDailyReportPosition
        },
    props: {
        organisationDailyReport: {
            type: Object,
            required: true,
        },
        organisationDailyReportHeader: {
            type: Object,
            required: true,
        },
        organisationDailyReportSubHeader: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            activePosition: null,
            currentComponent: "",
        }
    },
    mounted(){
    },
    updated(){
        console.log('entry updated')
        
        console.log('exit updated')
    },
    methods: {
        sortPositionArray(){
            console.log('entry sortPositionArray')
            this.organisationDailyReportSubHeader.daily_report_positions = this.organisationDailyReportSubHeader.daily_report_positions.sort((a,b) => {
                if(a.position < b.position){
                    return -1
                }
                if (a.position > b.position){
                    return 1
                }
                return 0
            })
            this.updatePositionsBackend()
            console.log('exit sortPositionArray')
        },
        setNewPosition(){
            console.log('entry setNewPosition')
            this.currentComponent = "AddOrganisationDailyReportPosition",
            console.log('exit setNewPosition')
        },
        editPosition(position){
            console.log('entry editPosition')
            this.currentComponent = 'EditOrganisationDailyReportPosition'
            this.activePosition = position
            console.log('exit editPosition')
        },
        deletePosition(position){
            console.log('entry deletePosition')
            axios
                .delete(`/api/v1/organisation_daily_report_positions/${position.id}/`)
                .then(response => {
                    createToast({text: this.$t("position deleted")
                    ,type: 'success'})
                    this.deletePositionFromFrontend(position)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            this.activePosition = ''
            console.log('exit deletePosition')
        },
        deletePositionFromFrontend(dailyReportPosition){
            console.log('entry deletePositionFromFrontend')
            let position = 1
            let pos_delete = this.organisationDailyReportSubHeader.daily_report_positions.map(e => e.id).indexOf(dailyReportPosition.id)
            console.log('pos_delete: ' + pos_delete)
            this.organisationDailyReportSubHeader.daily_report_positions.splice(pos_delete, 1)
            this.organisationDailyReportSubHeader.daily_report_positions.forEach(element => {
                element.position = position
                position++
                axios
                    .patch(`/api/v1/organisation_daily_report_positions/${element.id}/`, element)
                    .then(response => {
                        console.log('response')
                        console.log(response.data)
                    })
                    .catch(error => {
                        if (error.response) {
                            if ('detail' in error.response.data){
                                createToast({text: this.$t(error.response.data['detail'])
                                            ,type: 'error'})
                            }                        
                            console.error(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            console.error(JSON.stringify(error.message))
                        } else {
                            console.error(JSON.stringify(error))
                        }
                    })
            })
            console.log('exit deletePositionFromFrontend')
        },
        movePositionUp(position){
            console.log('entry movePositionUp')
            if(position.position < 2){
                return
            }
            let header_to_switch = this.organisationDailyReportSubHeader.daily_report_positions.filter(array_header => array_header.position === position.position -1)

            header_to_switch.forEach((value,index) =>{
                value.position = position.position
            })
            position.position--
            this.sortPositionArray()
            console.log('exit movePositionUp')
        },
        movePositionDown(position){
            console.log('entry movePositionDown')
            if(position.position >= this.organisationDailyReportSubHeader.daily_report_positions.length){
                return
            }
            let header_to_switch = this.organisationDailyReportSubHeader.daily_report_positions.filter(array_header => array_header.position === position.position +1)

            header_to_switch.forEach((value,index) =>{
                value.position = position.position
            })
            position.position++
            this.sortPositionArray()
            console.log('exit movePositionDown')
        },
        setActivePosition(position){
            console.log('entry setActivePosition')
            this.activePosition = position
            console.log('exit setActivePosition')
        },
        updatePositionsBackend(){
            console.log('entry updatePositionsBackend')
            this.organisationDailyReportSubHeader.daily_report_positions.forEach(element => {
                axios
                    .patch(`/api/v1/organisation_daily_report_positions/${element.id}/`, element)
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        if (error.response) {
                            if ('detail' in error.response.data){
                                createToast({text: this.$t(error.response.data['detail'])
                                            ,type: 'error'})
                            }                        
                            console.error(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            console.error(JSON.stringify(error.message))
                        } else {
                            console.error(JSON.stringify(error))
                        }
                    })
                })
            console.log('exit updatePositionsBackend')
        },
        closeModal(action, element){
            console.log("closeModal")
            document.getElementById('modalPositionsClose').click();
            if(action === 'add'){
                this.$emit('addPositionToSubHeader', element)
            }
        },
    },
    computed:{
        currentProperties(){
            
            return {organisationDailyReportSubHeader: this.organisationDailyReportSubHeader,
                organisationDailyReportPosition: this.activePosition,
                position: this.organisationDailyReportSubHeader.daily_report_positions.length +1,
                }
        },
    }
}
</script>