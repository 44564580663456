<template>
    <div v-if="allColDescriptionsFormatted.length > 0">
      <div class="card shadow-sm">
        <div class="card-header d-flex align-items-center">
          <p class="text-center h5">{{ $t("selectionData") }}</p>
        </div>
        <div class="card-body">
          <div class="row">
            <template
              v-for="descriptions in allColDescriptionsFormatted"
              v-bind:key="descriptions[0].id"
            >
              <div :class="colClasses">
                <template v-for="description in descriptions" v-bind:key="description.id">
                  <div class="form-check">
                    <input
                      v-on:change="updateChoosenData(description)"
                      class="form-check-input"
                      type="checkbox"
                      :value="description.id"
                      :id="description.col_component_data"
                      v-bind:checked="descriptionIsSelected(description)"
                      :disabled="reachedMaxDescriptions"
                    >
                    <label
                      class="form-check-label"
                      :for="description.col_component_data"
                    >
                      {{ description.col_description }}
                    </label>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ChooseDataResearch",
    emits: ["updateChoosenData"],
    components: {},
    props: {
      component: {
        type: Object,
        required: true,
      },
      checkedDescriptions: {
        type: Array,
        required: true,
      },
        maxDescriptions: {
            type: Number,
            required: true
        }
    },
    computed: {
      reachedMaxDescriptions(){
        return this.checkedDescriptions.length >= this.maxDescriptions
      },
      allColDescriptionsFormatted() {
        if (!this.component) {
          return [];
        }
        const { component_data_description } = this.component;
        const arrayLength = component_data_description.length;
        if (arrayLength < 2) {
          return [component_data_description];
        }
        const middle = Math.round(arrayLength / 2);
        return [
          component_data_description.slice(0, middle),
          component_data_description.slice(middle, arrayLength),
        ];
      },
      colClasses() {
        return this.allColDescriptionsFormatted.length > 1
          ? "col-6"
          : "col";
      },
    },
    methods: {
      descriptionIsSelected(description) {
        return this.checkedDescriptions.find(
          (d) => d.description_id === description.id
        ) !== undefined;
      },
      updateChoosenData(description) {
        this.$emit("updateChoosenData", description);
      },
    },
  };
  </script>
  