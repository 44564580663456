<template>
    <div class="nav-organisation-sidebar">
        <ul class="nav nav-sidebar flex-column ms-2">
            <li class="nav-item" v-for="organisation in allOrganisations"
                v-bind:key="organisation.id">
                <a class="nav-link" :class="{'active': this.currentOrganisation === organisation}" href="#" @click="setOrganisation(organisation)">
                    {{organisation.name}}
                </a>
            </li>
        </ul>
        
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'NavOrganisationsSidebar',
    emits: [
        'setOrganisation',
    ],
    components: {
    },
    props: {
        allOrganisations: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            currentOrganisation: ""
        }
    },
    mounted(){
        
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
        
    },
    methods: {
        setOrganisation(organisation){
            console.log('entry setOrganisation')
            this.currentOrganisation = organisation
            this.$emit('setOrganisation', organisation)
            console.log('exit setOrganisation')
        },
       
    },
    computed:{
        
    }
}
</script>