<template>
    <div class="loading-block" v-if="!this.chartDone"></div>

    <ChartWithExport v-bind:chartId="chartDataId" v-bind:chartData="chartData"  v-bind:chartOptions="chartOptions" v-on:generateExcel="generateExcel"/>

</template>

<script>
import ChartWithExport from '@/components/ChartWithExport.vue'
const fileDownload = require('js-file-download')
import { de } from 'date-fns/locale';
export default {
    name: 'VisualizeResearchData',
    components: {
        ChartWithExport
    },
    emits: [
        'generateExcel'
    ],
    props: {
        dataForVisualize: {
            type: Object,
            required: true
        },
        
    },
    watch: {
        dataForVisualize: {
            deep: true,
            handler() {
                this.prepareDataforChart()
            }
        }
    },
    data() {
        return {
            chartDone: true,
            selectionIntervall: "day",
            chartDataset: [],
            chartDataId: 'test',
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: 'time',
                        distribution: 'linear',
                        time: {
                            //unit: 'minute',
                            tooltipFormat: 'dd.MM HH:mm',
                            displayFormats: {
                                'millisecond': 'dd.MM HH:mm',
                                'second': 'dd.MM HH:mm',
                                'minute': 'dd.MM HH:mm',
                                'hour': 'dd.MM HH:mm',
                                'day': 'dd.MM HH:mm',
                                'week': 'dd.MM HH:mm',
                                'month': 'dd.MM HH:mm',
                                'quarter': 'dd.MM HH:mm',
                                'year': 'dd.MM HH:mm',
                            }
                        },
                        adapters: { 
                            date: {
                                locale: de, 
                            },
                        },
                    }
                },
                plugins: {
                    /*zoom: {
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            pinch: {
                                enabled: true
                            },
                            mode: 'xy',
                        },
                        pan: {
                            enabled: true,
                            mode: 'xy',
                        },
                    },*/
                    legend: {
                        position: 'top',
                    },
                    notifyAfterRender: {
                        doAfterRender: setDone => {
                            this.chartDone = true
                        }
                    }
                }
            },
        }
    },
    mounted(){
    },
    updated(){
        console.log('entry updated')

        console.log('exit updated')
    },
    methods: {
        prepareDataforChart() {
            console.log('entry prepareDataforChart')
            console.log(this.dataForVisualize)
            if (this.dataForVisualize.length === 0) {
                return {}
            }
            const valueMap = new Map();

            // Populate the map with the values from the original array
            for (const item of this.dataForVisualize.data_array) {
                const { date, type, value } = item;
                valueMap.set(`${date}-${type}`, value);
            }

            // Get a list of all the distinct dates and types
            const dates = Array.from(new Set(this.dataForVisualize.data_array.map(item => item.date)));
            const types = Array.from(new Set(this.dataForVisualize.data_array.map(item => item.type)));
            const new_data_array = []
            for (const date of dates) {
                for (const type of types) {
                    // Look up the value in the map using the date and type as the key
                    const value = valueMap.get(`${date}-${type}`) || null;
                    new_data_array.push({ date, type, value });
                }
            }
            console.log(new_data_array)
            const chart_dataset_array = this.dataForVisualize.dataset_array.map(dataset => {
                return {
                    label: dataset.label,
                    backgroundColor: dataset.backgroundColor,
                    borderColor: dataset.borderColor,
                    data: new_data_array.reduce((acc, data) => {
                        if (data.type == dataset.type) {
                            acc.push({ x: data.date, y: data.value })
                        }
                        return acc
                    }, [])
                }
            })
            console.log(chart_dataset_array)
            this.chartDataset = chart_dataset_array
            this.chartDone = false
            console.log('exit prepareDataforChart')
        },
        generateExcel(){
            console.log('entry generateExcel')
            this.$emit('generateExcel')
            console.log('exit generateExcel')
        },
       
        
    },
    computed:{
        chartData(){
            if(this.chartDataset === []){
                return {};
            }
            
            console.log('this.chartDataset')
            console.log(this.chartDataset)
            return {
                datasets: this.chartDataset
            }
        },

        
    }
}
</script>
