<template>
  <footer class="text-muted footer mt-auto py-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 text-center text-sm-start col-sm-4">
            {{ new Date().getFullYear() }} © TeDatCo
          </div>
          <div class="col-12 text-center text-sm-start col-sm-4">
            v.{{ version }}
          </div>
          <div class="col-12 col-sm-4">
            <div class="text-center text-sm-end footer-links ">
              <a v-if="['root', 'Orga-Manager', 'Admin'].includes($store.state.me.grp_name_bez)" href="mailto:feedback@real-time-plants.de">Feedback senden</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
    return {
      version: process.env.VUE_APP_VERSION || 'dev',
    };
  },
}
</script>

<style>

</style>