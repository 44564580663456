import axios from "axios";
import demoData from "@/mocks/demoData.json"; // Statische Demo-Daten
import dayjs from "dayjs";
import { store } from '../store'; // Falls du den Store nutzt, sonst entfernen

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_AXIOS,
});

// Klon der Mock-Daten
const tempStorage = JSON.parse(JSON.stringify(demoData));

/** URL-spezifische Offset-Konfiguration */
const urlOffsets = {
    "/api/v1/dashboard/": { min: -2, max: -0, randomTime: true },
    "default": { min: -5, max: -2, randomTime: true },
};

const blacklist = ['status_data', 'recent_data_hours'];

/** Gibt Offset-Infos für die URL zurück */
function getOffsetForUrl(url) {
    const offsetConfig = urlOffsets[url] !== undefined ? urlOffsets[url] : urlOffsets["default"];

    let daysOffset = 0;
    let randomTime = false;

    if (typeof offsetConfig === "number") {
        daysOffset = offsetConfig;
    } else if (typeof offsetConfig === "object") {
        daysOffset = Math.floor(Math.random() * (offsetConfig.max - offsetConfig.min + 1)) + offsetConfig.min;
        randomTime = offsetConfig.randomTime || false;
    }

    return { daysOffset, randomTime };
}

function isLikelyDate(value) {
    return dayjs(value).isValid() && dayjs(value).year() > 2000;
}

function isWhitelisted(key) {
    if (blacklist.includes(key)) {
        return false; // explizit ignorieren
    }
    return /(timestamp|time|date|at)/i.test(key);
}

function adjustTimestamps(obj, daysOffset = 0, randomTime = false) {
    if (Array.isArray(obj)) {
        return obj.map(item => adjustTimestamps(item, daysOffset, randomTime));
    } else if (typeof obj === "object" && obj !== null) {
        const newObj = {};
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                const val = obj[key];
                if (typeof val === "string" && isWhitelisted(key) && isLikelyDate(val)) {
                    let date = dayjs(val).add(daysOffset, 'day');
                    if (randomTime) {
                        const randomHours = Math.floor(Math.random() * 24);
                        const randomMinutes = Math.floor(Math.random() * 60);
                        date = date.add(randomHours, 'hour').add(randomMinutes, 'minute');
                    }
                    // keine Zukunft erlauben
                    if (date.isAfter(dayjs())) {
                        date = dayjs();
                    }
                    newObj[key] = date.toISOString();
                } else if (typeof val === "object") {
                    newObj[key] = adjustTimestamps(val, daysOffset, randomTime);
                } else {
                    newObj[key] = val;
                }
            }
        }
        return newObj;
    }
    return obj;
}

// Axios Interceptor
apiClient.interceptors.request.use((config) => {
    const isDemoUser = localStorage.getItem("isDemoUser") === "true";
    if (!isDemoUser) {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.common['Authorization'] = "Token " + token;
        }
        return config; // Normaler API-Call
    }

    // DEMO-MODUS: Verwende Mock-Daten + Zeitverschiebung
    return {
        ...config,
        adapter: () => {
            return new Promise((resolve) => {
                const { method, url, data } = config;
                const { daysOffset, randomTime } = getOffsetForUrl(url);

                console.log(`[Demo] ${method.toUpperCase()} ${url} | Offset: ${daysOffset} Tage | Random Time: ${randomTime}`);

                let responseData = tempStorage[url] || {};

                if (["get", "post", "patch"].includes(method)) {
                    responseData = adjustTimestamps(responseData, daysOffset, randomTime);
                    console.log('responseData apiClient mockData', responseData)
                }

                if (method === "get") {
                    resolve({ data: responseData });
                } 
                else if (method === "post") {
                    resolve({
                        data: responseData,
                        status: 200,
                        statusText: "OK",
                        headers: {},
                        config,
                        request: {},
                    });
                } 
                else if (method === "patch") {
                    const id = data.id;
                    if (tempStorage[url]) {
                        const index = tempStorage[url].findIndex(item => item.id === id);
                        if (index !== -1) {
                            tempStorage[url][index] = { ...tempStorage[url][index], ...data };
                            const patchedData = adjustTimestamps(tempStorage[url][index], daysOffset, randomTime);
                            resolve({ data: patchedData });
                        } else {
                            resolve({ data: {}, status: 404 });
                        }
                    }
                } 
                else if (method === "delete") {
                    if (tempStorage[url]) {
                        tempStorage[url] = tempStorage[url].filter(item => item.id !== data.id);
                        resolve({ data: { success: true } });
                    } else {
                        resolve({ data: { success: false }, status: 404 });
                    }
                }
            });
        }
    };
});

export default apiClient;
