<template>
    <div class="container-fluid my-3 py-3">
        <div class="row my-3">
            <div class="col-12">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="organisationDailyReportPosition.name" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                </div>
            </div>                
        </div>
        <div class="row my-3">
            <div class="col-6">
                <div class="form-check form-switch">
                    <input class="form-check-input" id="show_units" type="checkbox" v-model="organisationDailyReportPosition.show_units">
                    <label class="form-check-label" for="show_units" >
                        {{ $t("show_units")}}
                    </label>
                </div>
            </div>
        </div>
        <div class="row my-3">
            <div class="col-6">
                <label for="type_column" class="form-label">{{ $t("type_column") }}</label>
                <select id="type_column" class="form-select " aria-label="Default select example" v-model="organisationDailyReportPosition.type_column">
                    <option value="DA">{{ $t("daily_report_type_column_da") }}</option>
                    <option value="DG">{{ $t("daily_report_type_column_dg") }}</option>
                    <option value="SD">{{ $t("daily_report_type_column_sd") }}</option>
                    <option value="AD">{{ $t("daily_report_type_column_ad") }}</option>
                </select>
            </div>
            <div class="col-6">
                <label for="type_footer" class="form-label">{{ $t("type_footer") }}</label>
                <select id="type_footer" class="form-select " aria-label="Default select example" v-model="organisationDailyReportPosition.type_footer">
                    <option value="EF">{{ $t("daily_report_type_footer_ef") }}</option>
                    <option value="AF">{{ $t("daily_report_type_footer_af") }}</option>
                    <option value="SF">{{ $t("daily_report_type_footer_sf") }}</option>
                </select>
            </div>
        </div>
        <template v-if="['DA', 'DG'].includes(organisationDailyReportPosition.type_column)" >
            <div class="row my-3" >
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="organisationDailyReportPosition.linked_plant" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("linked_plant") }}</label>
                    </div>
                </div>
                <div class="col-3">
                    <label for="type_value_column" class="form-label">{{ $t("type_value_column") }}</label>
                    <select id="type_value_column" class="form-select " aria-label="Default select example" v-model="organisationDailyReportPosition.type_value_column">
                        <option value="FD">{{ $t("daily_report_type_value_column_fd") }}</option>
                        <option value="LD">{{ $t("daily_report_type_value_column_ld") }}</option>
                        <option value="MD">{{ $t("daily_report_type_value_column_md") }}</option>
                        <option value="SD">{{ $t("daily_report_type_value_column_sd") }}</option>
                        <option value="AD">{{ $t("daily_report_type_value_column_ad") }}</option>
                        <option value="FK">{{ $t("daily_report_type_value_column_fk") }}</option>
                        <option value="TK">{{ $t("daily_report_type_value_column_tk") }}</option>
                        <option value="MM">{{ $t("daily_report_type_value_column_mm") }}</option>
                        <option value="M2">{{ $t("daily_report_type_value_column_m2") }}</option>
                        <option value="KW">{{ $t("daily_report_type_value_column_kw") }}</option>
                    </select>
                </div>
                <div class="col-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" id="ignore_null_values" type="checkbox" v-model="organisationDailyReportPosition.ignore_null_values">
                        <label class="form-check-label" for="ignore_null_values" >
                            {{ $t("ignore_null_values")}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="organisationDailyReportPosition.linked_component" placeholder="name@example.com">
                        <label for="inputName" class="form-label" ><template v-if="organisationDailyReportPosition.type_column == 'DG'">{{ $t("group_name") }}</template><template v-else>{{ $t("linked_component") }}</template></label>
                    </div>
                </div>   
                <div class="col-6" >
                    <div class="form-floating">
                        <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="organisationDailyReportPosition.linked_component_data_description_data" placeholder="name@example.com">
                        <label for="inputName" class="form-label" ><template v-if="organisationDailyReportPosition.type_column == 'DG'">{{ $t("linked_component_data_description_data_group") }}</template><template v-else>{{ $t("linked_component_data_description_data") }}</template></label>
                    </div>
                </div>               
            </div>
        </template>
        <template v-else-if="['SD', 'AD'].includes(organisationDailyReportPosition.type_column)">
            <div class="row my-3">
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="organisationDailyReportPosition.linked_plant" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("column_type_calculation") }}</label>
                    </div>
                </div>   
            </div>
        </template>
        <div class="row my-3" v-if="!['SD', 'AD'].includes(organisationDailyReportPosition.type_column)">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="organisationDailyReportPosition.type_calculation" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("type_calculation") }}</label>
                </div>
            </div>
        </div>
        <div class="row my-3" v-if="['DA', 'DG'].includes(organisationDailyReportPosition.type_column)">
            <div class="col-6">
                <label for="calculation_day" class="form-label">{{ $t("calculation_day") }}</label>
                <select id="calculation_day" class="form-select " aria-label="Default select example" v-model="organisationDailyReportPosition.calculation_day">
                    <option value="DB">{{ $t("daily_report_data_day_before") }}</option>
                    <option value="CD">{{ $t("daily_report_data_calculation_day") }}</option>
                </select>
            </div>
        </div>
        <div class="text-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'AddOrganisationDailyReportPosition',
    emits: ['closeModal'],
    props: {
        organisationDailyReportPosition: {
            type:Object,
            required: true
        }
    },
    data() {
        return {
            
            errors: [],
        }
    },
    mounted(){
        console.log('entry mounted')
        this.focusInput()
        console.log('exit mounted')
    },
    methods: {
        focusInput() {
            console.log('entry focusInput')
            console.log(this.$refs.name)
            this.$refs.name.focus();
            console.log('exit focusInput')
        },
        submitForm() {
                        
            axios
                .patch(`/api/v1/organisation_daily_report_positions/${this.organisationDailyReportPosition.id}/`, this.organisationDailyReportPosition)
                .then(response => {
        
                    createToast({text: this.$t("position altered")
                        ,type: 'success'})

                    this.$emit('closeModal', 'edit', response.data)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    },
    computed:{
        
    }
}
</script>