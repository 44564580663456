<template>
    <div class="page-config-live-data mt-2">
        <div class="text-end">
            <button type="button" class="btn btn-danger" @click="deleteLiveData()">{{ $t("delete_live_data") }}</button>
        </div>
        <div class="row mt-5">
            <div class="col-4">
                <ConfigLiveDataPlant v-bind="currentProperties"/>
            </div>
            <div class="col-8">
                <ConfigLiveDataHeader v-bind="currentProperties" 
                v-on:refreshData="refreshData" v-on:deletePosition="deletePosition" v-on:deleteHeader="deleteHeader"
                v-on:addPosition="addPosition"/>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
import ConfigLiveDataPlant from '@/components/livedata/ConfigLiveDataPlant.vue'
import ConfigLiveDataHeader from '@/components/livedata/ConfigLiveDataHeader.vue'
export default {
    name: 'ConfigLiveData',
    emits: ['deleteLiveData',
            ],
    components: {
        ConfigLiveDataPlant,
        ConfigLiveDataHeader
        },
    props: {
        organisation: {
            type: Object,
            required: true,
        },
        liveDataId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            liveDataOld: 0,
            liveData: {}
        }
    },
    mounted(){
        this.getLiveData()
    },
    updated(){
        console.log('entry updated')
        if(this.liveDataOld === 0){
            this.liveDataOld = this.liveDataId
        }
        if(this.liveDataOld != this.liveDataId){
            this.getLiveData()
            this.liveDataOld = this.liveDataId
        }
        console.log('this.liveDataId: ' + this.liveDataId)
        console.log('exit updated')
    },
    methods: {
        deleteLiveData(){
            console.log('entry deleteLiveData')
            axios
                .delete(`/api/v1/organisation_live_datas/${this.liveData.id}/`)
                .then(response => {
                    createToast({text: this.$t("live_data_deleted")
                    ,type: 'success'})
                    this.$emit('deleteLiveData', this.liveData)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit deleteLiveData')
        },
        addPosition(header, position){
            console.log('entry addPosition')
            this.liveData.live_data_headers.forEach(element => {
                if(element === header){
                    element.live_data_positions.push(position)
                }
            })
            console.log('exit addPosition')
        },
        deleteHeader(header){
            console.log('entry deleteHeader')
            let position = 1
            let pos_delete = this.liveData.live_data_headers.map(e => e.id).indexOf(header.id)
            console.log('pos_delete: ' + pos_delete)
            this.liveData.live_data_headers.splice(pos_delete, 1)
            this.liveData.live_data_headers.forEach(element => {
                element.position = position
                position++
                axios
                    .patch(`/api/v1/organisation_live_data_headers/${element.id}/`, element)
                    .then(response => {
                        console.log('response')
                        console.log(response.data)
                    })
                    .catch(error => {
                        if (error.response) {
                            if ('detail' in error.response.data){
                                createToast({text: this.$t(error.response.data['detail'])
                                            ,type: 'error'})
                            }                        
                            console.error(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            console.error(JSON.stringify(error.message))
                        } else {
                            console.error(JSON.stringify(error))
                        }
                    })
                })
            console.log('exit deleteHeader')
        },
        numeratePositionArray(header){
            console.log('entry numeratePositionArray')
            this.liveData.live_data_headers.forEach(element => {
                if(element === header){
                    let position = 1
                    element.live_data_positions.forEach(header_position => {
                        header_position.position = position
                        position++
                    })
                }
            })
            console.log('exit numeratePositionArray')
        },
        deletePosition(header, position){
            console.log('entry deletePosition')

            this.liveData.live_data_headers.forEach(element => {
                if(element === header){
                    let pos_delete = element.live_data_positions.map(e => e.id).indexOf(position.id)
                    console.log('pos_delete: ' + pos_delete)
                    element.live_data_positions.splice(pos_delete, 1)
                }
            });
            this.numeratePositionArray(header)
            console.log('exit deletePosition')
        },
        refreshData(){
            console.log('entry refreshData')
            this.getLiveData()
            console.log('exit refreshData')
        },
        getLiveData(){
            console.log('entry getLiveDataHeaders')
            axios
                .get(`/api/v1/organisation_live_datas/${this.liveDataId}/`)
                .then(response => {
                    console.log('response')
                    console.log(response.data)
                    this.liveData = response.data
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getLiveDataHeaders')
        },
    },
    computed:{
        currentProperties(){
                return {organisation: this.$store.state.organisation, liveData: this.liveData}
            },
    }
}
</script>