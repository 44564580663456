<template>
    <div class="page-config-live-data">
        <div class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
            <NavPlantDashboardSidebar v-if="dashboardsLoaded" v-bind="currentPlantDashboardProperties" 
                v-on:setDashboard="setDashboard" v-on:editDashboard="editDashboard" v-on:newDashboard="newDashboard"></NavPlantDashboardSidebar>
        </div>
        <div class="col-md-9 ms-sm-auto col-lg-10">
            <template v-if="this.currentDashboard">
                <component :is="currentTabComponent" v-bind="currentProperties"></component>
            </template>
        </div>
    </div>
    <button id="modalOpen" style="display:none;" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modalDashboard" aria-hidden="true">hidden</button>
    <div class="modal fade" id="modalDashboard" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentModalComponent) }}</h5>
                    <button type="button" id="modalDashboardClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component :is="currentModalComponent" v-if="currentModalComponent" v-bind="currentModalProperties" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
import EditDashboardForOrganisation from '@/components/dashboard/EditDashboardForOrganisation.vue'
import EditDashboardRowForOrganisation from '@/components/dashboard/EditDashboardRowForOrganisation.vue'
import NavPlantDashboardSidebar from '@/components/navs/NavPlantDashboardSidebar.vue'
import EditDashboard from '@/components/dashboard/EditDashboard.vue'
import AddDashboard from '@/components/dashboard/AddDashboard.vue'
import axios from 'axios'
export default {
    name: 'ConfigPlantDashboards',
    components: {
        EditDashboardForOrganisation,
        EditDashboardRowForOrganisation,
        NavPlantDashboardSidebar,
        EditDashboard,
        AddDashboard
    },
    data() {
        return {
            currentDashboard: '',
            currentTabComponent: '',
            currentModalComponent: '',
            allPlantDashboards: [],
            dashboardsLoaded: false
        }
    },
    mounted(){
        this.checkAndLoadPlantDashboards()
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
    },
    methods: {
        closeModal(action, new_dashboard){
            console.log('entry closeModal with action: ' + action)
            if(action === 'add'){
                this.allPlantDashboards.push(new_dashboard)
                this.currentDashboard = new_dashboard
            }
            document.getElementById('modalDashboardClose').click();
            console.log('exit closeModal')
        },
        editDashboard(dashboard){
            console.log('entry editDashboard')
            this.currentModalComponent = 'EditDashboard'
            this.currentDashboard = dashboard
            document.getElementById('modalOpen').click();
            console.log('exit editDashboard')
        },
        newDashboard(){
            console.log('entry newDashboard')
            this.currentModalComponent = 'AddDashboard'
            this.currentDashboard = ''
            document.getElementById('modalOpen').click();
            console.log('exit newDashboard')
        },
        setDashboard(dashboard){
            console.log('entry setDashboard')
            this.currentDashboard = dashboard
            if(dashboard.type == 'table'){
                this.currentTabComponent = 'EditDashboardForOrganisation'
            }else{
                this.currentTabComponent = 'EditDashboardRowForOrganisation'
            }
            console.log('exit setDashboard')
        },   
        checkAndLoadPlantDashboards(){
            console.log('entry checkAndLoadPlantDashboards')
            if(this.allPlantDashboards.length < 1){
                axios
                    .get('/api/v1/plantdashboards/')
                    .then(response => {
                        console.log('plantdashboards loaded')
                        console.log(response.data)
                        this.allPlantDashboards = response.data
                        this.dashboardsLoaded = true
                    
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
            }
            console.log('exit checkAndLoadPlantDashboards')
        }
    },
    computed:{
        
        currentProperties(){
            return {dashboard: this.currentDashboard}
        },
        currentModalProperties(){
            return {organisation: this.$store.getters.getOrganisation,
                    dashboard: this.currentDashboard}
        },
        currentPlantDashboardProperties(){
                return {plantDashboards: this.allPlantDashboards
                    ,editPlantDashboard: true}
            },
    }
}
</script>
