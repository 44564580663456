<template>
    <div class="loading-block" v-if="this.loading"></div>
    <div class="row">
        <div class="col-lg-4 my-2">
            <div class="card shadow-sm">
                <div class="card-header text-center">
                    <p class="h5">{{ $t("selectionIntervall") }}</p>
                    
                </div>
                <div class="card-body ">
                    <Datepicker v-model="date" monthPicker
                                closeOnScroll autoApply 
                                :format="getDatepickerFormat" locale="de"
                                cancelText="abbrechen" selectText="auswählen" 
                                :enableTimePicker="false" />
                    <div class="text-end mt-3">
                        <button v-if="$store.state.me.is_superuser" class="btn btn-success mx-1 ms-auto" @click="getDailyReport('xlsx')" :disabled="isButtonConfirmDisabled"><i class="fa-solid fa-file-excel"></i> {{ $t("xlsx") }}</button>
                        <button class="btn btn-success mx-1 ms-auto" @click="getDailyReport('pdf')" :disabled="isButtonConfirmDisabled"><i class="fa-solid fa-file-pdf"></i> {{ $t("pdf") }}</button>
                        <button class="btn btn-success mb-0 ms-auto" @click="getDailyReport('plain')" :disabled="isButtonConfirmDisabled"><i class="fa-solid fa-table-columns"></i> {{ $t("browser") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <component ref="dailyreport" v-if="showComponent" :is="currentTabComponent" v-bind="currentProperties"></component>

</template>

<script>
import axios from 'axios'

const fileDownload = require('js-file-download')
import DailyReportForId from '@/components/dailyreport/DailyReportForId'
export default {
    name: 'OrganisationDailyReport',
    components: {
        DailyReportForId
    },
    data() {
        return {
            allOrganisationDailyReports: [],
            currentTabComponent: "DailyReportForId",
            dailyReportsLoaded: false,
            date: null,
            showComponent: false,
            loading: false,
            dailyReport: {}
        }
    },
    mounted(){
        this.loadOrganisationDailyReport()
        this.setDefaultDate()
    },
    updated(){

    },
    unmounted(){
        console.log('component unmounted')
        
    },
    methods: {
        setDefaultDate(){
            console.log('entry setDefaultDate')
            const currentDate = new Date();
            this.date = {"month": currentDate.getMonth(), "year": currentDate.getFullYear() } 
            console.log('exit setDefaultDate')
        },
        loadOrganisationDailyReport(){
            console.log('entry loadOrganisationDailyReport')
            axios
                .get('/api/v1/organisation_daily_reports/')
                .then(response => {
                    console.log('daily reports loaded')
                    console.log(response.data)
                    this.allOrganisationDailyReports = response.data
                    this.dailyReportsLoaded = true
                    this.getDailyReport('plain')
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit loadOrganisationDailyReport')
        },
        getFormData(){
            console.log('entry getFormData')
            var formData = {}
            console.log(this.date)
            formData.month=this.date.month +1
            formData.year=this.date.year
            console.log('exit getFormdata')

            return formData
        },
        getDailyReport(type){
            console.log('entry getDailyReport')
            this.loading = true
            var formData = this.getFormData()
            var organisation_daily_report = this.allOrganisationDailyReports[0]
            
            formData.type_data = type
            if (type == 'pdf'){
                this.showComponent = false
                axios
                    .post(`/api/v1/organisations/${organisation_daily_report.organisation}/${organisation_daily_report.id}/get_daily_report/`, formData, {responseType: 'blob'})
                    .then(response => {
                        console.log('fertig')
                        console.log(response.data)
                        var real_month = this.date.month + 1
                        var date_name = (real_month<10?'0':'') + real_month + this.date.year 
                        fileDownload(response.data, `Tagesbericht_${this.$store.getters.getOrganisation.name}_${date_name}.pdf`);
                        this.loading = false
                    })
                    .catch(error => {
                        this.loading = false
                        console.log(JSON.stringify(error))
                    })
            }else if (type == 'xlsx'){
                axios
                    .post(`/api/v1/organisations/${organisation_daily_report.organisation}/${organisation_daily_report.id}/get_daily_report/`, formData, {responseType: 'blob'})
                    .then(response => {
                        console.log('fertig')
                        console.log(response.data)
                        var real_month = this.date.month + 1
                        var date_name = (real_month<10?'0':'') + real_month + this.date.year 
                        fileDownload(response.data, `Tagesbericht_${this.$store.getters.getOrganisation.name}_${date_name}.xlsx`);
                        this.loading = false
                    })
                    .catch(error => {
                        this.loading = false
                        console.log(JSON.stringify(error))
                    })
                 
            }else{

                axios
                    .post(`/api/v1/organisations/${organisation_daily_report.organisation}/${organisation_daily_report.id}/get_daily_report/`, formData)
                    .then(response => {
                        console.log('fertig')
                        console.log(response.data)
                        this.dailyReport = response.data
                        this.loading = false
                        this.showComponent = true
                    })
                    .catch(error => {
                        this.loading = false
                        console.log(JSON.stringify(error))
                    })
            }
                
            console.log('exit getDailyReport')
        }
    },
    computed:{
        isButtonConfirmDisabled(){

            if(this.date === null){
                return true
            }
            return false
        },
        getDatepickerFormat(){
            return "MM/yyyy"
        },
        currentProperties(){
            return {dailyReport: this.dailyReport,}
        },
    }
}
</script>
