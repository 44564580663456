<template>
    <div class="page-update-plant">
        <div class="container-fluid my-3 py-3">
            <div class="row my-3">
                <div class="col-4">
                    <div class="form-floating">
                        <input :disabled="!$store.state.me.is_superuser" id="inputName" type="text" name="name" class="form-control"  v-model="plant.name" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                    </div>
                </div>

                <div class="col-4">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="plant.installed_capacity" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("installed_capacity") }}</label>
                    </div>
                </div>
                
                <div class="col-4" v-if="$store.state.me.is_superuser">
                    <div class="form-floating">
                        <input id="price_per_month" type="number" name="name" class="form-control"  v-model="plant.price_per_month" placeholder="name@example.com">
                        <label for="price_per_month" class="form-label" >{{ $t("price_per_month") }}</label>
                    </div>
                </div>
            </div>
            <div class="row my-3" v-if="plant.type=='Windkraft' && $store.state.me.is_superuser">
                <div class="col-4">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="plant.identificator_1" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("park_num") }}</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="plant.identificator_2" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("turb_num") }}</label>
                    </div>
                </div>
            </div>

            <div class="row my-3" v-if="getSystemForPlant.import_data_element_tag=='Gasaufbereitung' && $store.state.me.is_superuser">
                <div class="col-4">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="plant.identificator_1" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("skip_rows_data") }}</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="plant.identificator_2" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("batch_size_data") }}</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="plant.identificator_3" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("skip_rows_alarm") }}</label>
                    </div>
                </div>
            </div>

            <div class="row my-3" v-if="$store.state.me.is_superuser">
                <div class="col-4">
                    <select class="form-select " aria-label="Default select example" v-model="plant.dashboard">
                        <option  v-for="dashboard in this.plantDashboards" 
                            v-bind:key="dashboard.name" v-bind:value="dashboard.id">{{dashboard.name}}</option>
                    </select>
                </div>
                <div class="col-4" v-if="$store.state.me.is_superuser">
                    <select :disabled="!$store.state.me.is_superuser" class="form-select " aria-label="Default select example" v-model="plant.system">
                        <option  v-for="system in this.plantSystems" 
                            v-bind:key="system.name" v-bind:value="system.id">{{system.name}}</option>
                    </select>
                </div>

                <div class="col-4" v-if="$store.state.me.is_superuser">
                    <select class="form-select " aria-label="Default select example" v-model="plant.type">
                        <option  v-for="types in this.plantTypes" 
                            v-bind:key="types.name" v-bind:value="types.name">{{types.name}}</option>
                    </select>
                </div>
            </div>
            

            <div class="row my-3">
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputLatitude" type="number" name="Latitude" class="form-control"  v-model="plant.latitude" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputLatitude" class="form-label" >{{ $t("latitude") }}</label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputLongitude" type="number" name="Longitude" class="form-control"  v-model="plant.longitude" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputLongitude" class="form-label" >{{ $t("longitude") }}</label>
                    </div>
                </div>
            </div>
            <div class="row my-3" v-if="$store.state.me.is_superuser">
                <div class="col-3">
                    <div class="form-floating">
                        <input id="inputImportfolder" type="text" name="ImportFolder" class="form-control"  v-model="plant.folder_name_import" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputImportfolder" class="form-label" >{{ $t("importfolder") }}</label>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-floating">
                        <input id="inputImportfolder" type="text" name="ImportFolder" class="form-control"  v-model="plant.archivfolder" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputImportfolder" class="form-label" >{{ $t("archivfolder") }}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-check form-switch">
                        <input id="inputImportfolder" type="checkbox" name="ImportFolder" class="form-check-input" role="switch"  v-model="plant.folder_yaml" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputImportfolder" class="form-check-label" >{{ $t("folder_yaml") }}</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-floating">
                        <input id="inputImportfolder" type="text" name="ImportFolder" class="form-control"  v-model="plant.file_prefix_yaml" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputImportfolder" class="form-label" >{{ $t("file_prefix_yaml") }}</label>
                    </div>
                </div>
            </div>

            <div class="row my-3" v-if="$store.state.me.is_superuser">
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputImportfolder" type="text" name="ImportFolder" class="form-control"  v-model="plant.folder_alarm_import" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputImportfolder" class="form-label" >{{ $t("folder_alarm_import") }}</label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputImportfolder" type="text" name="ImportFolder" class="form-control"  v-model="plant.file_prefix_alarm" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputImportfolder" class="form-label" >{{ $t("file_prefix_alarm") }}</label>
                    </div>
                </div>
            </div>

            <div class="row my-3" v-if="$store.state.me.is_superuser || $store.state.me.grp_name_bez === 'Orga-Manager'">
                <div class="col-4">
                    <label >{{ $t("prio_value_1") }}</label>
                    <model-select id="floatingSelect"
                        :options="getAllPlantDescriptionOptions"
                         v-model="prio_value_1"
                    >
                    </model-select>
                </div>
                <div class="col-4">
                    <label >{{ $t("prio_value_2") }}</label>
                    <model-select id="floatingSelect"
                        :options="getAllPlantDescriptionOptions"
                         v-model="prio_value_2"
                    >
                    </model-select>
                </div>
                
                <div class="col-4">
                    <label >{{ $t("prio_value_3") }}</label>
                    <model-select id="floatingSelect"
                        :options="getAllPlantDescriptionOptions"
                         v-model="prio_value_3"
                    >
                    </model-select>
                </div>
                <div class="col-12 text-danger">
                    {{ $t("cache_note_15") }}
                </div>
            </div>

            <div class="row my-3" v-if="$store.state.me.is_superuser">
                <div class="col-4">
                    <label >{{ $t("dashboard_value_field") }}</label>
                    <model-select id="floatingSelect"
                        :options="getAllPlantDescriptionOptions"
                         v-model="plant.dashboard_value_field"
                    >
                    </model-select>
                </div>
                
            </div>
            <div class="row my-3" v-if="$store.state.me.is_superuser">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                {{ $t("view_orgas_assignement") }}
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">

                                <template
                                    v-for="orgas in getAllOrgasFormatted"
                                    v-bind:key="orgas.id"
                                >
                                    <div :class="{'col-6': getAllOrgasFormatted.length > 1, 'col': getAllOrgasFormatted.length === 1}">
                                        <template
                                            v-for="orga in orgas"
                                            v-bind:key="orga.id"
                                        >
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" :value="orga.id" :id="orga.id" v-model="plant.view_organisations">
                                                <label class="form-check-label" :for="orga.id" >
                                                    {{orga.name}}
                                                    </label>
                                            </div>

                                        </template>
                                    </div>
                        
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import { ModelSelect } from "vue-search-select"
import axios from 'axios'
export default {
    name: 'AddPlant',
    emits: ['closeModal'],
    props: {
        organisation: {
            type: Object,
            required: true,
        },
        plant: {
            type: Object,
            required: true,
        }
    },
    components: {
        ModelSelect,
    },
    watch: {
        plant: {
            deep: true,
            handler() {
                console.log('watch fired')
                this.loadPlant()
            }
        }
    },
    data() {
        return {
            plantTypes: {},
            plantSystems: [],
            plantDashboards: [],
            loadedPlant: {},
            prio_value_1: '',
            prio_value_2: '',
            prio_value_3: '',
            allOrganisations: []
        }
    },
    mounted(){
        this.loadPlant()
        this.getPlantTypes()
        this.getPlantSystems()
        this.getPlantDashboards()
        if(this.$store.state.me.is_superuser){
            this.getAllOrganisations()
        }
    },
    methods: {
        getAllOrganisations(){
            console.log('entry getAllOrganisations')
            axios
                .get('/api/v1/allOrganisations/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        this.allOrganisations.push(response.data[i])
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getAllOrganisations')
        },
        loadPlant(){
            console.log('entry loadPlant')
             axios
                .get(`/api/v1/plants/${this.plant.id}/`)
                .then(response => {
                    this.loadedPlant = response.data
                    
                    if(this.loadedPlant.plant_prio_values.length === 3){
                        this.prio_value_1 = this.loadedPlant.plant_prio_values[0].component_data_description
                        this.prio_value_2 = this.loadedPlant.plant_prio_values[1].component_data_description
                        this.prio_value_3 = this.loadedPlant.plant_prio_values[2].component_data_description
                    }else if(this.loadedPlant.plant_prio_values.length === 2){
                        this.prio_value_1 = this.loadedPlant.plant_prio_values[0].component_data_description
                        this.prio_value_2 = this.loadedPlant.plant_prio_values[1].component_data_description
                    }else if(this.loadedPlant.plant_prio_values.length === 1){
                        this.prio_value_1 = this.loadedPlant.plant_prio_values[0].component_data_description
                    }
                    console.log('plantsystems fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit loadPlant')
        },
        getPlantSystems(){
            console.log('entry getPlantSystems')
             axios
                .get('/api/v1/plantsystems/')
                .then(response => {
                    this.plantSystems = response.data
                    console.log('plantsystems fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantSystems')
        },
        getPlantTypes(){
            console.log('entry getPlantTypes')
             axios
                .get('/api/v1/planttypes/')
                .then(response => {
                    this.plantTypes = response.data
                    console.log('planttypes fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantTypes')
        },
        getPlantDashboards(){
            console.log('entry getPlantDashboards')
             axios
                .get('/api/v1/plantdashboards/')
                .then(response => {
                    this.plantDashboards = response.data
                    console.log('plantdashboards fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantDashboards')
        },
        updatePrioValues(){
            console.log('entry updatePrioValues')
            let allPrioValues = [{'id': this.prio_value_1}, {'id': this.prio_value_2}, {'id': this.prio_value_3}]

            axios
                .patch(`/api/v1/plants/${this.plant.id}/update_prio_values/`, allPrioValues)
                .then(response => {
                    this.updatePlant()
                    
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit updatePrioValues')
        },
        updatePlant(){
            this.plant.organisation = this.organisation.id
            this.plant.created_by = this.$store.state.me.id
            this.plant.modified_by = this.$store.state.me.id
            this.plant.users = {}
            
            console.log('plant:')
            console.log(this.plant)
            axios
                .patch(`/api/v1/plants/${this.plant.id}/`, this.plant)
                .then(response => {
                    createToast({text: this.$t("plant updated")
                   ,type: 'success'})

                   this.$emit('closeModal', 'update', response.data)
                    
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        },
        submitForm() {
            this.updatePrioValues()
            
        }
    },
    computed:{
        getSystemForPlant(){
            console.log('entry getSystemForPlant')
            if(this.plantSystems.length < 1){
                return {'import_data_element_tag': ''}
            }
            if(!this.plant.system){
                return {'import_data_element_tag': ''}
            }
            console.log('alles erfuellt')
            let plantsystem = this.plantSystems.filter(system => system.id === this.plant.system)[0]
            console.log('plantsystem')
            console.log(plantsystem)
            return plantsystem
        },
        getAllOrgasFormatted(){
            console.log('entry getAllOrgasFormatted')
            console.log(this.allOrganisations)
            let array_length = this.allOrganisations.length
            let formatted_array = []

            if(array_length < 2){
                formatted_array[0] = this.allOrganisations
            }else{
                let orgas = this.allOrganisations.slice(0, Math.round(array_length/2))
                let orgas2 = this.allOrganisations.slice(Math.round(array_length/2), array_length)
                formatted_array[0] = orgas
                formatted_array[1] = orgas2
                console.log('orgas')
                console.log(orgas)
                console.log('orgas2')
                console.log(orgas2)
            }
            console.log('formatted_array')
            console.log(formatted_array)
            console.log('exit getAllOrgasFormatted')
            return formatted_array
        },
        getAllPlantDescriptions(){
            console.log('entry getAllPlantDescriptions')
            let allPlantDescriptions = []
            console.log(this.loadedPlant)
            console.log(typeof this.loadedPlant)
            if(JSON.stringify(this.loadedPlant) === '{}'){
                return allPlantDescriptions
            }
            console.log('plant is not null')
            this.loadedPlant.plant_components.forEach(component => {
                console.log('add another arry')
                console.log(component.component_data_description)
                component.component_data_description.forEach(data_description => {
                        data_description.component_name = component.name
                    })
                if(allPlantDescriptions.length === 0){
                    console.log('array is empty')
                    
                    allPlantDescriptions = component.component_data_description
                }else{
                    console.log('add array')
                    allPlantDescriptions = allPlantDescriptions.concat(component.component_data_description)
                }
                console.log(allPlantDescriptions)
            })
            console.log('exit getAllPlantDescriptions')
            console.log(allPlantDescriptions)
            allPlantDescriptions = allPlantDescriptions.sort((a,b) => {
                if(a.component < b.component){
                    return -1
                }
                if (a.component > b.component){
                    return 1
                }
                if (a.col_description.toUpperCase() < b.col_description.toUpperCase()){
                    return -1
                }
                if (a.col_description.toUpperCase() > b.col_description.toUpperCase()){
                    return 1
                }
                return 0
            })
            return allPlantDescriptions
        },
        getAllPlantDescriptionOptions(){
            console.log('entry getAllPlantDescriptionOptions')

            var allPlantDescriptionsRaw = this.getAllPlantDescriptions
            let allPlantDescriptions = []
            allPlantDescriptionsRaw.forEach(description => {
                allPlantDescriptions.push({value: description.id,
                                          text: description.component_name  + " - " + description.col_description})
                                          
            })
           
            console.log('allPlantDescriptions')
            console.log(allPlantDescriptions)
            return allPlantDescriptions
        }
    }
}
</script>