<template>
    <div class="container-fluid my-3 py-3">
        <div class="row my-3">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="plant_name" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                </div>
            </div>
            <div class="col-6">
                <select class="form-select " aria-label="Default select example" v-model="plant_system">
                    <option  v-for="system in this.plantSystems" 
                        v-bind:key="system.name" v-bind:value="system.id">{{system.name}}</option>
                </select>
            </div>                  
        </div>
        <div class="row my-3">
            <div class="col-6">
                <select class="form-select " aria-label="Default select example" v-model="plant_type">
                    <option  v-for="types in this.plantTypes" 
                        v-bind:key="types.name" v-bind:value="types.name">{{types.name}}</option>
                </select>
            </div>   
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="identificator_1" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("identificator_1") }}</label>
                </div>
            </div>                
        </div>
        <div class="row my-3">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="identificator_2" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("identificator_2") }}</label>
                </div>
            </div>   
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="identificator_3" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("identificator_3") }}</label>
                </div>
            </div>                
        </div>
        <div class="row my-3">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="folder_name_import" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("folder_name_import") }}</label>
                </div>
            </div>   
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="folder_alarm_import" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("folder_alarm_import") }}</label>
                </div>
            </div>                
        </div>
        <div class="row my-3">
            <div class="col-12">
                <select class="form-select " aria-label="Default select example" v-model="this.selectedOrganisation">
                    <option  v-for="organisation in this.allOrganisations" 
                        v-bind:key="organisation.name" v-bind:value="organisation.id">{{organisation.name}}</option>
                </select>
            </div>
        </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("create_plant") }}</button>
    </div>



</template>

<script>
import axios from 'axios'

export default {
    name: 'CreateNewPlant',
    components: {
    },
    data() {
        return {
            plant_name: '',
            plant_system: '',
            plant_type: '',
            identificator_1: '',
            identificator_2: '',
            identificator_3: '',
            folder_name_import: '',
            folder_alarm_import: '',
            selectedOrganisation: '',
            allOrganisations: [],
            plantTypes: {},
            plantSystems: [],
        }
    },
    mounted(){
        this.getAllOrganisations()
        this.getPlantTypes()
        this.getPlantSystems()
    },
    updated(){

    },
    unmounted(){
        console.log('component unmounted')
        
    },
    methods: {
        submitForm(){
            console.log('entry submitForm')

            const data = {
                plant_name: this.plant_name,
                plant_system: this.plant_system,
                plant_type: this.plant_type,
                identificator_1: this.identificator_1,
                identificator_2: this.identificator_2,
                identificator_3: this.identificator_3,
                folder_name_import: this.folder_name_import,
                folder_alarm_import: this.folder_alarm_import,
                organisation: this.selectedOrganisation
            }

            axios
                .post(`/api/v1/tasks/create_new_plant/`, data)
                .then(response => {
                    console.log('fertig')
                    this.plant_name = ''
                    this.plant_system = ''
                    this.plant_type = ''
                    this.identificator_1 = ''
                    this.identificator_2 = ''
                    this.identificator_3 = ''
                    this.folder_name_import = ''
                    this.folder_alarm_import = ''
                    this.selectedOrganisation =''
                    
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit write_daily_report_all')
        },
        getPlantSystems(){
            console.log('entry getPlantSystems')
             axios
                .get('/api/v1/plantsystems/')
                .then(response => {
                    this.plantSystems = response.data
                    console.log('plantsystems fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantSystems')
        },
        getPlantTypes(){
            console.log('entry getPlantTypes')
             axios
                .get('/api/v1/planttypes/')
                .then(response => {
                    this.plantTypes = response.data
                    console.log('planttypes fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantTypes')
        },
        getAllOrganisations(){
            console.log('entry getAllOrganisations')
            axios
                .get('/api/v1/allOrganisations/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        this.allOrganisations.push(response.data[i])
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getAllOrganisations')
        }
    },
    computed:{
     
    }
}
</script>
