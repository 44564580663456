<template>
    <div class="row my-3">
        <div class="col-6">
            <div class="form-floating">
                <input id="inputName" type="text" name="name" class="form-control"  v-model="numUpv" placeholder="name@example.com">
                <label for="inputName" class="form-label" >{{ $t("numUpv") }}</label>
            </div>
        </div>
        <div class="col-6">
            <div class="form-floating">
                <input id="inputName" type="text" name="name" class="form-control"  v-model="numIpv" placeholder="name@example.com">
                <label for="inputName" class="form-label" >{{ $t("numIpv") }}</label>
            </div>
        </div>        
    </div>
    <div class="row my-3">
        <div class="col-6">
            <div class="form-floating">
                <input id="inputName" type="text" name="name" class="form-control"  v-model="numUac" placeholder="name@example.com">
                <label for="inputName" class="form-label" >{{ $t("numUac") }}</label>
            </div>
        </div>
        <div class="col-6">
            <div class="form-floating">
                <input id="inputName" type="text" name="name" class="form-control"  v-model="numIac" placeholder="name@example.com">
                <label for="inputName" class="form-label" >{{ $t("numIac") }}</label>
            </div>
        </div>        
    </div>
    <div class="row my-3">
        <p>Standardspalten: Status, Temp, cos, fac, Pac, Qac, Eac, E-Day, E-Total, Cycle Time</p>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'InitInverter',
    emits: ['closeModal'],
    props: {
        
    },
    data() {
        return {
            numUpv: 10,
            numIpv: 10,
            numUac: 3,
            numIac: 3,
            typeInit: 'dummy'
        }
    },
    methods: {
        initComponent(addedComponent) {
            console.log('entry initComponent')
            let formData = {
                'numUpv': this.numUpv,
                'numIpv': this.numIpv,
                'numUac': this.numUac,
                'numIac': this.numIac,
            }
            axios
                .post(`/api/v1/components/${addedComponent.id}/init_component/`, formData)
                .then(response => {
                    
                    createToast({text: this.$t("component init")
                   ,type: 'success'})

                   this.$emit('closeModal')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>