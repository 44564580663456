<template>
    <div class="activate-account">
        Bitte warten, ihr Account wird aktiviert
    </div>
</template>

<script>
import axios from 'axios'
import { useHead } from '@vueuse/head'
import { createToast } from '@/assets/js/myToast.js'
export default {
    name: 'ActivateAccount',
    setup () {
      useHead({
        meta: [
          {
            name: `robot`,
            content: 'noindex',
          },
        ],
      })
    },
    data() {
        return {
            uid: this.$route.params.uid,
            token: this.$route.params.token,
            errors: [],
        }
    },
    mounted(){
    this.$nextTick(function () {
            this.activateAccount();
        })
    },
    methods: {
        async activateAccount() {

            const formData = {
                uid: this.uid,
                token: this.token,
            }

            await axios
                .post("/api/v1/users/activation/", formData)
                .then(response => {
                    createToast({text: this.$t("successActivateAccount")
                              ,title: this.$t("ActivateAccount")
                              ,type: 'success'})
                    this.$router.push('/')
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }

                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.log(JSON.stringify(error.message))
                    } else {
                        console.log(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>