<script setup>
import { ref, computed, onMounted } from 'vue';
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
const props = defineProps({
  organization: {
    type: Object,
    required: true
  },
  onSave: {
    type: Function,
    default: () => {}
  }
});

const emit = defineEmits(['save']);

// Create a deep copy of the organization's KPI config to avoid direct mutation
const kpiConfig = ref({
  recentDataHours: props.organization.recent_data_hours,
  measurements: JSON.parse(JSON.stringify(props.organization?.organisation_kpis || []))
});

// Available measurement types
const availableMeasurementTypes = ref([
  ]);

// Add any measurement types from the organization that aren't in our default list
onMounted(() => {
  // Add measurement types from the current configuration
  kpiConfig.value.measurements.forEach(measurement => {
    if (!availableMeasurementTypes.value.includes(measurement.name)) {
      availableMeasurementTypes.value.push(measurement.name);
    }
  });
  
  // Add measurement types from all anlagen
  props.organization.plants.forEach(plant => {
    plant.plant_prio_values.forEach(prio_value => {
        if (!availableMeasurementTypes.value.includes(prio_value.name)) {
            availableMeasurementTypes.value.push(prio_value.name);
        }
    })
  });
  
  // Sort alphabetically
  availableMeasurementTypes.value.sort();
});

// New measurement being added
const newMeasurement = ref({
  kpi: '',
  aggregation: 'avg'
});

// Check if a measurement is already configured
function isMeasurementConfigured(kpi) {
  return kpiConfig.value.measurements.some(m => m.kpi === kpi);
}

// Add a new measurement
function addMeasurement() {
  if (!newMeasurement.value.kpi || isMeasurementConfigured(newMeasurement.value.kpi)) {
    return;
  }
  
  kpiConfig.value.measurements.push({
    kpi: newMeasurement.value.kpi,
    aggregation: newMeasurement.value.aggregation
  });
  
  // Reset form
  newMeasurement.value = {
    kpi: '',
    aggregation: 'avg'
  };
}

// Remove a measurement
function removeMeasurement(index) {
  kpiConfig.value.measurements.splice(index, 1);
}

// Change aggregation type
function changeAggregation(index, type) {
  kpiConfig.value.measurements[index].aggregation = type;
}

// Save configuration
function saveConfig() {
  axios
    .post(`/api/v1/organisation/${props.organization.id}/update_organisation_kpis/`, kpiConfig.value)
    .then(response => {
                    
      createToast({text: "organisation altered"
        ,type: 'success'})
    })
    .catch(error => {
        if (error.response) {
            if ('detail' in error.response.data){
                createToast({text: error.response.data['detail']
                            ,type: 'error'})
            }                        
            console.error(JSON.stringify(error.response.data))
        } else if (error.message) {
            console.error(JSON.stringify(error.message))
        } else {
            console.error(JSON.stringify(error))
        }
    })
}

// Get aggregation text
function getAggregationText(type) {
  return type === 'avg' ? 'Durchschnitt' : 'Summe';
}

// Check if we have reached the maximum number of measurements (6)
const isMaxMeasurementsReached = computed(() => {
  return kpiConfig.value.measurements.length >= 6;
});

</script>

<template>
  <div class="row mt-3">
    <div class="col-12 mb-4">
      <div class="card shadow-sm">
        <div class="card-header bg-white py-3 d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <h2 class="h5 mb-0 me-2">KPI-Konfiguration</h2>
          </div>
        </div>
        <div class="card-body">
          <div class="row g-4">
            <!-- Recent Data Hours Configuration -->
            <div class="col-md-6">
              <div class="card h-100">
                <div class="card-header bg-white">
                  <h6 class="mb-0">Aktuelle Daten Konfiguration</h6>
                </div>
                <div class="card-body">
                  <div class="mb-3">
                    <label class="form-label">Stunden für "Aktuelle Daten" KPI</label>
                    <div class="input-group">
                      <input 
                        type="number" 
                        class="form-control" 
                        v-model="kpiConfig.recentDataHours" 
                        min="1" 
                        max="24"
                      />
                      <span class="input-group-text">Stunden</span>
                    </div>
                    <div class="form-text">
                      Anlagen mit Daten, die innerhalb dieser Stundenzahl gesendet wurden, werden als "aktuell" betrachtet.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <!-- Measurements Configuration -->
            <div class="col-md-6">
              <div class="card h-100">
                <div class="card-header bg-white d-flex justify-content-between align-items-center">
                  <h6 class="mb-0">Messwerte für Dashboard</h6>
                  <span class="badge bg-primary">{{ kpiConfig.measurements.length }}/6</span>
                </div>
                <div class="card-body">
                  <p class="text-secondary small mb-3">
                    Konfigurieren Sie bis zu 6 Messwerte, die im Dashboard angezeigt werden sollen.
                  </p>
                  
                  <!-- Add new measurement -->
                  <div class="mb-3">
                    <div class="input-group mb-2">
                      <select 
                        class="form-select" 
                        v-model="newMeasurement.kpi"
                        :disabled="isMaxMeasurementsReached"
                      >
                        <option value="">Messwert auswählen</option>
                        <option 
                          v-for="type in availableMeasurementTypes" 
                          :key="type" 
                          :value="type"
                          :disabled="isMeasurementConfigured(type)"
                        >
                          {{ type }}
                        </option>
                      </select>
                      <select 
                        class="form-select" 
                        v-model="newMeasurement.aggregation"
                        :disabled="isMaxMeasurementsReached"
                      >
                        <option value="avg">Durchschnitt</option>
                        <option value="sum">Summe</option>
                      </select>
                      <button 
                        class="btn btn-primary" 
                        @click="addMeasurement"
                        :disabled="!newMeasurement.kpi || isMaxMeasurementsReached"
                      >
                        <i class="fa-light fa-plus"></i>
                      </button>
                    </div>
                    <div v-if="isMaxMeasurementsReached" class="text-danger small">
                      Maximale Anzahl an Messwerten (6) erreicht.
                    </div>
                  </div>
                  
                  <!-- List of configured measurements -->
                  <div class="list-group">
                    <div 
                      v-for="(measurement, index) in kpiConfig.measurements" 
                      :key="index"
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <div>
                        <strong>{{ measurement.kpi }}</strong>
                        <div class="text-secondary small">
                          Aggregation: {{ getAggregationText(measurement.aggregation) }}
                        </div>
                      </div>
                      <div class="d-flex gap-2">
                        <div class="dropdown">
                          <button 
                            class="btn btn-sm btn-outline-secondary dropdown-toggle" 
                            type="button" 
                            data-bs-toggle="dropdown"
                          >
                            {{ getAggregationText(measurement.aggregation) }}
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <a 
                                class="dropdown-item" 
                                href="#" 
                                @click.prevent="changeAggregation(index, 'avg')"
                              >
                                Durchschnitt
                              </a>
                            </li>
                            <li>
                              <a 
                                class="dropdown-item" 
                                href="#" 
                                @click.prevent="changeAggregation(index, 'sum')"
                              >
                                Summe
                              </a>
                            </li>
                          </ul>
                        </div>
                        <button 
                          class="btn btn-sm btn-outline-danger" 
                          @click="removeMeasurement(index)"
                        >
                          <i class="fa-light fa-trash-can"></i>
                        </button>
                      </div>
                    </div>
                    
                    <!-- Empty state -->
                    <div 
                      v-if="kpiConfig.measurements.length === 0" 
                      class="list-group-item text-center text-secondary py-3"
                    >
                      <i class="bi bi-exclamation-circle me-2"></i>
                      Keine Messwerte konfiguriert. Die häufigsten Messwerte werden automatisch angezeigt.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer bg-white d-flex justify-content-end">
          <button class="btn btn-primary" @click="saveConfig">
            <i class="bi bi-save me-1"></i> Speichern
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-hover {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card-hover:hover {
  transform: translateY(-3px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
</style>