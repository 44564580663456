<template>
    <div class="container-fluid my-3 py-3">
        <template v-if="plant.last_alarm">
            <div class="row my-3" >
                <div class="col-12">
                    {{ $t("current_alarm") }}: {{plant.last_alarm}} <img :src="require('@/assets/icons/status_'+ plant.status_anlage + '.gif')"/>
                </div>
                <div class="col-12">
                    {{this.getTimeFormatted(plant.time_last_alarm)}}
                </div>
            </div>
            
        </template>
        <template v-else>
            <div class="row my-3">
                <div class="col-12">
                    {{ $t("no_current_alarm") }}
                </div>
            </div>
        </template>

        <div class="row my-3">
            <div class="col-3">
                {{ $t("color_status") }}
            </div>
            <div class="col-7 d-flex justify-content-between">
                <button type="button" @click="status = '2'" class="btn " :class="{'btn-danger': status === '2', 'btn-outline-danger': status !== '2'}">{{ $t("error") }} </button>
                <button type="button" @click="status = '1'" class="btn " :class="{'btn-warning': status === '1', 'btn-outline-warning': status !== '1'}">{{ $t("warning") }} </button>
                <button type="button" @click="status = '0'" class="btn " :class="{'btn-success': status === '0', 'btn-outline-success': status !== '0'}">{{ $t("ok") }} </button>
            </div>
            
        </div>
        <div class="row my-3">
            <div class="col-12">
                <div class="form-floating">
                    <input id="inputAlarmMessage" ref="name" type="text" name="name" class="form-control" maxlength="200" v-model="alarm_message" placeholder="name@example.com">
                    <label for="inputAlarmMessage" class="form-label" >{{ $t("inputAlarmMessage") }}</label>
                </div>
            </div>
        </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'ManualAlarmForPlant',
    emits: ['closeManualAlarmForPlant'],
    props: {
        plant: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            status: '0',
            alarm_message: ''
        }
    },
    mounted(){
    },
    methods: {
        getTimeFormatted(time){
            if(time){
                let updatedTime = new Date(time)
                return updatedTime.toLocaleString()

            }
            return ''
        },
        submitForm() {
            let formData = {
                alarm_txt: this.alarm_message,
                status: this.status
            }
            axios
                .post(`/api/v1/plants/${this.plant.id}/update_plant_alarmmessage/`, formData)
                .then(response => {
                    this.$emit('closeManualAlarmForPlant')
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
                
                
        }
    },
    computed:{
    }
}
</script>