<template>
    <div class="row mt-3">
        <div class="col-12">
            <table class="table table-striped table-hover clickable table-sm">
                <thead>
                    <tr>
                        <th>{{ $t("description") }}</th>
                        <th>{{ $t("first_name") }}</th>
                        <th>{{ $t("last_name") }}</th>
                        <th>{{ $t("email")}}</th>
                        <th>{{ $t("phone")}}</th>
                        <th>{{ $t("send_daily_report")}}</th>
                        <th></th>
                        <th v-if="this.canWriteUser"> <button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modal" @click="addUser()">{{ $t("newUser") }}</button></th>
                    </tr>
                </thead>
                <tbody>

                    <tr
                        v-for="user in allOrganisationReportUsers"
                        v-bind:key="user.id"
                        >
                        <td @click="editUser(user)" data-bs-toggle="modal" data-bs-target="#modal">{{ user.description}}</td>
                        <td @click="editUser(user)" data-bs-toggle="modal" data-bs-target="#modal">{{ user.first_name }}</td>
                        <td @click="editUser(user)" data-bs-toggle="modal" data-bs-target="#modal">{{ user.last_name}}</td>
                        <td @click="editUser(user)" data-bs-toggle="modal" data-bs-target="#modal">{{ user.email}}</td>
                        <td @click="editUser(user)" data-bs-toggle="modal" data-bs-target="#modal">{{ user.phone_number}}</td>
                        <td v-on:change="updateUser(user)">
                            <div class="form-check form-switch">
                                <input :disabled="user.email == ''" class="form-check-input" type="checkbox" role="switch" v-model="user.send_daily_report">
                            </div>
                        </td>
                        <td><button type="button" class="btn btn-sm btn-outline-secondary" @click="testEmailSms(user)">{{ $t("testEmailSms") }}</button></td>
                        <td v-if="this.canWriteUser"><button type="button" class="btn btn-sm btn-outline-danger" @click="deleteUser(user)">{{ $t("deleteUser") }}</button> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                <button type="button" id="modalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body modal-textarea">
                <component v-if="currentComponent" :is="currentComponent" v-bind="currentProperties" v-on:closeModal="closeModal"></component>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'

import AddReportUser from '@/components/report_users/AddReportUser.vue'
import EditReportUser from '@/components/report_users/EditReportUser.vue'
import ResetPasswordForUser from '@/components/masterdata/ResetPasswordForUser.vue'
import axios from 'axios'
export default {
    name: 'OrganisationReportUsers',
    components: {
        AddReportUser,
        EditReportUser,
        ResetPasswordForUser
    },
    data() {
        return {
            currentComponent: "",
            allOrganisationReportUsers: [],
            currentReportUser: ''
        }
    },
    async mounted(){
       await this.getAllReportUsersForCompanie()
    },
    methods: {
        updateUser(user){
            console.log('entry updateUser')
            axios
                .patch(`/api/v1/report_users/${user.id}/`, user)
                .then(response => {
                    
                    createToast({text: this.$t("report_user_altered")
                   ,type: 'success'})
                   this.$emit('closeModal')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })          
            console.log(('exit updateUser'))
        },  
        testEmailSms(user){
            console.log('entry testEmailSms')
            axios
                .get(`/api/v1/report_users/${user.id}/test_email_sms/`)
                .then(response => {
                    createToast({text: this.$t("email_sms_user tested")
                        ,type: 'success'})
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit testEmailSms')
        },
        deleteUser(user){
            console.log('entry deleteUser')
            axios
                .delete(`/api/v1/report_users/${user.id}/`)
                .then(response => {
                    createToast({text: this.$t("user deleted")
                        ,type: 'success'})
                    this.deleteUserFromFrontend(user)
                    console.log('user geloescht')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit deleteUser')
        },
        deleteUserFromFrontend(user){
            console.log('entry deleteUserFromFrontend')
            let pos_delete = this.allOrganisationReportUsers.map(e => e.id).indexOf(user.id)
            console.log('pos_delete: ' + pos_delete)
            this.allOrganisationReportUsers.splice(pos_delete, 1)
            
            console.log('exit deleteUserFromFrontend')
        },
        editUser(user){
            console.log('entry editUser')
            if(this.canWriteUser){
                this.currentComponent = "EditReportUser"
                this.currentReportUser = user
            }
            console.log('exit editUser')
        },
        addUser(){
            console.log('entry addUser')
            if(this.canWriteUser){
                this.currentComponent = "AddReportUser"
            }
            console.log('exit addUser')
        },
        getAllReportUsersForCompanie(){
            console.log('entry getAllReportUsersForCompanie')
            axios
                .get('/api/v1/report_users/')
                .then(response => {
                    this.allOrganisationReportUsers = response.data
                    console.log('users fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getAllReportUsersForCompanie')
        },
        closeModal(){
            console.log("closeModal")
            document.getElementById('modalClose').click();
            console.log("reload")
            this.currentComponent = ''
            this.getAllReportUsersForCompanie()
        },
    },
    computed:{
        canWriteUser(){
            return this.$store.getters.userHasPermission('user_group', 'can_write')
        },
        currentProperties(){
            return {organisation: this.organisation
                ,reportUser: this.currentReportUser}
        },
        getModalName(){
            
        },
        organisation(){
            return this.$store.getters.getOrganisation
        }
    }
}
</script>