<template>
    <div class="container-fluid my-3 py-3">
        <div class="row my-3">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="plantDashboardHeader.name" placeholder="name@example.com">
                    <label for="inputName" class="form-label" ><template v-if="plantDashboardHeader.is_group_name">{{ $t("group_name") }}</template><template v-else>{{ $t("component_name") }}</template></label>
                </div>
            </div>     
            <div class="col-6">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="is_group_name" v-model="plantDashboardHeader.is_group_name">
                    <label class="form-check-label" for="is_group_name" v-tooltip title="Anzeige aller Komponenten mit selben Gruppennamen" >
                        {{$t("dashboard_group_name")}}
                    </label>
                </div>
            </div>               
        </div>
        <div class="row my-3">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="plantDashboardHeader.column" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("column") }}</label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control" v-model="plantDashboardHeader.position" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("position") }}</label>
                </div>
            </div>
        </div>
        <div class="row my-3">
            <div class="col-4">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="positions_different_times" v-model="plantDashboardHeader.positions_different_times">
                    <label class="form-check-label" for="positions_different_times">
                        {{$t("positions_different_times")}}
                    </label>
                </div>
            </div>
            <div class="col-4">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="use_research_models" v-model="plantDashboardHeader.use_research_models">
                    <label class="form-check-label" for="use_research_models">
                        {{$t("use_research_models")}}
                    </label>
                </div>
            </div>
            <div class="col-4">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="use_value_translation" v-model="plantDashboardHeader.use_value_translation">
                    <label class="form-check-label" for="use_value_translation">
                        {{$t("use_value_translation")}}
                    </label>
                </div>
            </div>
        </div>
        
        <div class="row my-3" v-if="this.plantDashboardHeader.use_value_translation">
            <EditPlantDashboardHeaderTranslations :plantDashboardHeader="plantDashboardHeader"></EditPlantDashboardHeaderTranslations>
        </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import EditPlantDashboardHeaderTranslations from '@/components/dashboard/EditPlantDashboardHeaderTranslations.vue';
import axios from 'axios'
export default {
    name: 'AddPlantDashboardHeader',
    emits: ['closeModal'],
    props: {
        plantDashboardHeader: {
            type: Object,
            required: true,
        },
    },
    components: {
        EditPlantDashboardHeaderTranslations
    },
    data() {
        return {
            
        }
    },
    mounted(){
        console.log('entry mounted')
        this.focusInput()
        console.log('exit mounted')
    },
    methods: {
        focusInput() {
            console.log('entry focusInput')
            console.log(this.$refs.name)
            this.$refs.name.focus();
            console.log('exit focusInput')
        },
        submitForm() {
            
            axios
                .patch(`/api/v1/plantdashboards_headers/${this.plantDashboardHeader.id}/`, this.plantDashboardHeader)
                .then(response => {
        
                    createToast({text: this.$t("header altered")
                        ,type: 'success'})
                    

                this.$emit('closeModal')
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        }
    },
    computed:{
    }
}
</script>