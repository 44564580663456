<template>
    <div class="loading-block" v-if="!this.loadingDone"></div>
    <div class="card border-0 shadow-sm mt-3">
        <div class="card-header bg-white py-3">
            <div class="d-flex justify-content-between align-items-center">
                <h2 class="h5 mb-0">Anlagen Übersicht</h2>
            <div class="badge bg-primary">{{ plants.length }} Anlagen</div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="table-responsive">
            <table class="table table-hover mb-0" >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Letzte Daten</th>
                        <th>Letzte Stör-/Meldung</th>
                        <th>Meldetext</th>
                        <th>Messwerte</th>
                    </tr>
                </thead>
                <tbody>
                    <tr 
                        v-for="plant in sortedPlants" 
                        v-bind:key="plant.id"
                        @click="openInfoWindow(plant)"
                        :class="getCssClassForTablePlant(plant)"
                    >
                        
                        <td class="fw-medium"><img :src="require('@/assets/icons/'+ plant.type + '.png')"/>{{ plant.name }} </td>
                        <td>{{ getTimeSince(plant.time_last_data) }}</td>
                        <td>{{ getTimeSince(plant.time_last_alarm) }}</td>
                        <td>
                            {{ plant.last_alarm }}
                        </td>
                        <td>
                            <div class="d-flex flex-wrap gap-2">
                                <span 
                                    v-for="prio_value in plant.plant_prio_values" 
                                    :key="prio_value.id"
                                    class="badge bg-light text-dark border"
                                >
                                    {{ prio_value.name }}: {{ prio_value.value }} {{ prio_value.unit }}
                                </span>
                            </div>
                        </td>
                    </tr>
            
                    <!-- Empty state -->
                    <tr v-if="plants.length === 0">
                        <td colspan="6" class="text-center py-4 text-secondary">
                            <i class="bi bi-exclamation-circle me-2"></i>
                            Keine Anlagen gefunden. Bitte passen Sie Ihre Filterkriterien an.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>

    <button id="modalOpen" style="display:none;" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modal" aria-hidden="true">hidden</button>
    <button id="modal2Close" style="display:none;" class="btn btn-sm btn-primary" data-bs-dismiss="modal" data-bs-target="#changeStatusPlant" aria-hidden="true">hidden</button>
    <button id="modal3Close" style="display:none;" class="btn btn-sm btn-primary" data-bs-dismiss="modal" data-bs-target="#changeESNPlant" aria-hidden="true">hidden</button>
    
    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-xxl">
        <div class="modal-content ">
            <div class="modal-header">
                <h5 class="modal-title" id="modalLabel">{{currentPlant.name}}</h5>
                
                <button type="button" id="modalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetComponent()"></button>
            </div>
            <div class="modal-body modal-textarea">
                <template v-if="currentOverviewComponent != ''">
                    <component ref="overview" :is="currentOverviewComponent" :id='"test"' v-bind="currentProperties" v-on:closeModal="closeModal" v-on:updateTime="updateTime"></component>
                </template>
            </div>
            <div class="modal-footer justify-content-between">
                <p class="small">{{ $t("updatedAt") }} {{time}}</p>
                <div class="footer-buttons justify-content-between">
                    <button type="button" class="btn btn-outline-secondary" @click="generatePdf('test')"><i class="fa-solid fa-file-pdf"></i> {{ $t("export_pdf") }}</button>
                    <button type="button" class="btn btn-outline-secondary" @click="generateExcel()"><i class="fa-solid fa-file-excel"></i> {{ $t("export_excel") }}</button>
                    <button type="button" class="btn btn-outline-secondary" data-bs-target="#changeStatusPlant" data-bs-toggle="modal">{{ $t("change_status") }}</button>
                    <button type="button" class="btn btn-outline-secondary" data-bs-target="#changeESNPlant" data-bs-toggle="modal">{{ $t("change_esn") }}</button>

                </div>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="resetComponent()">{{ $t("close") }}</button>
            </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="changeStatusPlant" aria-hidden="true" aria-labelledby="changeStatusPlant" tabindex="-1">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="changeStatusPlant">{{currentPlant.name}} - {{ $t("change_status") }}</h5>
                    <button type="button" class="btn-close" data-bs-target="#modal" data-bs-toggle="modal"></button>
                </div>
                <div class="modal-body">
                    <ManualAlarmForPlant v-bind="currentManualAlarmForPlantProperties" v-on:closeManualAlarmForPlant="closeManualAlarmForPlant"/>
                </div>
                <div class="modal-footer">
                    <button id="closeModalChangeStatusPlant" class="btn btn-secondary" data-bs-target="#modal" data-bs-toggle="modal">{{ $t("back") }}</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="changeESNPlant" aria-hidden="true" aria-labelledby="changeESNPlant" tabindex="-1">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="changeESNPlant">{{currentPlant.name}} - {{ $t("change_esn") }}</h5>
                    <button type="button" class="btn-close" data-bs-target="#modal" data-bs-toggle="modal"></button>
                </div>
                <div class="modal-body">
                    <ManualChangeESNForPlant v-bind="currentManualChangeESNForPlantProperties" v-on:closeManualChangeESNForPlant="closeManualChangeESNForPlant"/>
                </div>
                <div class="modal-footer">
                    <button id="closeModalChangeESNPlant" class="btn btn-secondary" data-bs-target="#modal" data-bs-toggle="modal">{{ $t("back") }}</button>
                </div>
            </div>
        </div>
    </div>

    
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import { jsPDF } from "jspdf";
import apiClient from "@/services/apiClient";
import BiogasOverview from '@/components/dashboard/BiogasOverview.vue'
import ManualAlarmForPlant from '@/components/Alarmmessages/ManualAlarmForPlant.vue'
import ManualChangeESNForPlant from '@/components/dashboard/ManualChangeESNForPlant.vue'
import axios from 'axios'
export default {
    name: 'ListDashboard',
    emits: [],
    props: {
        plants: {
            type: Object,
            required: true,
        },
        
    },
    components: {
        BiogasOverview,
        ManualAlarmForPlant
    },
    data() {
        return {
            loadingDone: true,
            currentPlant: {},
            time: '',
            currentOverviewComponent: '',
            polling: null,
            infoWindowOpen: false
        }
    },
    mounted(){
        console.log('entry mounted')
        this.pollData()
        console.log('exit mounted')
    },
    unmounted(){
          console.log('component unmounted')
          
          clearInterval(this.polling)
    },
    methods: {
        getCssClassForTablePlant(plant){
            const maxStatus = this.getMaxPlantStatus(plant)
            if( maxStatus == 2){
                return 'table-danger'
            }
            else if(maxStatus == 1){
                return 'table-warning'
            }
            return 'table-success'
        },
        getTimeSince(date_string) {
            if (!date_string) return '';

            const date = new Date (date_string)
  
            const now = new Date();
            const diffMs = now - date;
            const diffMins = Math.floor(diffMs / (1000 * 60));
  
            if (diffMins < 60) {
                return `vor ${diffMins} min`;
            } else if (diffMins < 24 * 60) {
                const hours = Math.floor(diffMins / 60);
                return `vor ${hours} h`;
            } else {
                const days = Math.floor(diffMins / (60 * 24));
                return `vor ${days} d`;
            }
        },
        closeManualAlarmForPlant(){
            console.log('entry closeManualAlarmForPlant')
            this.getPlants()
            document.getElementById('modal2Close').click();
            this.resetComponent()
            console.log('exit closeManualAlarmForPlant')
        },
        closeManualChangeESNForPlant(){
            console.log('entry closeManualChangeESNForPlant')
            this.getPlants()
            document.getElementById('modal3Close').click();
            this.resetComponent()
            console.log('exit closeManualChangeESNForPlant')
        },
        generateExcel(){
            console.log('entry generateExcel')
            this.$refs.overview.getDataForPlantExcel()
            console.log('exit generateExcel')
        },
        generatePdf(){
            console.log('entry generatePDF')
            this.$refs.overview.getDataForPlantPdf()
            console.log('exit genetarePdf')
        },
        getMaxPlantStatus(plant){
            return Math.max(plant.status_anlage, plant.status_data)
        },
        resetComponent(){
            console.log('entry resetComponent')
            this.currentOverviewComponent = ''
            this.infoWindowOpen = false
            console.log('exit resetComponent')
        },
        pollData () {
              
            this.polling = setInterval(() => {
                console.log('timer hat gefeuert. erstmal schlafen')
                setTimeout(() => {
                    if(!this.infoWindowOpen){
                        this.getPlants()
                    }
                    
                }, 2000)
            }, process.env.VUE_APP_REFRESH_INTERVALL_OVERVIEW)

          },
          async getPlants(){
            this.loadingDone = false
            try {
                const response_dashboard =  await apiClient.get("/api/v1/dashboard/")
                console.log('plants loaded')
                console.log(response_dashboard.data)
                this.$store.commit('setPlants', response_dashboard.data)
                this.loadingDone = true
            }catch (error) {
                console.info("Fehler beim Laden von dashboard");

                console.log(JSON.stringify(error))
            } 
        },
        getTimeFormatted(time){
            if(time){
                let updatedTime = new Date(time)
                return updatedTime.toLocaleString()

            }
            return ''
        },
        updateTime(time){
                this.time = time
            },
        closeModal(){
                console.log("closeModal")
                document.getElementById('modalClose').click();
                this.resetComponent()
                
            },
            openInfoWindow(plant){
                console.log('entry openInfoWindow')
                console.log('plant: ')
                console.log(plant)
                this.currentPlant = plant
                this.currentOverviewComponent = 'BiogasOverview'
                this.infoWindowOpen = true
                document.getElementById('modalOpen').click();
                console.log('exit openInfoWindow')
            },
    },
    computed:{
        currentProperties(){
            return {plant: this.currentPlant}
        },
        currentManualAlarmForPlantProperties(){
            return {plant: this.currentPlant}
        },
        currentManualChangeESNForPlantProperties(){
            return {plant: this.currentPlant}
        },
        sortedPlants(){
            return this.plants.sort((a,b) => {
                if(Math.max(a.status_anlage, a.status_data) < Math.max(b.status_anlage, b.status_data)){
                    return 1
                }
                if (Math.max(a.status_data, a.status_anlage) > Math.max(b.status_data, b.status_anlage)){
                    return -1
                }
                return 0
            })
        },
        statusGroupedPlants(){
            var statusSortedPlants = this.plants.sort((a,b) => {
                if(Math.max(a.status_anlage, a.status_data) < Math.max(b.status_anlage, b.status_data)){
                    return 1
                }
                if (Math.max(a.status_data, a.status_anlage) > Math.max(b.status_data, b.status_anlage)){
                    return -1
                }
                return 0
            })

            var groupedArray = []
            var status = 'a'
            var plantArray = []
            var plantArrayObject = {}
            statusSortedPlants.forEach(plant =>{
                if(status === 'a'){
                    status = Math.max(plant.status_anlage, plant.status_data)
                }
                if(status !== Math.max(plant.status_anlage, plant.status_data)){
                    plantArrayObject.status = status
                    plantArrayObject.plants = plantArray
                    groupedArray.push(plantArrayObject)
                    plantArray = []
                    plantArrayObject = {}
                    status = Math.max(plant.status_anlage, plant.status_data)
                }
                plantArray.push(plant)
            })
            plantArrayObject.status = status
            plantArrayObject.plants = plantArray
            groupedArray.push(plantArrayObject)

            console.log('groupedArray')
            console.log(groupedArray)

            return groupedArray
        }
    }
}
</script>

