<template>
  <header class="bg-white shadow-sm">
    <div class="">

      <div class="container py-3">
        <!-- Compact Header -->
       <!-- <div class="d-flex flex-md-column flex-row gap-md-2 gap-0 justify-content-md-between align-items-center mb-2">-->
        <div class="d-flex flex-column flex-xl-row gap-2 gap-xl-0 justify-content-between align-items-xl-center mb-2">
          <div class="d-flex align-items-center gap-3">
            <button 
              class="btn btn-sm btn-outline-primary" 
              @click="toggleMonitoring"
            >
              <i class="fa-light" :class="isMonitoringVisible ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
              Kennzahlen
            </button>
  
            <!-- Quick Stats -->
            <div v-if="!isMonitoringVisible" class="d-flex gap-3 small">
              <span :class="`status-${kritischeAnlagen > 0 ? 'rot' : 'grün'}`">
                <i class="fa-light fa-octagon-exclamation me-1"></i>
                {{ kritischeAnlagen }} Kritisch
              </span>
              <span :class="`status-${warnungAnlagen > 0 ? 'gelb' : 'grün'}`">
                <i class="fa-light fa-triangle-exclamation me-1"></i>
                {{ warnungAnlagen }} Warnung
              </span>
              <span class="status-gruen">
                <i class="fa-light fa-circle-check me-1"></i>
                {{ normaleAnlagen }} Normal
              </span>
            </div>
                     
            <!-- Health Progress Bar (only when collapsed) -->
            <div v-if="!isMonitoringVisible" class="health-indicator d-flex align-items-center gap-2">
              <div class="progress" style="width: 150px; height: 8px;">
                <div 
                  class="progress-bar" 
                  role="progressbar" 
                  :style="{ 
                    width: `${gesundheitsIndex}%`, 
                    backgroundColor: getHealthColor(gesundheitsIndex) 
                  }" 
                  :aria-valuenow="gesundheitsIndex" 
                  aria-valuemin="0" 
                  aria-valuemax="100"
                ></div>
              </div>
              <span class="small" :style="{ color: getHealthColor(gesundheitsIndex) }">
                {{ gesundheitsIndex }}% ({{ getHealthStatusText(gesundheitsIndex) }})
              </span>
            </div>
          </div>

          <!-- Dashboard Controls -->
          <div class="d-flex align-items-center gap-3" v-if="!isMobile">
            <div class="separator"></div>
            <span>Ansicht:</span>
            <!-- View Toggle -->
            <div class="btn-group btn-group-sm">
              <button 
                type="button" 
                class="btn" 
                :class="viewMode === 'TileDashboard' ? 'btn-primary' : 'btn-outline-primary'"
                @click="changeViewMode('TileDashboard')"
              >
                <i class="fa-light fa-grid"></i> Kacheln
              </button>
              <button 
                type="button" 
                class="btn" 
                :class="viewMode === 'ListDashboard' ? 'btn-primary' : 'btn-outline-primary'"
                @click="changeViewMode('ListDashboard')"
              >
                <i class="fa-light fa-bars"></i> Liste
              </button>
              <button 
                type="button" 
                class="btn" 
                :class="viewMode === 'ListDashboardNew' ? 'btn-primary' : 'btn-outline-primary'"
                @click="changeViewMode('ListDashboardNew')"
              >
                <i class="fa-light fa-chart-line"></i> Grafik
              </button>
              <button 
                type="button" 
                class="btn" 
                :class="viewMode === 'GoogleMaps' ? 'btn-primary' : 'btn-outline-primary'"
                @click="changeViewMode('GoogleMaps')"
              >
                <i class="fa-light fa-map-location-dot"></i> Karte
              </button>
            </div>

            <div class="separator"></div>
          
            <div class="d-flex align-items-center gap-3" v-if="allAvailableTypes.length > 1">
              <span>Filter Anlagentyp:</span>
              <div class="btn-group btn-group-sm">
                <template v-for="typ in allAvailableTypes" :key="typ">
                  <button
                    class="btn btn-sm btn-outline-primary" 
                    :class="{ 'filter-selected': activeFilter.includes(typ) }"
                    :src="require('@/assets/icons/' + typ + '.png')"
                    @click="setTypeFilter(typ)"
                  >
                    <img :src="require('@/assets/icons/' + typ + '.png')">
                  </button>
                </template>  
              </div>
            </div>

            <div class="separator"></div>

            <!-- Search -->
            <div class="input-group input-group-sm flex-grow-1 flex-md-grow-0" style="width: 300px;" v-if="plants.length > 5">
              <span class="input-group-text">
                <i class="fa-light fa-magnifying-glass"></i>
              </span>
              <input 
                type="text" 
                class="form-control" 
                placeholder="Filter Anlagenname..." 
                :value="searchQuery"
                @input="updateSearch($event.target.value)"
              />
              <button 
                v-if="searchQuery" 
                class="btn btn-outline-secondary" 
                type="button"
                @click="clearSearch"
              >
                <i class="fa-light fa-xmark"></i>
              </button>
            </div>
          </div>
          
        </div>
        <div class="kpi-dashboard-wrapper">
  
          <div class="kpi-dashboard mt-2"
              :class="{ 'show': isMonitoringVisible }"
          >
    
            <div class="row g-3 mb-1">
              <!-- System Health -->
              <div class="col-md-6 col-lg-3">
                <div class="card h-100 card-hover">
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                    <h6 class="card-title mb-0 text-secondary">Systemgesundheit</h6>
                    <span class="fs-4 fw-bold">{{ gesundheitsIndex }}%</span>
                  </div>
                  <div class="progress mb-2" style="height: 6px;">
                    <div 
                      class="progress-bar" 
                      role="progressbar" 
                      :style="{ width: `${gesundheitsIndex}%`, backgroundColor: getHealthColor(gesundheitsIndex) }" 
                      :aria-valuenow="gesundheitsIndex" 
                      aria-valuemin="0" 
                      aria-valuemax="100"
                    >
                    </div>
                  </div>

                  <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <span class="dot-indicator dot-rot me-2"></span>
                      <span>{{ kritischeAnlagen }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <span class="dot-indicator dot-gelb me-2"></span>
                      <span>{{ warnungAnlagen }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <span class="dot-indicator dot-gruen me-2"></span>
                      <span>{{ normaleAnlagen }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
              <!-- Average Values -->
              <div class="col-md-6 col-lg-3">
                <div class="card h-100 card-hover">
                  <div class="card-body">
                    <h6 class="card-title mb-0 text-secondary">Messwerte</h6>
                    <div class="row g-3">
                      <div v-for="(measurement, index) in topMeasurements" :key="index" class="col-4 text-center" v-tooltip :title="getTooltip(measurement)">
                        <div class="text-secondary small">{{ measurement.name }}</div>
                        <div class="fw-bold">{{ measurement.value }}</div>
                        <div class="small text-secondary">{{ measurement.unit }}</div>
                      </div>
                      <div v-if="topMeasurements.length === 0" class="col-12 text-center text-secondary fst-italic py-3">
                        Keine Messwerte verfügbar
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      
              <!-- Data Metrics -->
              <div class="col-md-6 col-lg-3">
                <div class="card h-100 card-hover">
                  <div class="card-body">
                    <h5 class="card-title text-secondary fs-6 mb-3">Datenzustand</h5>
                    <div class="row mb-3">
                      <div class="col-12 text-center">
                        <div class="position-relative d-inline-block">
                          <svg width="60" height="60" viewBox="0 0 120 120">
                            <circle cx="60" cy="60" r="54" fill="none" stroke="#e9ecef" stroke-width="12" />
                            <circle 
                              cx="60" 
                              cy="60" 
                              r="54" 
                              fill="none" 
                              stroke="var(--bs-primary)" 
                              stroke-width="12" 
                              stroke-dasharray="339.292" 
                              :stroke-dashoffset="339.292 * (1 - recentDataPercentage / 100)" 
                              transform="rotate(-90 60 60)" 
                            />
                          </svg>
                          <div class="position-absolute top-50 start-50 translate-middle fw-bold">{{ recentDataPercentage }}%</div>
                        </div>
                        <div class="small text-secondary mt-1">
                          Aktuelle Daten
                          <span class="d-block">({{ organisation.recent_data_hours }}h)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Total Systems -->
              <div class="col-md-6 col-lg-3">
                <div class="card h-100 card-hover">
                  <div class="card-body text-center">
                    <h5 class="card-title text-secondary fs-6 mb-3">Gesamtsysteme</h5>
                    <div class="display-3 fw-bold mb-2">{{ props.plants.length }}</div>
                    <div class="text-secondary mb-3">Anlagen im Monitoring</div>
                    <div class="small text-secondary border-top pt-2">
                      Älteste Daten: {{ hoursSinceOldestData }}h
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
  
<script setup>
  import { defineProps, computed, ref, watch } from 'vue';
  import { useResponsive } from '@/composables/useResponsive';

  const props = defineProps({
    plants: {
      type: Object,
      required: true
    },
    organisation: {
      type: Object,
      required: true
    },
    viewMode: {
      type: String,
      required: true
    },
    searchQuery: {
      type: String,
      required: true
    },
    activeFilter: {
      type: Array,
      required: true
    },
    allAvailableTypes: {
      type: Object,
      required: true
    }
  });

  const emit = defineEmits(['updateViewMode', 'updateSearch', 'updateTypeFilter']);
  // Monitoring visibility state
  const isMonitoringVisible = ref(false);
  const userPreferredMonitoringVisible = ref(false);

  // Use the shared responsive composable
  const { isMobile } = useResponsive();

  // Watch for changes in screen size and update monitoring visibility
  watch(isMobile, (newIsMobile, oldIsMobile) => {
    // If transitioning to mobile, hide monitoring
    if (!oldIsMobile && newIsMobile) {
      isMonitoringVisible.value = false;
    } 
    // If transitioning back to desktop, restore user's preference
    else if (oldIsMobile && !newIsMobile) {
      isMonitoringVisible.value = userPreferredMonitoringVisible.value;
    }
  });

  // Watch for user changes to monitoring visibility and update preference
  watch(isMonitoringVisible, (newValue) => {
    // Only update preference if we're not on mobile or if explicitly toggled on mobile
    if (!isMobile.value || newValue === true) {
      userPreferredMonitoringVisible.value = newValue;
    }
  });

  function toggleMonitoring() {
    isMonitoringVisible.value = !isMonitoringVisible.value;
  }

  function getTooltip(measurement){
    if(measurement.aggregation == 'avg')
      return "Durchschnitt"
    return "Summe"
  }

  function getHealthColor(value) {
    if (value >= 80) return 'var(--bs-success)';
    if (value >= 50) return 'var(--bs-warning)';
    return 'var(--bs-danger)';
  }

  const measurementCounts = computed(() => {
    if (!props.plants) return [];
    let mesCount = {}
    props.plants.forEach(plant => {
      plant.plant_prio_values.forEach(prio_value => {
        if (!mesCount[prio_value.name]) {
        mesCount[prio_value.name] = 0;
      }
      mesCount[prio_value.name]++;
      })
      
    })
    return mesCount
  })
  const commonMeasurementTypes = computed(() => {
    if (!props.plants) return [];
    if (!props.organisation.organisation_kpis) return [];
    let mesCountArray = []
    let mesCount = {}
    if (props.organisation.organisation_kpis.length == 0){
      
      props.plants.forEach(plant => {
        plant.plant_prio_values.forEach(prio_value => {
          
          if (!mesCount[prio_value.name]) {
            mesCount[prio_value.name] = 0;
          }
          mesCount[prio_value.name]++;
        })
      
      })
      Object.keys(mesCount).sort((a, b) => measurementCounts[b] - measurementCounts[a]).slice(0, 6).forEach(mes => {
        mesCount = {name: mes,
                    aggregation: 'avg'
        }
        mesCountArray.push(mesCount)
      })
    }else{
      props.organisation.organisation_kpis.forEach(kpi => {
        mesCount = {name: kpi.kpi,
                    aggregation: kpi.aggregation
        }
        mesCountArray.push(mesCount)
      })
    }
    
    console.log('mesCount', mesCount)
    console.log('mesCountArray', mesCountArray)
    return mesCountArray
  })
  // Get the top 3 most common measurement types for display
  const topMeasurements = computed(() => {
    if (!props.plants) return [];
    console.log('entry topMeasurements')
    console.log('commonMeasurementTypes', commonMeasurementTypes.value)
    return commonMeasurementTypes.value.map(type => ({
      name: type.name,
      aggregation: type.aggregation,
      ...measurementAverages.value[type.name]
    })).filter(item => item.value !== undefined);
  });

  // Calculate measurement averages
  const measurementAverages = computed(() => {
    console.log('entry measurementAverages')
    let values = {}
    console.log('commonMeasurementTypes', commonMeasurementTypes.value)
    commonMeasurementTypes.value.forEach(type => {
      console.log('type', type)
      let sum = 0;
      let count = 0;
      let unit = '';
    
      props.plants.forEach(plant => {
        const value = plant.plant_prio_values.find(v => v.name === type.name);
        if (value) {
          sum += Number(value.value);
          count++;
          unit = value.unit;
        }
      });
      if (count > 0) {
        if (type.aggregation === 'avg') {
          // Calculate average
          values[type.name] = {
            value: Math.round(sum / count * 10) / 10,
            unit: unit
          };
        } else if (type.aggregation === 'sum') {
          // Use sum
          values[type.name] = {
            value: Math.round(sum * 10) / 10,
            unit: unit
          };
        }
      }
      
    });
    return values
  });
  
  
  const gesundheitsIndex = computed(() => {
    if (!props.plants) return [];
    return Math.round(((normaleAnlagen.value * 100) + (warnungAnlagen.value * 50)) / props.plants.length)
  });

  const kritischeAnlagen = computed(() => {
    if (!props.plants) return [];
  
    return props.plants.filter(plant =>  Math.max(plant.status_data, plant.status_anlage) === 2).length
  });

  const warnungAnlagen = computed(() => {
    if (!props.plants) return [];
  
    return props.plants.filter(plant =>  Math.max(plant.status_data, plant.status_anlage) === 1).length
  });

  const normaleAnlagen = computed(() => {
    if (!props.plants) return [];
  
    return props.plants.filter(plant =>  Math.max(plant.status_data, plant.status_anlage) === 0).length
  });

  const hoursSinceOldestData = computed(() => {
    if (!props.plants) return [];
    let now = new Date()
    let oldestData = new Date()
    
    props.plants.forEach(plant => {
      if(plant.time_last_data){
        let plantDate = new Date(plant.time_last_data)
        if(plantDate < oldestData){
          console.log('plant.time_last_data', plant.time_last_data)
          oldestData = plantDate
        }
      } 
    });
    console.log('oldestData', oldestData)
    return Math.round((now - oldestData) / (1000 * 60 * 60));
  })

  const recentDataPercentage = computed(() => {
    // Calculate data freshness
    const now = new Date();
    const recentThreshold = 1000 * 60 * 60; // 1 hour
    const recentDataCount = props.plants.filter(
      plant => (now - new Date(plant.time_last_data)) < recentThreshold
    ).length;
    return Math.round((recentDataCount / props.plants.length) * 100);
  })

  // Get health status text
  function getHealthStatusText(value) {
    if (value >= 80) return 'Gut';
    if (value >= 50) return 'Mittel';
    return 'Kritisch';
  }
  // Handle view mode change
  function changeViewMode(mode) {
    emit('updateViewMode', mode);
  }

  // Handle search query change
  function updateSearch(value) {
    emit('updateSearch', value);
  }

  function setTypeFilter(value){
    emit('updateTypeFilter', value)
  }

  // Clear search
  function clearSearch() {
    emit('updateSearch', '');
  }
</script>

<style>
.health-indicator.fade-in {
  opacity: 1;
  transform: translateY(0);
}
/* KPI Dashboard Animation */
.kpi-dashboard-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease;
}

.kpi-dashboard {
  opacity: 0;
  overflow: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.kpi-dashboard.show {
  opacity: 1;
  transform: translateY(0);
}

.kpi-dashboard-wrapper:has(.kpi-dashboard.show) {
  grid-template-rows: 1fr;
}
/* Responsive adjustments */
@media (max-width: 767.98px) {
  .btn-group .btn {
    padding: 0.375rem 0.75rem;
  }
  
  .input-group {
    max-width: none;
  }

  .separator {
    display: none;
  }
}

/* Visual Separator */
.separator {
  width: 1px;
  height: 24px;
  background-color: #dee2e6;
}
</style>