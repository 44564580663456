// Composable for handling responsive behavior
import { ref, onMounted, onBeforeUnmount } from 'vue';

export function useResponsive(options = {}) {
  // Default breakpoint is Bootstrap's md breakpoint (768px)
  const breakpoint = options.breakpoint || 768;
  
  // Reactive state
  const isMobile = ref(false);
  
  // Check screen size
  function checkScreenSize() {
    isMobile.value = window.innerWidth < breakpoint;
    return isMobile.value;
  }
  
  // Initialize on mount
  onMounted(() => {
    console.log('entry onMounted useResponsive')
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
  });
  
  // Clean up on unmount
  onBeforeUnmount(() => {
    window.removeEventListener('resize', checkScreenSize);
  });
  
  return {
    isMobile,
    checkScreenSize
  };
}