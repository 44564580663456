<template>
    <div class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
        <NavAuthGroupSidebar v-if="this.allAuthGroups.length > 0" v-bind="currentAuthGroupsProperties" 
            v-on:setAuthGroup="setAuthGroup"></NavAuthGroupSidebar>
    </div>
    <div class="col-md-9 ms-sm-auto col-lg-10">
        <PermissionsForAuthGroup v-if="this.currentAuthGroup" v-bind="currentProperties"></PermissionsForAuthGroup>
    </div>
</template>

<script>
    

import { createToast } from '@/assets/js/myToast.js'
import NavAuthGroupSidebar from '@/components/permissions/NavAuthGroupSidebar.vue'
import PermissionsForAuthGroup from '@/components/permissions/PermissionsForAuthGroup.vue'
import axios from 'axios'
export default {
    name: 'Permissions',
    emits: [
            ],
    components: {
        NavAuthGroupSidebar,
        PermissionsForAuthGroup
        },
    props: {
    },
    data() {
        return {
            currentAuthGroup: "",
            allAuthGroups: []
        }
    },
    mounted(){
        this.checkAndLoadAuthGroups()
    },
    updated(){
        
    },
    methods: {
        checkAndLoadAuthGroups(){
            console.log('entry checkAndLoadAuthGroups')
            if(this.allAuthGroups.length < 1){
                axios
                    .get('/api/v1/allAuthgroup/')
                    .then(response => {
                        console.log('authgroup loaded')
                        console.log(response.data)
                        this.allAuthGroups = response.data
                    
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
            }
            console.log('exit checkAndLoadAuthGroups')
        },
        setAuthGroup(authGroup){
            console.log('entry setAuthGroup')
            this.currentAuthGroup = authGroup
            console.log('exit setAuthGroup')
        },
        
    },
    computed:{
        currentAuthGroupsProperties(){
                return {authGroups: this.allAuthGroups}
            },
        currentProperties(){
                return {authGroup: this.currentAuthGroup}
            },
    }
}
</script>