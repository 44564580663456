<template>
    <div class="page-edit-plant-user-mapping my-3">
        <div class="row">
            <div class="col-6">
                <div class="card shadow-sm">
                    <div class="card-header d-flex align-items-center">
                        <p class="text-center h5">{{ $t("plant_user_mapping") }} - {{plant.name}}</p>
                            
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 text-danger">
                                Bitte beachten Sie, dass die Aktualisierung der Zuordnung erst nach 15 Minuten ersichtlich ist.
                            </div>
                        </div>
                        <div class="row my-2">
                            <template
                                v-for="users in getAllPlantUsersFormatted"
                                v-bind:key="users.id"
                            >
                                <div :class="{'col-6': getAllPlantUsersFormatted.length > 1, 'col': getAllPlantUsersFormatted.length === 1}">
                                    <template
                                        v-for="user in users"
                                        v-bind:key="user.id"
                                    >
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" :value="user.id" :id="user.id" v-model="plant.users">
                                            <label class="form-check-label" :for="user.id" >
                                                {{user.username}}
                                            </label>
                                        </div>

                                    </template>
                                </div>
                        
                            </template>
                        </div>
                        <div class="text-end">
                            <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'EditPlantUserMapping',
    props: {
        plant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            allOrganisationUsers: []
        }
    },
    async mounted(){
       await this.getAllUsersForCompanie()
    },
    updated(){
        
    },
    methods: {
        getAllUsersForCompanie(){
            console.log('entry getAllUsersForCompanie')
            axios
                .get('/api/v1/allOrganisationUsers/')
                .then(response => {
                    this.allOrganisationUsers = response.data
                    console.log('users fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getAllUsersForCompanie')
        },
        submitForm() {
            console.log('entry submitForm')
            axios
                .patch(`/api/v1/plants/${this.plant.id}/`, this.plant)
                .then(response => {
                    createToast({text: this.$t("plant_altered")
                    ,type: 'success'})
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit submitForm')
        }
    },
    computed:{
        getAllPlantUsersFormatted(){
            console.log('entry getAllPlantUsersFormatted')
            console.log('activeComponent:')
            console.log(this.allOrganisationUsers)
            let array_length = this.allOrganisationUsers.length
            let formatted_array = []

            if(array_length < 2){
                formatted_array[0] = this.allOrganisationUsers
            }else{
                let users = this.allOrganisationUsers.slice(0, Math.round(array_length/2))
                let users2 = this.allOrganisationUsers.slice(Math.round(array_length/2), array_length)
                formatted_array[0] = users
                formatted_array[1] = users2
                console.log('users')
                console.log(users)
                console.log('users2')
                console.log(users2)
            }
            console.log('formatted_array')
            console.log(formatted_array)
            console.log('exit getAllPlantUsersFormatted')
            return formatted_array
        },
    }
}
</script>