<template>
    <div class="page-config-live-data-plant">

        <div class="card shadow-sm">
            <div class="card-header text-center">

            </div>
            <div class="card-body ">
                <div class="row">
                    <template
                        v-for="plants in getAllPlantsOrganisation"
                        v-bind:key="plants.id"
                    >
                        <div :class="{'col-6': getAllPlantsOrganisation.length > 1, 'col': getAllPlantsOrganisation.length === 1}">
                            <template
                                v-for="plant in plants"
                                v-bind:key="plant.id"
                            >
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :value="plant.id" :id="plant.name" v-model="liveData.plants">
                                    <label class="form-check-label" :for="plant.name">
                                        {{plant.name}} <template v-if="plant.system_bez">({{ plant.system_bez }}) </template> 
                                    </label>
                                </div>

                            </template>
                        </div>
                        
                    </template>
                </div>
                <div class="text-end">
                    <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'ConfigLiveDataPlant',
    props: {
        organisation: {
            type: Object,
            required: true,
        },
        liveData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            
        }
    },
    mounted(){
        
    },
    methods: {
        
    },
    computed:{
        getAllPlantsOrganisation(){
            console.log('entry getAllPlantsOrganisation')

            let array_length = this.$store.getters.getPlants.length
            let formatted_array = []

            if(array_length < 2){
                formatted_array[0] =  this.$store.getters.getPlants
            }else{
                let descriptions = this.$store.getters.getPlants.slice(0, Math.round(array_length/2))
                let descriptions2 =  this.$store.getters.getPlants.slice(Math.round(array_length/2), array_length)
                formatted_array[0] = descriptions
                formatted_array[1] = descriptions2
                console.log('descriptions')
                console.log(descriptions)
                console.log('descriptions2')
                console.log(descriptions2)
            }
            console.log('formatted_array')
            console.log(formatted_array)
            console.log('exit getAllPlantsOrganisation')
            return formatted_array
        },
        submitForm() {
            console.log('entry submitForm')
            axios
                .patch(`/api/v1/organisation_live_datas/${this.liveData.id}/`, this.liveData)
                .then(response => {
                    createToast({text: this.$t("live_data_plant_altered")
                    ,type: 'success'})
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit submitForm')
        }
    }
}
</script>