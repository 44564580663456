<template>
    <div class="container-fluid my-3 py-3">
        <div class="row my-3">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="plantDashboardRowColumn.name" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("component_name") }}</label>
                </div>
            </div>     
                        
        </div>
        <div class="row my-3">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control" v-model="plantDashboardRowColumn.position" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("position") }}</label>
                </div>
            </div>
        </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'EditPlantDashboardRowColumn',
    emits: ['closeModal'],
    props: {
        plantDashboardRowColumn: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            
        }
    },
    mounted(){
        console.log('entry mounted')
        this.focusInput()
        console.log('exit mounted')
    },
    methods: {
        focusInput() {
            console.log('entry focusInput')
            console.log(this.$refs.name)
            this.$refs.name.focus();
            console.log('exit focusInput')
        },
        submitForm() {
            
            axios
                .patch(`/api/v1/plantdashboards_row_columns/${this.plantDashboardRowColumn.id}/`, this.plantDashboardRowColumn)
                .then(response => {
        
                    createToast({text: this.$t("header altered")
                        ,type: 'success'})
                    

                this.$emit('closeModal')
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        }
    },
    computed:{
    }
}
</script>