<template>
    <div v-if="this.dailyReportLoaded">
        <div class="row my-3" v-for="(page, index) in Array(this.dailyReport.num_pages)"
            v-bind:key="index">
            <div class="col-12">
                <div class="card shadow-sm">
                    <div class="card-body scroll-y">
                    
                        <table id="test" ref="testHtml" class="table table-striped table-hover table-bordered table-fixed-first-column">
                            <thead>
                                <tr>
                                    <template v-for="header in this.dailyReport.page_headers[index].headers"
                                        v-bind:key="header">
                                        <template v-if="header.name != 'spacing'">
                                            <th :rowspan="header.rowspan" :colspan="header.colspan">{{header.name}}</th>
                                        </template>
                                    </template>
                            
                                </tr>
                                <tr>
                                    <template v-for="subheader in this.dailyReport.page_headers[index].subheaders"
                                        v-bind:key="subheader">
                                        <template v-if="subheader.name != 'spacing' && subheader.name != 'hide'">
                                            <th :rowspan="subheader.rowspan" :colspan="subheader.colspan">{{subheader.name}}</th>
                                        </template>
                                    </template>
                                </tr>
                                <tr>

                                    <template v-for="header_pos in this.dailyReport.page_headers[index].positions"
                                    v-bind:key="header_pos">
                                        <template v-if="header_pos.name != 'spacing'">
                                            <th>{{header_pos.name}}</th>
                                        </template>
                                    </template>
                                </tr>
                            </thead>
                            
                            <tbody>
                                <template
                                    v-for="position in this.dailyReport.page_positions[index]"
                                    v-bind:key="position.id"
                                >
                                    <tr>
                                        <td>
                                            {{getDateFormatted(position.created_at_date)}}
                                        </td>
                                        <template
                                            v-for="value in position.data"
                                            v-bind:key="value.id"
                                        >   
                                            <template v-if="value.value != 'spacing'">

                                                <td class="text-end">
                                                    {{value.value}} <template v-if="value.unit"> <span class="table-unit">{{ value.unit }}</span></template>
                                                </td>
                                            </template>
                                        </template>
                                    </tr>
                                </template>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>{{this.dailyReport.page_footer[index].name}}</th>
                                    <template
                                        v-for="value in this.dailyReport.page_footer[index].data"
                                        v-bind:key="value.id"
                                    >
                                        <template v-if="value.value != 'spacing'">
                                            <th class="text-end">
                                                {{value.value}} <template v-if="value.unit"><span class="table-unit"> {{ value.unit }}</span></template>
                                            </th>
                                        </template>
                                    </template>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import axios from 'axios'
import { createToast } from '@/assets/js/myToast.js'
const fileDownload = require('js-file-download')
export default {
    name: 'OrganisationDailyReport',
    components: {
    },
    props: {
        dailyReport: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            
        }
    },
    mounted(){
    },
    updated(){
        console.log('entry updated')
        console.log(this.dailyReport)
        console.log('exit updated')
    },
    unmounted(){
        console.log('component unmounted')
        
    },
    methods: {
        refreshData(){
            this.getDailyReport()
        },
        getDateFormatted(date_string){
            let date = new Date(date_string)
            return date.toLocaleDateString()
        },
        
        generatePdf(htmlRef){
            console.log('entry generatePDF: ' +htmlRef)

            var formData = this.getFormData()
            formData.type_data = 'pdf'
            axios
                .post(`/api/v1/organisations/${this.organisationDailyReport.organisation}/${this.organisationDailyReport.id}/get_daily_report/`, formData, {responseType: 'blob'})
                .then(response => {
                    console.log('fertig')
                    fileDownload(response.data, `Tagesbericht.pdf`);
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        generateExcel(htmlRef){
            console.log('entry generateExcel: ' +htmlRef)

            var formData = this.getIntervall()
            
            formData.type_data = 'xlsx'
            axios
                .post(`/api/v1/plants/${this.plant.id}/get_plant_journal/`, formData, {responseType: 'blob'})
                .then(response => {
                    console.log('fertig')
                    fileDownload(response.data, `_auswertung.xlsx`);
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit generateExcel')
        },
        
    },
    computed:{
        dailyReportLoaded(){
            if(this.dailyReport.hasOwnProperty('page_headers')){
                return true
            }
            return false
        }
    }
}
</script>
