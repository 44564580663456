<template>
    <div class="row">
        <h5 v-if="lastValue">{{lastValue}} kW</h5>
        <apexchart type="line" :height="height"  :options="chartOptions1" :series="valuesForSeries()"></apexchart>
    </div>
    <div class="row">
        <div class="col-3 text-start">{{firstValueDate}}</div>
        <div class="col-5 text-center">{{chartTitle}} <small>[{{chartUnit}}]</small></div>
        <div class="col-4 text-end">{{lastValueDate}}</div>
    </div>
  
  </template>
  
  <script>

  export default {
      name: 'ApexChart',
      components: {
        
      },
      props: {
        chartData: {
            type: Object,
            required: true
        },
        valueField: {
            type: String,
            required: false,
            default: 'value'
        },
        chartUnit: {
            type: String,
            required: false,
            default: 'kW'
        },
        chartTitle: {
            type: String,
            required: false,
            default: 'Wirkleistung'
        },
        height: {
            type: Number,
            required: false,
            default: 35
        },
        width: {
            type: Number,
            required: false,
            default: 100
        },
      },
      data(){
        return{
            series1: [{
                data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
            }],
            
        }
      },
      methods: {
        valuesForSeries() {
            if(!this.chartData)
                return [{data:[]}]
            const data = this.chartData.map(item => item[this.valueField] + " " + this.chartUnit);
            console.log(data)
            return [{ data }]
        },
        generateColors() {
            console.log('entry generateColors')
            if(!this.chartData)
                return null
            var data = this.chartData.map(item => item[this.valueField])

            return data.map((d, idx) => {
                let color = d > 0 ? '#22c55f': '#ef4544';
                console.log('color:')
                console.log(color)
                return {
                    offset: idx / (data.length - 1) * 100,
                    color,
                    opacity: 1
                }
            })
            
        }
      },
      computed:{
        xAxisForSeries() {
            if (!this.chartData) return [];
            return this.chartData.map(item => {
                let updatedTime = new Date(item.created_at)
                    const options = {
                    day: "2-digit",
                    month: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                };
                return updatedTime.toLocaleDateString("de-DE", options).replace(",", "");
            });
        },
        chartOptions1() {
            return{
                //colors: ['#73ff2f'],
                stroke: {
                    width: 2
                },
                xaxis: {
                    categories: this.xAxisForSeries
                },
                
                chart: {
                    type: 'line',
                    //width: this.width,
                    height: this.height,
                    sparkline: {
                        enabled: true
                    },
                    toolbar: {
                        show: false
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        colorStops: this.generateColors()
                    }
                }, 

                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: true
                    },
                    y: {
                        title: {
                            formatter: (seriesName) => this.chartUnit,
                        },
                    },
                    marker: {
                        show: false
                    }
                }
            }
      },
        lastValue(){
            if(this.chartData.length > 0)
                return this.chartData[this.chartData.length -1].value
            return ''
        },
        firstValueDate(){
            if(this.chartData.length > 0)
                { 
                    let updatedTime = new Date(this.chartData[0].created_at)
                        const options = {
                        day: "2-digit",
                        month: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                    };
                    return updatedTime.toLocaleDateString("de-DE", options).replace(",", "");
                }
            return ''
                
        },
        lastValueDate(){
            if(this.chartData.length > 0)
                { 
                    let updatedTime = new Date(this.chartData[this.chartData.length -1].created_at)
                        const options = {
                        day: "2-digit",
                        month: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                    };
                    return updatedTime.toLocaleDateString("de-DE", options).replace(",", "");
                }
            return ''
        },
      }
  }
  </script>
  