<template>
    <div class="mx-2">
        <select class="form-select " @change="changeOrganisation()" aria-label="Default select example" v-model="this.selectedOrganisation">
            <option  v-for="organisation in this.sortedOrganisations" 
                v-bind:key="organisation.name" v-bind:value="organisation.id">{{organisation.name}}</option>
        </select>
        
    </div>


</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'ChooseOrganisation',

    data() {
        return {
            allOrganisations: [],
            selectedOrganisation: ""
        }
    },
    async mounted(){
        this.getAllOrganisations()
        await this.getCurrentOrganisation()
    },
    updated(){
        
    },
    methods: {
        async getCurrentOrganisation(){
            console.log('entry getCurrentOrganisation')
            await axios
                .get("/api/v1/users/me/")
                .then(response => {
                    
                    this.selectedOrganisation = response.data.organisation
                })
            console.log('exit getCurrentOrganisation')
        },
        changeOrganisation(){
            console.log('entry changeOrgansiation')
            console.log(this.selectedOrganisation)
            let user = this.$store.state.me
            let formData = {'new_organisation': this.selectedOrganisation}
            axios
                .post(`/api/v1/account/${user.id}/update_organisation/`, formData)
                .then(response => {
                   window.location.reload()
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit ChangeOrganisation')
        },
        getAllOrganisations(){
            console.log('entry getAllOrganisations')
            axios
                .get('/api/v1/allOrganisations/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        this.allOrganisations.push(response.data[i])
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getAllOrganisations')
        }
    },
    computed:{
        userOrganisation(){
            return this.$store.getters.getOrganisation.id
        },
        sortedOrganisations(){
            var sortedOrganisations = this.allOrganisations.sort((a,b) => {
                
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                return 0;
            })
            return sortedOrganisations
        }
    }
}
</script>