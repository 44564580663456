<template>
  <template v-if="$store.state.isAuthenticated"> 
      <Topbar/>
      <!-- Demo Mode Notification -->
      <div v-if="isDemoMode" class="demo-notification">
        <div class="container py-2">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <i class="fa-light fa-circle-info"></i>
              <span> Sie befinden sich im Demo-Modus. Dieser Zugang ist gültig für {{ getDemoRemainingDays }} weitere Tage. Bitte kontaktieren Sie uns, wenn Sie Ihren Zugang weiter nutzen möchten.</span>
            </div>
            <a v-if="['root', 'Orga-Manager', 'Admin'].includes($store.state.me.grp_name_bez)" href="mailto:feedback@real-time-plants.de" class="btn btn-sm btn-outline-light">Vollversion aktivieren</a>
          </div>
        </div>
      </div>
    </template>
  <metainfo>
  </metainfo>
  
  <div id="wrapper" class="h-100 d-flex flex-column ">
    
    <div class="container-fluid">
      <div class="row">
        <main class="">
          <router-view/>
          <div id="toast-container"></div>
        </main>
      </div>
    </div>
    <Footer/>
  </div>

  <button id="modalOpenAlert" style="display:none;" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#alert" aria-hidden="true">hidden</button>
  
  <div class="modal fade" id="alert" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable ">
        <div class="modal-content ">
            <div class="modal-header">
                <h5 class="modal-title" id="modalLabel">{{$t("alert_header")}}</h5>
                
                <button type="button" id="modalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body modal-textarea">
              {{this.$store.state.me.user_alert_text}}
            </div>
            
        </div>
      </div>
    </div>
</template>

<script>
  import { createToast } from '@/assets/js/myToast.js'
  import axios from 'axios'
  import apiClient from "@/services/apiClient";
  // @ is an alias to /src
  import Sidebar from '@/components/Sidebar.vue'
  import Topbar from '@/components/Topbar.vue'
  import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  components: {
    Sidebar,
    Topbar,
    Footer
  },
    beforeCreate() {
      console.log("init beforeCreate")
      this.$store.commit('initializeStore')

      const token = this.$store.state.token
      console.log(window.location.pathname)
      if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
        let actual_path = window.location.pathname
        if(actual_path === '/'){
          this.$router.push('/dashboard')
        }
      } else {
        axios.defaults.headers.common['Authorization'] = ""
      }
    }, 
    async mounted(){
      
      if(this.$store.state.isAuthenticated){
        await this.getAuth()
        await this.getMe()
      }else{
          console.log("User ist nicht angemeldet")
      }
      
    },
    methods:{
      checkAlertPopup(response_data){
        console.log('entry checkAlertPopup')
        console.log(response_data.response_data)
        if(response_data.user_alert_text){
          document.getElementById('modalOpenAlert').click();
        }
        console.log('exit checkAlertPopup')
      },
      async getPlants(){
        try {
          const response_dashboard =  await apiClient.get("/api/v1/dashboard/")
          console.log('plants loaded')
          console.log(response_dashboard.data)
          this.$store.commit('setPlants', response_dashboard.data)
        }catch (error) {
            console.info("Fehler beim Laden von dashboard");

            console.log(JSON.stringify(error))
        } 
      },
      async getOrganisation(){
        console.log('entry getOrganisation')
        const organisationID = this.$store.state.me.organisation
        
        try {
          const response_orga =  await apiClient.get(`/api/v1/organisations/${organisationID}/`)
          console.log(response_orga)
          this.$store.commit('setOrganisation', response_orga.data)
          console.log('Organisation gesetzt')
        }catch (error) {
            console.info("Fehler beim Laden von dashboard");

            console.log(JSON.stringify(error))
        }         

        console.log('exit getOrganisation')
      },
      async getAuth(){
        try {
          const response_authgroup =  await apiClient.get("/api/v1/authgroup/")
          this.$store.commit('setPermissions', response_authgroup.data[0])
          console.log('getAuth fertig geladen')
        }catch (error) {
            console.info("Fehler beim Laden von dashboard");

            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("username")
            localStorage.removeItem("userid")
            localStorage.removeItem("token")
            localStorage.removeItem("mustSetupCompany")
            this.$store.commit('removeToken')
            localStorage.removeItem("permissions")
            this.$router.push('/')
        }  
      },
      async getMe(){
        try {
          const response_me =  await apiClient.get("/api/v1/users/me/")
          this.$store.commit('setMe', response_me.data)
          this.getOrganisation()
          this.getPlants()
          this.checkAlertPopup(response_me.data)
        }catch (error) {
            console.info("Fehler beim Laden von me");

            console.log(JSON.stringify(error))
        } 
      }
    },
    computed:{
      isDemoMode(){
            if(this.$store.getters.getOrganisation){
                return this.$store.state.organisation.status_license == 10
            }
            return false
        },
        getDemoRemainingDays(){
          if(this.$store.getters.getOrganisation){
              if(this.$store.state.organisation.status_license == 10){
                const demoValid = new Date(this.$store.state.organisation.license_valid_until)
                const now = new Date()
                const diffTime = demoValid - now;
                return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
              }

          }
          return 0
        }
    }
}
</script>

<style lang="scss">
@import '/scss/custom.scss';
@import './assets/fontawesome/css/all.css';
</style>
