<template>
    <div class="page-config-live-data">
        <div class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
            <NavPlantTypesSidebar v-if="allPlantTypes" v-bind="currentPlantTypesProperties" 
            v-on:setType="setType" v-on:setEvaluation="setEvaluation"></NavPlantTypesSidebar>
        </div>
        <div class="col-md-9 ms-sm-auto col-lg-10">
            <template v-if="this.currentEvaluation">
                <component :is="currentTabComponent" v-bind="currentProperties"></component>
            </template>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import PlantEvaluations from '@/components/analyse/PlantEvaluations.vue'
import NavPlantTypesSidebar from '@/components/navs/NavPlantTypesSidebar.vue'
export default {
    name: 'PlantEvaluationsForPlants',
    components: {
        PlantEvaluations,
        NavPlantTypesSidebar
    },
    data() {
        return {
            currentType: '',
            currentEvaluation: '',
            currentTabComponent: 'PlantEvaluations',
            allPlantTypes: "",
        }
    },
    mounted(){
        this.getPlantTypes()
    },
    methods: {
        setEvaluation(type, evaluation){
            console.log('entry setEvaluation')
            this.currentEvaluation = evaluation
            this.currentType = type
            console.log('exit setEvaluation')
        },
        setType(type){
            console.log('entry setType')
            console.log(type)
            this.currentType = type
            this.currentEvaluation = ''
            console.log('exit setType')
        },  
        getPlantTypes(){
            console.log('entry getPlantTypes')
             axios
                .get('/api/v1/planttypes/')
                .then(response => {
                    this.allPlantTypes = response.data
                    console.log('planttypes fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantTypes')
        },   
    },
    computed:{
        currentProperties(){
                return {plantTypeEvaluation: this.currentEvaluation,
                    plantType: this.currentType,
                    }
            },
        currentPlantTypesProperties(){
                return {plantTypes: this.allPlantTypes,
                    editPlantType: false,
                    showTypEvaluation: true}
            },
    }
}
</script>
