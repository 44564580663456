<template>
    <div class="page-add-user">
        <div class="container-fluid my-3 py-3">
            <div class="row my-3">
                <div class="col-4">
                    <div class="form-floating">
                        <input :disabled="!$store.state.me.is_superuser" id="inputName" type="text" name="name" class="form-control"  v-model="plant.name" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                    </div>
                </div>

                <div class="col-4">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="plant.installed_capacity" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("installed_capacity") }}</label>
                    </div>
                </div>
                <div class="col-4" v-if="$store.state.me.is_superuser">
                    <div class="form-floating">
                        <input id="price_per_month" type="number" name="name" class="form-control"  v-model="plant.price_per_month" placeholder="name@example.com">
                        <label for="price_per_month" class="form-label" >{{ $t("price_per_month") }}</label>
                    </div>
                </div>
            </div>
            <div class="row my-3" v-if="plant.type=='Windkraft'">
                <div class="col-4">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="plant.identificator_1" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("park_num") }}</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="plant.identificator_2" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("turb_num") }}</label>
                    </div>
                </div>
            </div>
            <div class="row my-3" v-if="$store.state.me.is_superuser">
                <div class="col-4">
                    <select class="form-select " aria-label="Default select example" v-model="plant.dashboard">
                        <option  v-for="dashboard in this.plantDashboards" 
                            v-bind:key="dashboard.name" v-bind:value="dashboard.id">{{dashboard.name}}</option>
                    </select>
                </div>
                <div class="col-4" v-if="$store.state.me.is_superuser">
                    <select :disabled="!$store.state.me.is_superuser" class="form-select " aria-label="Default select example" v-model="plant.system">
                        <option  v-for="system in this.plantSystems" 
                            v-bind:key="system.name" v-bind:value="system.id">{{system.name}}</option>
                    </select>
                </div>

                <div class="col-4" v-if="$store.state.me.is_superuser">
                    <select class="form-select " aria-label="Default select example" v-model="plant.type">
                        <option  v-for="types in this.plantTypes" 
                            v-bind:key="types.name" v-bind:value="types.name">{{types.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputLatitude" type="number" name="Latitude" class="form-control"  v-model="plant.latitude" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputLatitude" class="form-label" >{{ $t("latitude") }}</label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputLongitude" type="number" name="Longitude" class="form-control"  v-model="plant.longitude" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputLongitude" class="form-label" >{{ $t("longitude") }}</label>
                    </div>
                </div>
            </div>
            <div class="row my-3" v-if="$store.state.me.is_superuser">
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputImportfolder" type="text" name="ImportFolder" class="form-control"  v-model="plant.folder_name_import" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputImportfolder" class="form-label" >{{ $t("importfolder") }}</label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputImportfolder" type="text" name="ImportFolder" class="form-control"  v-model="plant.file_prefix_yaml" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputImportfolder" class="form-label" >{{ $t("file_prefix_yaml") }}</label>
                    </div>
                </div>
            </div>
            <div class="row my-3" v-if="$store.state.me.is_superuser">
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputImportfolder" type="text" name="ImportFolder" class="form-control"  v-model="plant.folder_alarm_import" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputImportfolder" class="form-label" >{{ $t("folder_alarm_import") }}</label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputImportfolder" type="text" name="ImportFolder" class="form-control"  v-model="plant.file_prefix_alarm" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputImportfolder" class="form-label" >{{ $t("file_prefix_alarm") }}</label>
                    </div>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <template v-if="this.plantsCopy.length > 0">
                        <label for="copyPlant" class="form-label">{{ $t("new_from_copy") }}</label>
                        <model-select id="floatingSelect"
                            :options="getAllPlantOptions"
                             v-model="plantCopyId"
                        >
                        </model-select>
                    </template>
                    <template v-else>
                        {{ $t("loading_plants") }} 
                    </template>
                </div>
            </div>
            <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
import { ModelSelect } from "vue-search-select"
export default {
    name: 'AddPlant',
    emits: ['closeModal'],
    props: {
        organisation: {
            type: Object,
            required: true,
        },
        plants: {
            type: Object,
            required: true,
        },
    },
    components: {
        ModelSelect,
    },
    data() {
        return {
            plant: {
                type: 'Biogas'
            },
            plantTypes: {},
            plantSystems: [],
            plantDashboards: [],
            plantCopyId: '',
            plantsCopy: []
        }
    },
    mounted(){
        this.getPlantTypes()
        this.getPlantSystems()
        this.getPlantDashboards()
        this.getAllPlants()
    },
    methods: {
        getAllPlants(){
            console.log('entry getAllPlants')
             axios
                .get('/api/v1/plants/all')
                .then(response => {
                    this.plantsCopy = response.data
                    console.log('plants fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getAllPlants')
        },
        getPlantSystems(){
            console.log('entry getPlantSystems')
             axios
                .get('/api/v1/plantsystems/')
                .then(response => {
                    this.plantSystems = response.data
                    console.log('plantsystems fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantSystems')
        },
        getPlantTypes(){
            console.log('entry getPlantTypes')
             axios
                .get('/api/v1/planttypes/')
                .then(response => {
                    this.plantTypes = response.data
                    console.log('planttypes fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantTypes')
        },
        getPlantDashboards(){
            console.log('entry getPlantDashboards')
             axios
                .get('/api/v1/plantdashboards/')
                .then(response => {
                    this.plantDashboards = response.data
                    console.log('plantdashboards fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantDashboards')
        },
        submitForm() {

            this.plant.organisation = this.organisation.id
            this.plant.created_by = this.$store.state.me.id
            this.plant.modified_by = this.$store.state.me.id
            this.plant.users = {}
            console.log('plantCopyId:')
            console.log(this.plantCopyId)
            if(this.plantCopyId){
                let plantCopy = this.getCopyPlant
                console.log('plantCopy:')
                console.log(plantCopy[0])
                this.plant.plant_components = plantCopy[0].plant_components

            }
            console.log('plant:')
            console.log(this.plant)
            axios
                .post(`/api/v1/plants/`, this.plant)
                .then(response => {
                    
                    createToast({text: this.$t("plant added")
                   ,type: 'success'})

                   this.$emit('closeModal', 'add', response.data)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    },
    computed:{
        getAllPlantOptions(){
            console.log('entry getAllPlantOptions')

            var allPlantsRaw = this.plantsCopy
            let allPlants = []
            allPlantsRaw.forEach(plant => {
                allPlants.push({value: plant.id,
                                          text: plant.name})
                                          
            })
           
            console.log('allPlants')
            console.log(allPlants)
            return allPlants
        },
        getCopyPlant(){
            console.log('entry getCopyPlant')
            return this.plantsCopy.filter(plant => plant.id === this.plantCopyId)
        }
    }
}
</script>