<template>
    <div class="row mx-2 my-2">
        <div class="card shadow-sm">
            <div class="card-header d-flex align-items-center">
                <p class="text-center h5">{{ $t("user_group_user_mapping") }}</p>
                            
            </div>
            <div class="card-body">
                
                <table class="table table-striped table-hover table-sm">
                    <tbody>
                        <tr
                            v-for="user in allOrganisationReportUsers"
                            v-bind:key="user.id"
                        >
                            <td><input class="form-check-input" :disabled="!this.canWriteUserGroup" type="checkbox" :value="user.id" :id="user.id" v-model="taggedUsers"></td>
                            <td>{{ user.description}}</td>
                            <td>{{ user.first_name }} {{ user.last_name }}</td>
                            <td>{{ user.email}}</td>
                            <td>{{ user.phone_number}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'EditUserGroupUserMapping',
    props: {
        userGroup: {
            type: Object,
            required: true,
        },
        canWriteUserGroup: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            allOrganisationReportUsers: [],
            taggedUsers: [],
            userGroupOldId: 0
        }
    },
    async mounted(){
        await this.manipulateTaggedUsers()
       await this.getAllReportUsersForCompanie()
    },
    updated(){
        console.log('entry updated')
        if(this.userGroupOldId === 0){
            this.userGroupOldId = this.userGroup.id
        }
        if(this.userGroupOldId != this.userGroup.id){
            this.manipulateTaggedUsers()
            this.userGroupOldId = this.userGroup.id
        }
        console.log('exit updated')
    },
    methods: {
        getTooltip(user){
            return "<b>" + this.$t("phone_number") +"</b> " + user.phone_number + "<br> <b>" + this.$t("email") + "</b> " + user.email
        },
        manipulateTaggedUsers(){
            console.log('entry manipulateTaggedUsers')
            
            this.taggedUsers = []
            this.userGroup.group_users.forEach(user=>{
                this.taggedUsers.push(user.id)
            })
            console.log('exit manipulateTaggedUsers')
        },
        getAllReportUsersForCompanie(){
            console.log('entry getAllReportUsersForCompanie')
            axios
                .get('/api/v1/report_users/')
                .then(response => {
                    this.allOrganisationReportUsers = response.data
                    console.log('users fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getAllReportUsersForCompanie')
        },
        submitForm() {
            this.userGroup.group_users = []
            this.taggedUsers.forEach(userId=>{
                console.log(userId)
                var user = this.allOrganisationReportUsers.filter(orga_user=> orga_user.id === userId)[0]
                var userArray = this.userGroup.group_users.filter(group_user=> group_user.id === userId)[0]
                if(!userArray){
                    console.log('new User for Array')
                    this.userGroup.group_users.push(user)
                }
            })
            
            console.log('entry submitForm')
            axios
                .post(`/api/v1/usergroup/${this.userGroup.id}/update_user_group_users/`, this.taggedUsers)
                .then(response => {
                    createToast({text: this.$t("user_mapping altered")
                    ,type: 'success'})
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit submitForm')
        }
    },
    computed:{
        
    }
}
</script>