<template>
    <div class="container-fluid my-3 py-3">
        <div class="row my-3" v-if="loadedPlant">
            <div class="col-12">
                <label >{{ $t("sernum_old") }}</label>
                <model-select id="floatingSelect"
                    :options="getAllPlantComponents"
                        v-model="esn_old"
                >
                </model-select>
            </div>
                
        </div>

        <div class="row my-3" v-if="loadedPlant">
            <div class="col-12">
                <div class="form-floating">
                    <input id="inputSernumNew" ref="name" type="text" name="name" class="form-control" maxlength="200" v-model="esn_new" placeholder="name@example.com">
                    <label for="inputSernumNew" class="form-label" >{{ $t("sernum_new") }}</label>
                </div>
            </div>
                
        </div>

        <div class="row my-3">
            <div class="col-4">
                <label for="inputCheckDigit" class="form-label" >{{ $t("CheckDigit") }}: {{ check_digit }}</label>
            </div>
            <div class="col-8">
                <div class="form-floating">
                    <input id="inputCheckDigit" ref="name" type="text" name="name" class="form-control" maxlength="200" v-model="check_digit_user" placeholder="name@example.com">
                    <label for="inputCheckDigit" class="form-label" >{{ $t("inputCheckDigit") }}</label>
                </div>
            </div>
        </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm" :disabled="check_digit != check_digit_user">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
import { ModelSelect } from "vue-search-select"
export default {
    name: 'ManualChangeESNForPlant',
    emits: ['closeManualChangeESNForPlant'],
    props: {
        plant: {
            type: Object,
            required: true,
        }
    },
    components: {
        ModelSelect,
    },
    watch: {
        plant: {
            deep: true,
            handler() {
                console.log('watch fired')
                this.loadCompletePlant(this.plant)
                this.generateCheckDigit()
            }
        }
    },
    data() {
        return {
            check_digit: 0,
            check_digit_user: null,
            loadedPlant: null,
            esn_old: null,
            esn_new: null
        }
    },
    mounted(){
        this.loadCompletePlant(this.plant)
        this.generateCheckDigit()
    },
    updated(){
        
    },
    methods: {
        loadCompletePlant(plant){
            console.log('entry loadCompletePlant')
            axios
                .get(`/api/v1/plants/${plant.id}/`)
                .then(response => {
                    console.log('plant loaded')
                    console.log(response.data)
                    this.loadedPlant = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit loadCompletePlant')
        },
        generateCheckDigit(){
            this.check_digit = Math.floor(Math.random() * 9000) + 1000 
        },
        getTimeFormatted(time){
            if(time){
                let updatedTime = new Date(time)
                return updatedTime.toLocaleString()

            }
            return ''
        },
        submitForm() {
            let formData = {
                esn_old: this.esn_old,
                esn_new: this.esn_new
            }
            axios
                .post(`/api/v1/plants/${this.plant.id}/change_esn_for_plant_backend/`, formData)
                .then(response => {
                    this.$emit('closeManualChangeESNForPlant')
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
                
                
        }
    },
    computed:{
        getAllPlantComponents(){
            console.log('entry getAllPlantSernums')

            let allPlantSernums = []
            this.loadedPlant.plant_components.forEach(component => {
                console.log('im loop')
                console.log(component)
                allPlantSernums.push({value: component.sernum,
                                          text: component.name  + " - " + component.sernum})
                                          
            })
           
            console.log('allPlantDescriptions')
            console.log(allPlantSernums)
            return allPlantSernums
        }
    }
}
</script>