<template>
    <div class="row">
        <button class="btn btn-success mb-0 ms-auto" @click="clear_cache_backend" >{{ $t("clear_cache_backend") }}</button>
    </div>
    <div class="row">
        <button class="btn btn-success mb-0 ms-auto" @click="monitor_directories" >{{ $t("monitor_directories") }}</button>
    </div>
    <div class="row">
        <button class="btn btn-success mb-0 ms-auto" @click="get_kago_data" >{{ $t("get_kago_data") }}</button>
    </div>
    <div class="row">
        <button class="btn btn-success mb-0 ms-auto" @click="send_old_data" >{{ $t("send_old_data") }}</button>
    </div>
    <div class="row">
        <button class="btn btn-success mb-0 ms-auto" @click="count_files" >{{ $t("count_files") }}</button>
    </div>
    <div class="row">
        <button class="btn btn-success mb-0 ms-auto" @click="write_daily_report_all" >{{ $t("write_daily_report_all") }}</button>
    </div>
    <div class="row">
        <button class="btn btn-success mb-0 ms-auto" @click="write_daily_report_past_all" >{{ $t("write_daily_report_past_all") }}</button>
    </div>
    <div class="row">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="sendDailyReport" v-model="sendDailyReport">
            <label class="form-check-label" for="sendDailyReport" >
                {{$t("sendDailyReport")}}
            </label>
        </div>
        <button class="btn btn-success mb-0 ms-auto" @click="send_daily_report_all" :disabled="!sendDailyReport">{{ $t("send_daily_report_all") }}</button>
    </div>


</template>

<script>
import axios from 'axios'

export default {
    name: 'TriggerBackendTasks',
    components: {
    },
    data() {
        return {
            sendDailyReport: false
        }
    },
    mounted(){
        
    },
    updated(){

    },
    unmounted(){
        console.log('component unmounted')
        
    },
    methods: {
        write_daily_report_past_all(){
            console.log('entry write_daily_report_past_all')

            axios
                .post(`/api/v1/tasks/write_daily_report_past_all/`)
                .then(response => {
                    console.log('fertig')
                    
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit write_daily_report_all')
        },
        get_kago_data(){
            console.log('entry get_kago_data')

            axios
                .post(`/api/v1/tasks/get_kago_data/`)
                .then(response => {
                    console.log('fertig')
                    
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit get_kago_data')
        },
        send_old_data(){
            console.log('entry send_old_data')

            axios
                .post(`/api/v1/tasks/send_old_data/`)
                .then(response => {
                    console.log('fertig')
                    
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit send_old_data')
        },
        count_files(){
            console.log('entry count_files')

            axios
                .post(`/api/v1/tasks/count_files_view/`)
                .then(response => {
                    console.log('fertig')
                    
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit count_files')
        },
        monitor_directories(){
            console.log('entry monitor_directories')

            axios
                .post(`/api/v1/tasks/monitor_directories/`)
                .then(response => {
                    console.log('fertig')
                    
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit write_daily_report_all')
        },
        write_daily_report_all(){
            console.log('entry write_daily_report_all')

            axios
                .post(`/api/v1/tasks/write_daily_report_all/`)
                .then(response => {
                    console.log('fertig')
                    
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit write_daily_report_all')
        },
        send_daily_report_all(){
            console.log('entry send_daily_report_all')

            axios
                .post(`/api/v1/tasks/send_daily_report_all/`)
                .then(response => {
                    console.log('fertig')
                    
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit send_daily_report_all')
        },
        clear_cache_backend(){
            console.log('entry clear_cache_backend')

            axios
                .post(`/api/v1/tasks/clear_cache_backend/`)
                .then(response => {
                    console.log('fertig')
                    
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit clear_cache_backend')
        },
    },
    computed:{
     
    }
}
</script>
