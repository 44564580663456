<template>
    <div class="container-fluid ">
        <div class="row">
            <div class="col-12">
                <div class="form-floating">
                    <input id="inputName" type="text" name="name" class="form-control"  v-model="grp_name_delete" @keyup.enter="submitForm" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("grp_name_delete") }}</label>
                </div>
            </div>
        </div>
        <div class="text-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <button class="btn btn-danger mt-2 mb-0 ms-auto" @click="submitForm">{{ $t("delete") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'DeleteUserGroup',
    emits: ['closeModal'],
    props: {
        userGroup: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            grp_name_delete:'',
            errors: []
        }
    },

    methods: {
        submitForm() {
            console.log('entry submitForm')
            this.errors = []

            console.log(this.userGroup.grp_name)
            console.log(this.grp_name_delete)
            
            if(this.userGroup.grp_name != this.grp_name_delete){
                this.errors.push(this.$t("userGroup wrong"))
                return
            }
            axios
                .delete(`/api/v1/usergroup/${this.userGroup.id}`, this.userGroup)
                .then(response => {
                    
                    createToast({text: this.$t("userGroup deleted")
                   ,type: 'success'})
                    this.grp_name_delete = ''
                   this.$emit('closeModal', 'delete', response.data)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>