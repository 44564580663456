<template>
    <div class="row mt-3">
       <div class="col-4">
           <ul class="list-group ">
               <li :class="{'active': this.activeColumn === column}" class="list-group-item" v-for="column in dashboard.plant_dashboard_row_columns"
               v-bind:key="column.id" >
                   <div class="row">
                       <div class="col-8 cursor-pointer" @click="setActiveColumn(column)">
                           {{column.name}} | {{column.position}}
                       </div>
                       <div class="col-1">
                           <i class="fa-solid fa-arrow-up" @click="moveColumnUp(column)"></i>
                       </div>
                       <div class="col-1">
                           <i class="fa-solid fa-arrow-down" @click="moveColumnDown(column)"></i>
                       </div>
                       <div class="col-1">
                           <i class="fa-solid fa-pencil" @click="editColumn(column)" data-bs-toggle="modal" data-bs-target="#modalColumn"></i>
                       </div>
                       <div class="col-1">
                           <i class="fa-solid fa-trash" @click="deleteColumn(column)"></i>
                       </div>
                   </div>
                       
               </li>
               <li class="list-group-item cursor-pointer" @click="setNewColumn()"
                           data-bs-toggle="modal" data-bs-target="#modalColumn">
                   <i class="fa-solid fa-plus" ></i>
               </li>
           </ul>
       </div>
       
   </div>
   <div class="modal fade" id="modalColumn" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
       <div class="modal-dialog modal-dialog-scrollable modal-lg">
           <div class="modal-content">
               <div class="modal-header">
                   <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                   <button type="button" id="modalHeaderClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body modal-textarea">
                   <component v-if="this.currentComponent" :is="currentComponent" v-bind="currentPropertiesHeaders" v-on:closeModal="closeModal"></component>
               </div>
               <div class="modal-footer">
                   <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
               </div>
           </div>
       </div>
   </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
import AddPlantDashboardRowColumn from '@/components/dashboard/AddPlantDashboardRowColumn.vue'
import EditPlantDashboardRowColumn from '@/components/dashboard/EditPlantDashboardRowColumn.vue'
export default {
   name: 'EditDashboardForOrganisation',
   emits: [],
   components: {
       AddPlantDashboardRowColumn,
       EditPlantDashboardRowColumn
       },
   props: {
       dashboard: {
           type: Object,
           required: true,
       },
       
   },
   data() {
       return {
           activeColumn: null,
           currentComponent: "",
       }
   },
   mounted(){
   },
   updated(){
       console.log('entry updated')
       
       console.log('exit updated')
   },
   methods: {
       
       closeModal(action, element){
           console.log("closeModal")
           document.getElementById('modalHeaderClose').click();
           if(action === 'add'){
               this.dashboard.plant_dashboard_row_columns.push(element)
           }
       },
       sortColumnArray(){
           console.log('entry sortColumnArray')
           this.dashboard.plant_dashboard_row_columns = this.dashboard.plant_dashboard_row_columns.sort((a,b) => {
               if(a.position < b.position){
                   return -1
               }
               if (a.position > b.position){
                   return 1
               }
               return 0
           })
           this.updateColumnsBackend()
           console.log('exit sortColumnArray')
       },
       setNewColumn(){
           console.log('entry setNewColumn')
           this.currentComponent = "AddPlantDashboardRowColumn",
           console.log('exit setNewColumn')
       },
       editColumn(column){
           console.log('entry editColumn')
           this.currentComponent = 'EditPlantDashboardRowColumn'
           this.activeColumn = column
           console.log('exit editHeader')
       },
       deleteColumn(column){
           console.log('entry deleteColumn')
           axios
               .delete(`/api/v1/plantdashboards_headers/${column.id}/`)
               .then(response => {
                   createToast({text: this.$t("header deleted")
                   ,type: 'success'})
                   this.deleteColumnFromFrontend(column)
               })
               .catch(error => {
                   console.log(JSON.stringify(error))
                   createToast({text: error.message
                   ,type: 'error'})
               })
           this.activeColumn = ''
           console.log('exit deleteHeader')
       },
       deleteColumnFromFrontend(column){
           console.log('entry deleteColumnFromFrontend')
           let position = 1
           let pos_delete = this.dashboard.plant_dashboard_row_columns.map(e => e.id).indexOf(column.id)
           console.log('pos_delete: ' + pos_delete)
           this.dashboard.plant_dashboard_row_columns.splice(pos_delete, 1)
           this.dashboard.plant_dashboard_row_columns.forEach(element => {
               element.position = position
               position++
               axios
                   .patch(`/api/v1/plantdashboards_row_columns/${element.id}/`, element)
                   .then(response => {
                       console.log('response')
                       console.log(response.data)
                   })
                   .catch(error => {
                       console.log(JSON.stringify(error))
                       createToast({text: error.data
                       ,type: 'error'})
                   })
           })
           console.log('exit deleteColumnFromFrontend')
       },
       moveColumnUp(column){
           console.log('entry moveColumnUp')
           if(column.position < 2){
               return
           }
           let column_to_switch = this.dashboard.plant_dashboard_row_columns.filter(array_column => array_column.position === column.position -1)

           column_to_switch.forEach((value,index) =>{
               value.position = column.position
           })
           column.position--
           this.sortColumnArray()
           console.log('exit moveColumnUp')
       },
       moveColumnDown(column){
           console.log('entry moveColumnDown')
           if(column.position >= this.dashboard.plant_dashboard_row_columns.length){
               return
           }
           let column_to_switch = this.dashboard.plant_dashboard_row_columns.filter(array_column => array_column.position === column.position +1 )

           column_to_switch.forEach((value,index) =>{
               value.position = column.position
           })
           column.position++
           this.sortColumnArray()
           console.log('exit moveColumnDown')
       },
       setActiveColumn(column){
           console.log('entry setActiveColumn')
           this.activeColumn = column
           console.log('exit setActiveColumn')
       },
       updateColumnsBackend(){
           console.log('entry updateColumnsBackend')
           this.dashboard.plant_dashboard_row_columns.forEach(element => {
               axios
                   .patch(`/api/v1/plantdashboards_row_columns/${element.id}/`, element)
                   .then(response => {
                       console.log(response)
                   })
                   .catch(error => {
                       console.log(JSON.stringify(error))
                       createToast({text: error.message
                       ,type: 'error'})
                   })
               })
           console.log('exit updateColumnsBackend')
       }
       
   },
   computed:{
       currentProperties(){
               return {plantDashboardHeaderId: this.activeColumn.id,
                   organisation: this.$store.state.organisation, }
           },
       currentPropertiesHeaders(){
           
           return {dashboard: this.dashboard, 
                   position: 1,
                   plantDashboardRowColumn: this.activeColumn
               }
       },
   }
}
</script>