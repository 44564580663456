<template>
    <div class="container-fluid my-3 py-3">
        
        <div class="row my-3">
            <div class="col-6">
                <div class="form-floating">
                    <select class="form-select " id="floatingSelect" aria-label="Default select example" v-model="plantSystemSource">
                        <option value="dummy">{{ $t("please_select") }}</option>
                        <option  v-for="system in this.$store.getters.getPlantSystems" 
                            v-bind:key="system.name" v-bind:value="system.id">{{system.name}}</option>
                    </select>
                    <label for="floatingSelect">{{ $t("source_system_copy_alarmcode") }}</label>

                </div>
            </div> 
                  
        </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'CopyAlarmcodeSystem',
    emits: ['closeModal'],
    props: {
        plantSystem: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            plantSystemSource: 'dummy'
        }
    },
   
    methods: {
        
        submitForm() {

            let submitObject = {'system_id_new': this.plantSystem.id}
            
            axios
                .post(`/api/v1/plantsystems/${this.plantSystemSource}/copy_alarmcodes/`, submitObject)
                .then(response => {
                    
                    createToast({text: this.$t("alarmcodes copy")
                   ,type: 'success'})

                   this.$emit('closeModal', 'create', response.data)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    },
    computed:{
    }
}
</script>