<template>
    <div class="login-page d-flex align-items-center bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 col-lg-5 col-xl-4">
          <div class="card border-0 shadow-sm">
            <div class="card-body p-4">
              <!-- Header -->
              <div class="text-center mb-4">
                <i class="bi bi-speedometer2 text-primary display-4"></i>
                <h1 class="h4 mt-3">Real-Time-Plants</h1>
                <p class="text-secondary">Melden Sie sich an, um fortzufahren</p>
              </div>

              <!-- Error Message -->
              <div v-if="errors.length" class="alert alert-danger" role="alert">
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
              </div>

              <!-- Login Form -->
              <form @submit.prevent="submitForm">
                <div class="mb-3">
                  <label for="email" class="form-label">{{ $t("username") }}</label>
                  <input
                    type="text"
                    ref="login"
                    class="form-control"
                    id="email"
                    v-model="username"
                    placeholder="Benutzername"
                    required
                  />
                </div>

                <div class="mb-3">
                    <label for="inputPassword" class="form-label">{{ $t("Passwort") }}</label>
                    <div class="input-group">
                        <input ref="password" id="inputPassword" :type="passwordFieldType" name="password" class="form-control" v-model="password" aria-describedby="hideUnhide" placeholder="••••••••" required>
                        <span class="input-group-text" id="hideUnhide" @click="switchVisibility">
                            <i class="fa-solid fa-eye" v-if='this.passwordFieldType === "password"'></i>
                            <i class="fa-solid fa-eye-slash" v-if='this.passwordFieldType === "text"'></i>
                        </span>
                    </div>
                    <router-link to="/forgot-password" class="">{{ $t("Passwort vergessen?") }}</router-link>
                </div>

                <button 
                  type="submit" 
                  class="btn btn-primary w-100"
                  :disabled="loading"
                >
                  <span v-if="loading" class="spinner-border spinner-border-sm me-2"></span>
                  Anmelden
                </button>
              </form>

              <!-- Demo Credentials -->
              <div class="mt-4 text-center" v-if="false">
                <p class="text-secondary small mb-0">Demo Zugangsdaten:</p>
                <p class="text-secondary small">
                  E-Mail: demo@example.com<br>
                  Passwort: demo123
                </p>
              </div>
            </div>
          </div>

          <!-- Back to Landing -->
          <div class="text-center mt-4">
            <router-link to="/landingpage" class="btn btn-link text-secondary">{{ $t("Zurück zur Übersicht") }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
    
</template>

<script>
import axios from 'axios'
import apiClient from "@/services/apiClient";
import { useHead } from '@vueuse/head'
export default {
    name: 'LogIn',
    setup () {
      useHead({
        meta: [
          {
            name: `robot`,
            content: 'noindex',
          },
        ],
      })
    },
    data() {
        return {
            username: '',
            password: '',
            passwordFieldType: "password",
            errors: [],
            loading: false,
        }
    },
    mounted(){
        console.log('entry mounted')
        this.focusInput()
        console.log('exit mounted')
    },
    methods: {
        focusInput() {
            this.$refs.login.focus();
        },
        navigateToLanding() {
            router.push({ name: 'landing' });
        },
        async submitForm(e) {
            this.loading = true
            console.log('loading auf true')
            this.errors= []
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("token")

            const formData = {
                username: this.username,
                password: this.password,
            }

            if(this.username == 'demo@example.com' && this.password === 'demo123'){
                localStorage.setItem('isDemoUser', true)
            }else{
                localStorage.setItem('isDemoUser', false)
            }
            let hasError = false
            try {
                const response_login =  await apiClient.post("/api/v1/token/login/", formData)
                
                console.log(response_login)
                console.log(response_login.data)
                const token = response_login.data.auth_token

                this.$store.commit('setToken', token)
                    
                axios.defaults.headers.common["Authorization"] = "Token " + token

                localStorage.setItem("token", token)

            }catch (error) {
                console.info("Fehler beim Login");

                if (error.response) {
                    // Fehler vom Server
                    for (const property in error.response.data) {
                        this.errors.push(`${error.response.data[property]}`);
                    }
                    console.log("Server-Fehlermeldung:", JSON.stringify(error.response.data));
                } else if (error.message) {
                    // Axios-Fehler (z. B. Netzwerkproblem)
                    console.log("Axios-Fehlermeldung:", JSON.stringify(error.message));
                } else {
                    console.log("Unbekannter Fehler:", JSON.stringify(error));
                }
                hasError = true;
            } finally {
                this.loading = false;
            }
            
            if(hasError){
                return
            }
            console.log('no error continue')
            try {
              const response_me =  await apiClient.get("/api/v1/users/me/")
              console.log(response_me)
              this.$store.commit('setUser', {'username': response_me.data.username, 'id': response_me.data.id})

              localStorage.setItem('username', response_me.data.username)
              localStorage.setItem('userid', response_me.data.id)
              this.$store.commit('setMe', response_me.data)
              if(response_me.data.user_alert_text){
                  document.getElementById('modalOpenAlert').click();
              }
            }catch (error) {
                console.info("Fehler beim Laden von me");

                console.log(JSON.stringify(error))
            } finally {
                this.loading = false;
            }
            console.log('loading dashboard')
            try {
              const response_dashboard =  await apiClient.get("/api/v1/dashboard/")
              console.log(response_dashboard.data)
              this.$store.commit('setPlants', response_dashboard.data)
            }catch (error) {
                console.info("Fehler beim Laden von dashboard");

                console.log(JSON.stringify(error))
            } finally {
                this.loading = false;
            }
            console.log('loading authgroup')
            try {
              const response_authgroup =  await apiClient.get("/api/v1/authgroup/")
              console.log(response_authgroup)
              this.$store.commit('setPermissions', response_authgroup.data[0])
              this.$store.commit('setIsAuthenticated', true)
            }catch (error) {
                console.info("Fehler beim Laden von dashboard");

                console.log(JSON.stringify(error))
            } finally {
                this.loading = false;
            }
                        
            console.log('superuser? ' + this.$store.state.me.is_superuser)
            const organisationID = this.$store.state.me.organisation
            try {
              const response_orga =  await apiClient.get(`/api/v1/organisations/${organisationID}/`)
              console.log(response_orga)
              this.$store.commit('setOrganisation', response_orga.data)
                    
              this.$router.push('/dashboard')
            }catch (error) {
                console.info("Fehler beim Laden von dashboard");

                console.log(JSON.stringify(error))
            } finally {
                this.loading = false;
            }
            
            
        },
        switchVisibility(){
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            this.$refs.password.focus();
        }
    }
}
</script>