<template>
    <div class="container-fluid my-3 py-3">
        <div class="row my-3"
                v-for="translation in this.plantDashboardHeader.plant_dashboard_header_translations"
                v-bind:key="translation.id">
            
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="translation.value" placeholder="0">
                    <label for="inputName" class="form-label" >{{ $t("number-value") }}</label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="translation.translation" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("translation") }}</label>
                </div>
            </div>
        </div> 

        <div class="row">
            <div class="col-6">
                <button :disabled="!$store.state.me.is_superuser" class="btn btn-success mb-0 ms-auto" @click="addNewTranslation" v-if="$store.state.me.is_superuser">{{ $t("addNewTranslation") }}</button>
                
            </div>
            
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
export default {
    name: 'EditPlantDashboardHeaderTranslations',
    emits: [],
    props: {
        plantDashboardHeader: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            
        }
    },
    mounted(){
        console.log('entry mounted')
        //this.focusInput()
        console.log('exit mounted')
    },
    methods: {
        focusInput() {
            console.log('entry focusInput')
            console.log(this.$refs.name)
            this.$refs.name.focus();
            console.log('exit focusInput')
        },
        addNewTranslation(){
            console.log('entry addNewTranslation')
            this.plantDashboardHeader.plant_dashboard_header_translations.push({
                value: '',
                translation: '',
                uuid: uuidv4(),
                created_by: this.$store.state.user.id,
                modified_by: this.$store.state.user.id,
            })

            console.log('exit addNewTranslation')
        },
    },
    computed:{
    }
}
</script>