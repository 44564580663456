<template>
    <div class="container-fluid my-3 py-3">
        <div class="row">
            <div class="col-12 mb-3">
                <div class="form-floating">
                    <input id="inputUsername" disabled type="text" name="username" class="form-control" @keyup.enter="submitForm" v-model="user.username" placeholder="name@examp" required>
                    <label for="inputUsername" class="form-label" >{{ $t("username") }}</label>                
                </div>
            </div>
        </div>
        <div class="row">
            <div class="input-group">
                <div class="form-floating form-floating-group flex-grow-1">
                    <input ref="password" id="inputPassword" :type="passwordFieldType" name="password" class="form-control" v-model="password" aria-describedby="hideUnhide" placeholder="test">
                    <label for="inputPassword" class="form-label">{{ $t("Passwort") }}</label>
                </div>
                <span class="input-group-text" id="hideUnhide" @click="switchVisibility">
                    <i class="fa-solid fa-eye" v-if='this.passwordFieldType === "password"'></i>
                    <i class="fa-solid fa-eye-slash" v-if='this.passwordFieldType === "text"'></i>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="text-danger mt-2" v-if="errors.length">
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div>
        </div>
        <button class="btn btn-success mt-2 mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'ResetPasswordForUser',
    props: {
        organisation: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            passwordFieldType: "password",
            password: '',
            errors: [],
        }
    },
    mounted(){
       
    },
    methods: {
        
        submitForm() {
            this.errors = []
            const formData = {
                new_password: this.password,
            }
            console.log('formData')
            console.log(formData)
            axios
                .post(`/api/v1/account/${this.user.id}/reset_password_user/`, formData)
                .then(response => {
                    
                    createToast({text: this.$t("user_altered")
                   ,type: 'success'})
                   this.$emit('closeModal')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            
        },
        switchVisibility(){
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            this.$refs.password.focus();
        }
    }
}
</script>