<template>
    <div class="container-fluid">
        <div class="row my-3"
                v-for="alarmcode in this.allAlarmcodesForSystem"
                v-bind:key="alarmcode.id">
            
            <div class="col-small mt-4">
                <button type="button" class="btn btn-danger me-2" @click="removeAlarmcode(alarmcode)"><i class="fa-solid fa-minus"></i></button>
            </div>
            <div class="col-2">
                <label for="inputAlarmCode" class="form-label" >{{ $t("alarm_code") }}</label>
                <label id="inputAlarmCode" type="text" name="inputAlarmCode" class="form-control" disabled>{{alarmcode.alarm_code}}</label>
            </div>
            <div class="col-2">
                <label for="inputAlarmBracket" class="form-label" >{{ $t("alarm_bracket") }}</label>
                <input id="inputAlarmBracket" type="text" name="inputAlarmBracket" class="form-control" v-model="alarmcode.alarm_bracket" :disabled="alarmcode.id != this.alarmCodeEdit.id">
            </div>
            <div class="col-6">
                <label for="inputAlarmTxt" class="form-label" >{{ $t("alarm_txt") }}</label>
                <input id="inputAlarmTxt" type="text" name="inputAlarmTxt" class="form-control" :class="{'bg-danger': alarmcode.alarm_txt === 'systemisch erzeugt'}" v-model="alarmcode.alarm_txt" :disabled="alarmcode.id != this.alarmCodeEdit.id">
            </div>
            <div class="col-small mt-4">
                <button v-if="alarmcode.id != this.alarmCodeEdit.id" type="button" class="btn btn-light me-2" @click="editAlarmcode(alarmcode)"><i class="fa-regular fa-pen"></i></button>
                <button v-else type="button" class="btn btn-light me-2" @click="saveAlarmcode(alarmcode)"><i class="fa-regular fa-floppy-disk"></i></button>
            </div>
        </div>
        <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-end">
                <li class="page-item" :class="{'disabled': !showPrevButton}" >
                    <a class="page-link"  @click="loadPrev()">&laquo;</a>
                </li>
                <li class="page-item" :class="{'disabled': !showNextButton}">
                    <a class="page-link" href="#" @click="loadNext()">&raquo;</a>
                </li>
            </ul>
        </nav>
        <div class="card shadow-sm ">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-success mb-0 ms-auto" @click="addAlarmcode" data-bs-toggle="modal" data-bs-target="#modal">{{ $t("addAlarmcode") }}</button>
                        <button class="btn btn-success mb-0 ms-auto" @click="copyAlarmcodeSystem" data-bs-toggle="modal" data-bs-target="#modal">{{ $t("copyAlarmcodeSystem") }}</button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                    <button type="button" id="modalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetComponent()"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component v-if="this.currentComponent" :is="currentComponent" v-bind="currentProperties" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="resetComponent()">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'

import AddAlarmcodeForSystem from '@/components/Alarmmessages/AddAlarmcodeForSystem.vue'
import CopyAlarmcodeSystem from '@/components/Alarmmessages/CopyAlarmcodeSystem.vue'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
export default {
    name: 'EditAlarmcodesForSystem',
    components: {
        AddAlarmcodeForSystem,
        CopyAlarmcodeSystem
    },
    props: {
        plantSystem: {
            type: Object,
            required: true
        }
    },
    watch: {
        plantSystem: {
            deep: true,
            handler() {
                console.log('watch fired')
                this.loadAlarmcodesForSystem()
            }
        }
    },
    data() {
        return {
            currentComponent: "",
            alarmCodeEdit: {},
            oldAlarmCodeText: '',
            allAlarmcodesForSystem: [],
            currentPage: 1,
            showNextButton: false,
            showPrevButton: false
        }
    },
    async mounted(){
        await this.loadAlarmcodesForSystem()
    },
    unmounted(){
        console.log('component unmounted')        
    },
    methods: {
        editAlarmcode(alarm_code){
            console.log('entry editAlarmcode')
            if(this.alarmCodeEdit){
                this.alarmCodeEdit.alarm_txt = this.oldAlarmCodeText
            }
            this.alarmCodeEdit = alarm_code
            this.oldAlarmCodeText = alarm_code.alarm_txt
            console.log('exit editAlarmcode')
        },
        saveAlarmcode(alarm_code){
            console.log('entry saveAlarmcode')
            console.log(alarm_code)
            axios
                .patch(`/api/v1/alarmcodes/${alarm_code.id}/`, alarm_code)
                .then(response => {
                    
                    createToast({text: this.$t("alarmcodes altered")
                   ,type: 'success'})
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            this.oldAlarmCodeText = alarm_code.alarm_txt
            this.resetEditAlarmCode()
            console.log('exit saveAlarmcode')
        },
        resetEditAlarmCode(){
            console.log('entry resetEditAlarmCode')
            if(this.alarmCodeEdit){
                this.alarmCodeEdit.alarm_txt = this.oldAlarmCodeText
            }
            this.alarmCodeEdit = 0
            this.oldAlarmCodeText = ''
            console.log('exit resetEditAlarmCode')
        },
        loadNext() {
            this.currentPage += 1
            this.loadAlarmcodesForSystem()
            this.resetEditAlarmCode()
        },
        loadPrev() {
            this.currentPage -= 1
            this.loadAlarmcodesForSystem()
            this.resetEditAlarmCode()
        },
        loadAlarmcodesForSystem(){
            console.log('entry loadAlarmcodesForSystem')
            axios
                .get(`/api/v1/alarmcodes_list/?page=${this.currentPage}&system_id=${this.plantSystem.id}`)
                .then(response => {
                    console.log('activity loaded')
                    console.log(response.data)
                    this.showNextButton = false
                    this.showPrevButton = false
                    console.log(response.data.next)
                                
                    if (response.data.next != null) {
                        this.showNextButton = true
                    }
                    console.log(response.data.previous)
                    if (response.data.previous) {
                        this.showPrevButton = true
                        console.log('prevbutton')
                    }

                    this.allAlarmcodesForSystem = response.data.results
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        removeAlarmcode(alarm_code){
            console.log('entry removeItem: ')
            axios
                .delete(`/api/v1/alarmcodes/${alarm_code.id}/`)
                .then(response => {
                    const index = this.allAlarmcodesForSystem.findIndex((element, index) => {
                        if(element.id === alarm_code.id){
                            return true
                        }
                    })
                    console.log('index: ' +index)
                    this.allAlarmcodesForSystem.splice(index, 1)
                    
                    createToast({text: this.$t("alarmcode deleted")
                   ,type: 'success'})

                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit removeItem')
        },  
        
        addAlarmcode(){
            console.log('entry addAlarmcode')
            this.currentComponent = "AddAlarmcodeForSystem",
            console.log('exit addAlarmcode')
        },

        copyAlarmcodeSystem(){
            console.log('entry copyAlarmcodeSystem')
            this.currentComponent = "CopyAlarmcodeSystem",
            console.log('exit copyAlarmcodeSystem')
        },

        closeModal(action, element){
            console.log("closeModal")
            document.getElementById('modalClose').click();
            this.currentComponent = ""
            this.loadAlarmcodesForSystem()
        },
        resetComponent(){
            console.log('entry resetComponent')
            this.currentComponent = ""
            console.log('exit resetComponent')
        }
    },
    computed:{
        currentProperties(){
            
            return {
                plantSystem: this.plantSystem, 
                }
        },
    }
}
</script>