<template>
    <div class="card shadow-sm">
      <div class="card-header text-center">
        <p class="h5">{{ $t("selectionIntervall") }}</p>
      </div>
      <div class="card-body">
        <div class="mt-2 text-center">
          <Datepicker
            v-model="date"
            range
            closeOnScroll
            autoApply
            :format="getDatepickerFormat"
            :enable-time-picker="false"
            locale="de"
            cancelText="abbrechen"
            selectText="auswählen"
            v-if="selectionIntervall === 'day'"
          />
          <Datepicker
            v-model="date"
            monthPicker
            range
            closeOnScroll
            autoApply
            :format="getDatepickerFormat"
            locale="de"
            cancelText="abbrechen"
            selectText="auswählen"
            :enableTimePicker="false"
            v-if="selectionIntervall === 'month'"
          />
          <Datepicker
            v-model="date"
            yearPicker
            closeOnScroll
            autoApply
            :format="getDatepickerFormat"
            locale="de"
            cancelText="abbrechen"
            selectText="auswählen"
            :enableTimePicker="false"
            v-if="selectionIntervall === 'year'"
          />
        </div>
        <div class="mt-3 d-flex justify-content-evenly">
          <button class="btn btn-success mb-0 ms-auto" @click="confirmDateChart('xlsx')" :disabled="isButtonConfirmDisabled"><i class="fa-solid fa-file-excel"></i> {{ $t("confirm_excel") }}</button>
          <button class="btn btn-success mb-0 ms-auto" @click="confirmDateChart('plain')" :disabled="isButtonConfirmDisabled"><i class="fa-solid fa-table-columns"></i> {{ $t("browser") }}</button>
        </div>
        <div class="mt-3 d-flex justify-content-evenly">
            {{ $t("text_wait_evaluation") }}
        </div>
      </div>
    </div>
  </template>

<script>
export default {
    name: 'ChooseDateRangeEvaluation',
    emits: [
        'confirmDate'
    ],
    components: {
    },
    props: {
        evaluation: {
            type: Object,
            required: true
        },
        
    },
    watch: {
        evaluation: {
            deep: true,
            handler() {
                console.log('watch fired')
                this.setSelectionIntervall()
                this.setDefaultDate()
            }
        }
    },
    data() {
        return {
            date: null,
            selectionIntervall: "day",
        }
    },
    mounted(){
        this.setSelectionIntervall()
        this.setDefaultDate()
    },
    updated(){
        console.log('entry updated')
        
        console.log('exit updated')
    },
    methods: {
        setDefaultDate(){
            console.log('entry setDefaultDate')
            const currentDate = new Date();
            this.date = null
            if(this.evaluation.type_evaluation == 'DA'){
                const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

                const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0);
                this.date = [startDate, endDate];
            }else if (this.evaluation.type_evaluation == 'MO'){
                const startMonth = {"month": 0, "year": currentDate.getFullYear() } 
                const endMonth = {"month": currentDate.getMonth(), "year": currentDate.getFullYear() } 
                this.date = [startMonth, endMonth]
            }else if (this.evaluation.type_evaluation == 'YE'){
                this.date = {"year": currentDate.getFullYear() } 
            }

            
            console.log('exit setDefaultDate')
        },
        setSelectionIntervall(){
            console.log('entry setSelectionIntervall')
            if(this.evaluation.type_evaluation == 'DA'){
                this.selectionIntervall = "day"
            }else if (this.evaluation.type_evaluation == 'MO'){
                this.selectionIntervall = "month"
            }else if (this.evaluation.type_evaluation == 'YE'){
                this.selectionIntervall = "year"
            }
            console.log('exit setSelectionIntervall')
        },  
        confirmDateChart(type){
            console.log('entry confirmDate')
            this.$emit('confirmDate', this.getIntervall(), type)
            console.log('exut confirmDate')
        },
        switchIntervall(){
            console.log('entry switchIntervall')
            this.date = null
            console.log('exit switchIntervall')
        },
        getIntervall(){
            console.log('entry getIntervall')
            let startIntervall = {}
            let endIntervall = {}

            if(this.selectionIntervall === 'live') {
                console.log("year") 
                startIntervall['day'] = '1'
                startIntervall['month'] = '0'
                startIntervall['year'] = '1970'
                endIntervall['day'] = '31'
                endIntervall['month'] = '11'
                endIntervall['year'] = '1970'
            }else if(this.selectionIntervall === 'day') {
                console.log("day") 
                startIntervall['day'] = this.date[0].getDate()
                startIntervall['month'] = this.date[0].getMonth()
                startIntervall['year'] = this.date[0].getFullYear()
                endIntervall['day'] = this.date[1].getDate()
                endIntervall['month'] = this.date[1].getMonth()
                endIntervall['year'] = this.date[1].getFullYear()
            }else if(this.selectionIntervall === 'month') {
                console.log("month") 
                startIntervall['day'] = '1'
                startIntervall['month'] = this.date[0]['month']
                startIntervall['year'] = this.date[0]['year']
                if(this.date[1] != null){
                    
                    var lastDayOfMonth = new Date(this.date[1]['year'], this.date[1]['month'] +1, 0)
                    endIntervall['day'] = lastDayOfMonth.getDate()
                    endIntervall['month'] = this.date[1]['month']
                    endIntervall['year'] = this.date[1]['year']
                }else{
                    var lastDayOfMonth = new Date(this.date[0]['year'], this.date[0]['month'] +1, 0)
                    endIntervall['day'] = lastDayOfMonth.getDate()
                    endIntervall['month'] = this.date[0]['month']
                    endIntervall['year'] = this.date[0]['year']
                }
                
            }else if(this.selectionIntervall === 'year') {
                console.log("year") 
                startIntervall['day'] = '1'
                startIntervall['month'] = '0'
                startIntervall['year'] = this.date
                endIntervall['day'] = '31'
                endIntervall['month'] = '11'
                endIntervall['year'] = this.date
            }
           
            console.log('exit getIntervall')
            return {
                startIntervall: startIntervall,
                endIntervall: endIntervall,
                type_intervall: this.selectionIntervall,
            }
        },
        
    },
    computed:{
        radioLabels() {
            return {
                day: this.$t('day'),
                month: this.$t('month'),
                year: this.$t('year'),
            };
        },
        isButtonConfirmDisabled(){
            if(this.date === null){
                return true
            }
            return false
        },
        getDatepickerFormat(){
            console.log("getDatepickerFormat entry")
            console.log('date:')
            console.log(this.date)
            if(this.selectionIntervall === 'day') {
                console.log("day") 
                return "dd/MM/yyyy"
            }
            if(this.selectionIntervall === 'month') {
                console.log("month") 
                return "MM/yyyy"
            }
            if(this.selectionIntervall === 'year') {
                console.log("year") 
                return "yyyy"
            }
        },
    }
}
</script>
