<template>
    <div class="row">
        <div class="col-lg-4 my-2">
            <div class="card shadow-sm">
                <div class="card-header text-center">
                    <p class="h5">{{ $t("selectionIntervall") }}</p>
                    
                </div>
                <div class="card-body ">
                    <div class="mt-2 text-center">
                        <Datepicker v-model="date" monthPicker
                                                   closeOnScroll autoApply 
                                                   :format="getDatepickerFormat" locale="de"
                                                   cancelText="abbrechen" selectText="auswählen" 
                                                   :enableTimePicker="false" 
                                                   v-if="selectionIntervall === 'month'"/>
                    </div>
                    <div class="text-end mt-3">
                        <button class="btn btn-success mb-0 ms-auto" @click="getPlantNotificationData()" :disabled="isButtonConfirmDisabled">{{ $t("confirm") }}</button>
                    </div>
                </div>
            </div>
          
        </div>        
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card shadow-sm">
                <div class="card-body scroll-y">
                    <table class="table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>{{ $t("plant") }}</th>
                                <th>{{ $t("num_email") }}</th>
                                <th>{{ $t("num_sms") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="notification in plantNotificationData.datasets"
                                v-bind:key="notification">
                                <td> {{ notification.plant }}</td>
                                <td> {{ notification.num_email }}</td>
                                <td> {{ notification.num_sms }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import axios from 'axios'
import { createToast } from '@/assets/js/myToast.js'
const fileDownload = require('js-file-download')
export default {
    name: 'PlantNotificationsForOrganisation',
    components: {
    },
    props: {
        organisation: {
            type: Object,
            required: true
        },
        
    },
    watch: {
        organisation: {
            deep: true,
            handler() {
                console.log('watch fired')
                this.setDefaultDate()
                this.getPlantNotificationData()
            }
        }
    },
    data() {
        return {
            date: null,
            selectionIntervall: "month",
            plantNotificationData: {}
        }
    },
    updated(){
        console.log('entry updated')
        
        console.log('exit updated')
    },
    mounted(){
        console.log('entry mounted')
        this.setDefaultDate()
        this.getPlantNotificationData()
        console.log('exit mounted')
    },
    methods: {
        setDefaultDate(){
            console.log('entry setDefaultDate')
            const currentDate = new Date();
            this.date = {"month": currentDate.getMonth(), "year": currentDate.getFullYear() } 
            console.log('exit setDefaultDate')
        },
        generatePdf(htmlRef){
            console.log('entry generatePDF: ' +htmlRef)

            var doc = new jsPDF('l', 'pt', 'A4');
            
            doc.autoTable({ html: '#test', 
                            rowPageBreak: 'auto',
                            horizontalPageBreak: 'true',
                            horizontalPageBreakRepeat: 0,
                            //pageBreak: 'always',
                            // Default for all columns
                            styles: { overflow: 'linebreak'},
                            //bodyStyles: { valign: 'top' },
                            
                        })
            doc.save('table.pdf')
        },
        generateExcel(htmlRef){
            console.log('entry generateExcel: ' +htmlRef)

            var formData = this.getIntervall()
            
            formData.type_data = 'xlsx'
            axios
                .post(`/api/v1/plants/${this.plant.id}/get_plant_notification/`, formData, {responseType: 'blob'})
                .then(response => {
                    console.log('fertig')
                    fileDownload(response.data, `_auswertung.xlsx`);
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit generateExcel')
        },
        getIntervall(){
            console.log('entry getIntervall')
            let startIntervall = {}
            let endIntervall = {}

            if(this.selectionIntervall === 'day') {
                console.log("day") 
                startIntervall['day'] = this.date[0].getDate()
                startIntervall['month'] = this.date[0].getMonth()
                startIntervall['year'] = this.date[0].getFullYear()
                endIntervall['day'] = this.date[1].getDate()
                endIntervall['month'] = this.date[1].getMonth()
                endIntervall['year'] = this.date[1].getFullYear()
            }
            if(this.selectionIntervall === 'month') {
                console.log("month") 
                startIntervall['day'] = '1'
                startIntervall['month'] = this.date['month']
                startIntervall['year'] = this.date['year']
                if(this.date[1] != null){
                    
                    var lastDayOfMonth = new Date(this.date[1]['year'], this.date[1]['month'] +1, 0)
                    endIntervall['day'] = lastDayOfMonth.getDate()
                    endIntervall['month'] = this.date[1]['month']
                    endIntervall['year'] = this.date[1]['year']
                }else{
                    var lastDayOfMonth = new Date(this.date['year'], this.date['month'] +1, 0)
                    endIntervall['day'] = lastDayOfMonth.getDate()
                    endIntervall['month'] = this.date['month']
                    endIntervall['year'] = this.date['year']
                }
                
            }
            if(this.selectionIntervall === 'year') {
                console.log("year") 
                startIntervall['day'] = '1'
                startIntervall['month'] = '0'
                startIntervall['year'] = this.date
                endIntervall['day'] = '31'
                endIntervall['month'] = '11'
                endIntervall['year'] = this.date
            }
            const formData = {
                startIntervall: startIntervall,
                endIntervall: endIntervall,
                type_intervall: this.selectionIntervall,
            }
            console.log('exit getIntervall')
            return formData
        },
        getPlantNotificationData(){
            console.log('entry getPlantNotificationData')
            console.log(this.date)

            var formData = this.getIntervall()
            
            formData.type_data = 'plain'
            axios
                .post(`/api/v1/organisation/${this.organisation.id}/get_plant_notification/`, formData)
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    this.plantNotificationData = response.data
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantNotificationData')
        },
        reset(){
            console.log('entry reset')
            this.selectionIntervall = "month",
            this.date = null
            this.plantNotificationData = {}
            console.log('exit reset')
        },
        switchIntervall(){
            console.log('entry switchIntervall')
            this.date = null
            console.log('exit switchIntervall')
        }
    },
    computed:{
        isButtonConfirmDisabled(){
            if(this.selectionIntervall === 'live'){
                return false
            }

            if(this.date === null){
                return true
            }
            return false
        },
        getDatepickerFormat(){
            console.log("getDatepickerFormat entry")
            console.log('date:')
            console.log(this.date)
            if(this.selectionIntervall === 'month') {
                console.log("month") 
                return "MM/yyyy"
            }
        },
        
    }
}
</script>
