<template>
    <div class="page-config-live-data">
        
        <div class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
            <NavPlantsSidebar v-if="plantsLoaded" v-bind="currentPlantsProperties" 
                v-on:setPlant="setPlant"  v-on:setComponent="setPlantComponent" ></NavPlantsSidebar>
        </div>
        <div class="col-md-9 ms-sm-auto col-lg-10">
            <component :is="currentTabComponent" v-if="this.userSelectedSomething" v-bind="currentProperties"></component>

        </div>
    </div>
    
</template>

<script>
    
import EditLimitsForComponent from '@/components/masterdata/EditLimitsForComponent.vue'
import NavPlantsSidebar from '@/components/navs/NavPlantsSidebar.vue'
import axios from 'axios'
export default {
    name: 'Plants',
    emits: [
            ],
    components: {
        NavPlantsSidebar, 
        EditLimitsForComponent,
        },
    props: {
    },
    data() {
        return {
            currentPlant: "",
            currentTabComponent: "EditLimitsForComponent",
            currentComponent: "AddPlant",
            currentPlantComponent: "",
            userSelectedSomething: false
        }
    },
    mounted(){
    },
    updated(){
        
    },
    methods: {
        
        setPlant(plant){
            console.log('entry setPlant')
            if(this.currentPlant != plant){
                this.currentPlant = plant
                this.currentPlantComponent = ""
                this.currentTabComponent = ""
                this.userSelectedSomething = false
            }
            console.log('exit setPlant')
        },
        setPlantComponent(plant, component){
            console.log('entry setPlantComponent')
            this.currentPlantComponent = component
            this.currentPlant = plant
            this.currentTabComponent = "EditLimitsForComponent"
            this.userSelectedSomething = true
            console.log('exit setPlantComponent')
        },
        
    },
    computed:{
        canWritePlant(){
            return this.$store.getters.userHasPermission('plant', 'can_write')
        },
        plantsLoaded(){
            if(this.$store.getters.getPlants){
                return true
            }
            return false
        },
        currentProperties(){
                return {componentId: this.currentPlantComponent.id
                    ,canWritePlant: this.canWritePlant}
            },
        currentPlantsProperties(){
                return {plants: this.$store.getters.getPlants
                    ,showPlantComponents: true
                    ,editPlantComponents: false
                    ,editPlants: false}
            },
        
    }
}
</script>