<template>
    <ul class="nav nav-sidebar flex-column ms-2">
        <li class="nav-item" v-for="plant in this.plants"
            v-bind:key="plant.id">
           
            <a class="nav-link " href="#" 
                >
                <input
                v-on:change="updateChoosenData(plant)"
                class="form-check-input"
                type="checkbox"
                name="plants"
                :value="plant.id"
                :id="plant.id"
                v-bind:checked="plantIsSelected(plant)"
            >
            <label
                class="form-check-label"
                :for="plant.id"
            >
                {{ plant.name }} <template v-if="plant.system_bez"><small>({{ plant.system_bez }})</small></template>
            </label>
                
            </a>
            
        </li>   
    </ul>       
</template>
<script>
import axios from 'axios'
export default {
    name: 'NavPlantsSidebarMultiselect',
    emits: [
        'setPlant',
        "updateChoosenData",
            ],
    components: {
        },
    props: {
        checkedPlants: {
        type: Array,
        required: true,
      },
        plants: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            currentPlant: "",
        }
    },
    mounted(){
    },
    updated(){
        
    },
    methods: {
        plantIsSelected(plant) {
        return this.checkedPlants.find(
          (d) => d.id === plant.id
        ) !== undefined;
      },
        updateChoosenData(plant) {
            this.$emit("updateChoosenData", plant);
        },
        setPlant(plant){
            console.log('entry setPlant')
            if(this.currentPlant != plant){
                this.currentPlant = plant
            }
            
            this.$emit('setPlant', plant)

            console.log('exit setPlant')
        },
    },
    computed:{
        
    }
}
</script>