<template>
    <div class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
        <NavPlantSystemsSidebar v-if="allPlantSystems" v-bind="currentPlantSystemsProperties" 
            v-on:setSystem="setSystem" ></NavPlantSystemsSidebar>
    </div>
    <div class="col-md-9 ms-sm-auto col-lg-10">
        <component :is="currentTabComponent" v-if="this.currentSystem" v-bind="currentProperties"></component>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import NavPlantSystemsSidebar from '@/components/navs/NavPlantSystemsSidebar.vue'
import EditAlarmcodesForOrganisation from '@/components/Alarmmessages/EditAlarmcodesForOrganisation'
import axios from 'axios'
export default {
    name: 'AlarmcodesOrganisation',
    components: {
        NavPlantSystemsSidebar,
        EditAlarmcodesForOrganisation
    },
    
    data() {
        return {
            currentSystem: "",
            currentTabComponent: "EditAlarmcodesForOrganisation",
        }
    },
    mounted(){
        this.checkAndLoadPlantSystems()
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
        
    },
    methods: {
        setSystem(system){
            console.log('entry setSystem')
            this.currentSystem = system
            console.log('exit setSystem')
        },  
        checkAndLoadPlantSystems(){
            console.log('entry checkAndLoadPlantSystems')
            if(this.allPlantSystems.length < 1){
                axios
                    .get('/api/v1/plantsystems/')
                    .then(response => {
                        console.log('plantsystems loaded')
                        console.log(response.data)
                        this.$store.commit('setPlantSystems', response.data)
                    
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
            }
            console.log('exit checkAndLoadPlantSystems')
        }
    },
    computed:{
        canWriteAlarmcodes(){
            return this.$store.getters.userHasPermission('alarmcodes', 'can_write')
        },
        allPlantSystems(){
            return this.$store.getters.getPlantSystems
        },
        
        currentPlantSystemsProperties(){
                return {plantSystems: this.$store.getters.getPlantSystems,
                    }
            },
        currentProperties(){
                return {plantSystem: this.currentSystem
                    ,canWriteAlarmcodes: this.canWriteAlarmcodes}
            },
    }
}
</script>