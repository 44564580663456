<template>
    <div class="container-fluid my-3 py-3">
        <div class="row my-3">
            <div class="col-8">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="dashboard.name" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                </div>
            </div>                
        </div>
        <div class="row my-3">
            <div class="col-8">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="dashboard.num_alarms" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("num_alarms") }}</label>
                </div>
            </div>
        </div>
        <div class="row my-3" v-if="$store.state.me.is_superuser">
            <div class="col-8">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="dashboard.type" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("type") }}</label>
                </div>
            </div>
        </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'EditLiveData',
    emits: ['closeModal'],
    props: {
        organisation: {
            type: Object,
            required: true,
        },
        dashboard: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
        }
    },
    mounted(){
        console.log('entry mounted')
        this.focusInput()
        console.log('exit mounted')
    },
    methods: {
        focusInput() {
            console.log('entry focusInput')
            console.log(this.$refs.name)
            this.$refs.name.focus();
            console.log('exit focusInput')
        },
        submitForm() {            
            axios
                .patch(`/api/v1/plantdashboards/${this.dashboard.id}/`, this.dashboard)
                .then(response => {
        
                    createToast({text: this.$t("dashboard altered")
                        ,type: 'success'})

                this.$emit('closeModal', 'edit', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    },
    computed:{
    }
}
</script>