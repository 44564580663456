<template>
    <div class="row">
        <div class="col-lg-4 my-2">
            <div class="card shadow-sm">
                <div class="card-header text-center">
                    <p class="h5">{{ $t("selectionIntervall") }}</p>
                    
                </div>
                <div class="card-body ">
                    <div class="mt-2 text-center">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" @click="switchIntervall" type="radio" name="flexRadioDefault" id="selectionDay" v-model="selectionIntervall" v-bind:value="'day'" checked >
                            <label class="form-check-label" for="selectionDay">
                                {{ $t("day") }}
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" @click="switchIntervall" type="radio" name="flexRadioDefault" id="selectionMonth" v-model="selectionIntervall" v-bind:value="'month'" >
                            <label class="form-check-label" for="selectionMonth">
                                {{ $t("month") }}
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" @click="switchIntervall" type="radio" name="flexRadioDefault" id="selectionYear" v-model="selectionIntervall" v-bind:value="'year'">
                            <label class="form-check-label" for="selectionYear">
                                {{ $t("year") }}
                            </label>
                        </div>
                    </div>
                    <hr>
                    <div class="mt-2 text-center">
                        <Datepicker v-model="date" range closeOnScroll autoApply 
                                                   :format="getDatepickerFormat" locale="de"
                                                   cancelText="abbrechen" selectText="auswählen" 
                                                   :enableTimePicker="false"
                                                   v-if="selectionIntervall === 'day'"/>
                        <Datepicker v-model="date" monthPicker
                                                   range closeOnScroll autoApply 
                                                   :format="getDatepickerFormat" locale="de"
                                                   cancelText="abbrechen" selectText="auswählen" 
                                                   :enableTimePicker="false" 
                                                   v-if="selectionIntervall === 'month'"/>
                        <Datepicker v-model="date" yearPicker
                                                   closeOnScroll autoApply 
                                                   :format="getDatepickerFormat" locale="de"
                                                   cancelText="abbrechen" selectText="auswählen" 
                                                   :enableTimePicker="false" 
                                                   v-if="selectionIntervall === 'year'"/>
                    </div>
                    <div class="mt-3 d-flex justify-content-evenly">
                        <button class="btn btn-success mb-0 ms-auto" @click="getAlarmmessagesForPlantPdf" ><i class="fa-solid fa-file-pdf"></i> {{ $t("pdf") }}</button>
                        <button class="btn btn-success mb-0 ms-auto" @click="getAlarmmessagesForPlantExcel" ><i class="fa-solid fa-file-excel"></i> {{ $t("confirm_excel") }}</button>
                        <button class="btn btn-success mb-0 ms-auto" @click="getAlarmmessagesForPlantPlain" ><i class="fa-solid fa-table-columns"></i> {{ $t("browser") }}</button>
                    </div>
                </div>
            </div>
          
        </div>        
    </div>
    <div class="row my-3" v-if="allAlarmmessages.hasOwnProperty('alarm_messages')">
        <div class="col-12">

            <div class="card shadow-sm">
                <div class="card-body scroll-y">
                        
                    <table id="test" ref="testHtml" class="table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>{{ $t("date") }}</th>
                                <th>{{ $t("code") }}</th>
                                <th>{{ $t("description") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="alarm_message in this.allAlarmmessages.alarm_messages"
                                v-bind:key="alarm_message.id">
                                <td>{{ this.getTimeFormatted(alarm_message.created_at)}}</td>
                                <td>{{alarm_message.alarm_code}}</td>
                                <td>{{alarm_message.alarm_txt}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const fileDownload = require('js-file-download')
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'AlarmmessagesForPlant',
    props: {
        plant: {
            type: Object,
            required: true
        },
    },
    watch: {
        plant: {
            deep: true,
            handler() {
                console.log('watch fired')
                this.reset()
                this.allAlarmmessages = []
            }
        }
    },
    data() {
        return {
            allAlarmmessages: [],
            plantIdOld: 0,
            date: null,
            selectionIntervall: "day",
        }
    },
    async mounted(){
    },
    updated(){
        console.log('entry updated')

        console.log('exit updated')
    },
    methods: {
        reset(){
            console.log('entry reset')
            this.selectionIntervall = "day",
            this.date = null
            console.log('exit reset')
        },
        switchIntervall(){
            console.log('entry switchIntervall')
            this.date = null
            console.log('exit switchIntervall')
        },
        getTimeFormatted(time){
            let updatedTime = new Date(time)
            console.log('nach updatedTime')
            console.log(updatedTime.toLocaleString())
            return updatedTime.toLocaleString()
        },
        getIntervall(){
            console.log('entry getIntervall')
            let startIntervall = {}
            let endIntervall = {}

            if(this.selectionIntervall === 'day') {
                console.log("day") 
                startIntervall['day'] = this.date[0].getDate()
                startIntervall['month'] = this.date[0].getMonth()
                startIntervall['year'] = this.date[0].getFullYear()
                endIntervall['day'] = this.date[1].getDate()
                endIntervall['month'] = this.date[1].getMonth()
                endIntervall['year'] = this.date[1].getFullYear()
            }
            if(this.selectionIntervall === 'month') {
                console.log("month") 
                startIntervall['day'] = '1'
                startIntervall['month'] = this.date[0]['month']
                startIntervall['year'] = this.date[0]['year']
                if(this.date[1] != null){
                    
                    var lastDayOfMonth = new Date(this.date[1]['year'], this.date[1]['month'] +1, 0)
                    endIntervall['day'] = lastDayOfMonth.getDate()
                    endIntervall['month'] = this.date[1]['month']
                    endIntervall['year'] = this.date[1]['year']
                }else{
                    var lastDayOfMonth = new Date(this.date[0]['year'], this.date[0]['month'] +1, 0)
                    endIntervall['day'] = lastDayOfMonth.getDate()
                    endIntervall['month'] = this.date[0]['month']
                    endIntervall['year'] = this.date[0]['year']
                }
                
            }
            if(this.selectionIntervall === 'year') {
                console.log("year") 
                startIntervall['day'] = '1'
                startIntervall['month'] = '0'
                startIntervall['year'] = this.date
                endIntervall['day'] = '31'
                endIntervall['month'] = '11'
                endIntervall['year'] = this.date
            }
            const formData = {
                selectionData: this.checkedDescriptions,
                startIntervall: startIntervall,
                endIntervall: endIntervall,
                type_intervall: this.selectionIntervall,
            }
            console.log('exit getIntervall')
            return formData
        },
        getAlarmmessagesForPlantPlain(){
            console.log('entry getAlarmmessagesForPlant')
            var formData = this.getIntervall()
            formData.type_data = 'plain'
            axios
                .post(`/api/v1/plants/${this.plant.id}/get_alarmmessages_for_plant/`, formData)
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    this.allAlarmmessages = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getAlarmmessagesForPlant')
        },
        getAlarmmessagesForPlantExcel(){
            console.log('entry getAlarmmessagesForPlantExcel')
            var formData = this.getIntervall()
            formData.type_data = 'xlsx'
            axios
                .post(`/api/v1/plants/${this.plant.id}/get_alarmmessages_for_plant/`, formData, {responseType: 'blob'})
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    
                    fileDownload(response.data, `Statusliste_${this.$store.getters.getOrganisation.name}.xlsx`);
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getAlarmmessagesForPlantExcel')
        },
        getAlarmmessagesForPlantPdf(){
            console.log('entry getAlarmmessagesForPlantPdf')
            var formData = this.getIntervall()
            formData.type_data = 'pdf'
            axios
                .post(`/api/v1/plants/${this.plant.id}/get_alarmmessages_for_plant/`, formData,  {responseType: 'blob'})
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    fileDownload(response.data, `Statusliste_${this.$store.getters.getOrganisation.name}.pdf`);
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getAlarmmessagesForPlantPdf')
        }
    },
    computed:{
        getDatepickerFormat(){
            console.log("getDatepickerFormat entry")
            console.log('date:')
            console.log(this.date)
            if(this.selectionIntervall === 'day') {
                console.log("day") 
                return "dd/MM/yyyy"
            }
            if(this.selectionIntervall === 'month') {
                console.log("month") 
                return "MM/yyyy"
            }
            if(this.selectionIntervall === 'year') {
                console.log("year") 
                return "yyyy"
            }
        },
    }
}
</script>