<template>
    <div class="container-fluid">
        <div class="row my-3"
            v-for="column in this.evaluation.plant_evaluation_columns"
            v-bind:key="column.id">
            
            <div class="col-2">
                    
                <label for="inputDescCol" class="form-label" >{{ $t("name") }}</label>
                <input disabled id="inputDescCol" type="text" name="inputDescCol" class="form-control"  v-model="column.name" >
            </div>

            <div class="col-2">
                <label for="type_column" class="form-label">{{ $t("type_column") }}</label>
                <select id="type_column" class="form-select " aria-label="Default select example" v-model="column.type_column">
                    <option value="DA">{{ $t("daily_report_type_column_da") }}</option>
                    <option value="DG">{{ $t("daily_report_type_column_dg") }}</option>
                </select>
            </div>
            <div class="col-2">
                    
                <label for="inputDescCol" class="form-label" >{{ $t("linked_component") }}</label>
                <input  id="inputDescCol" type="text" name="inputDescCol" class="form-control"  v-model="column.linked_component" >
            </div>
            <div class="col-2">
                    
                <label for="inputDescCol" class="form-label" >{{ $t("linked_component_data_description_data") }}</label>
                <input  id="inputDescCol" type="text" name="inputDescCol" class="form-control"  v-model="column.linked_component_data_description_data" >
            </div>

            
            <template v-if="this.evaluation.type_evaluation == 'DA'">
                <div class="col-2">
                    <label for="type_value_column" class="form-label">{{ $t("type_value_column") }}</label>
                    <select id="type_value_column" class="form-select " aria-label="Default select example" v-model="column.type_value_column">
                        <option value="LD">{{ $t("daily_report_type_value_column_ld") }}</option>
                        <option value="MD">{{ $t("daily_report_type_value_column_md") }}</option>
                        <option value="SD">{{ $t("daily_report_type_value_column_sd") }}</option>
                        <option value="AD">{{ $t("daily_report_type_value_column_ad") }}</option>
                        <option value="FK">{{ $t("daily_report_type_value_column_fk") }}</option>
                        <option value="TK">{{ $t("daily_report_type_value_column_tk") }}</option>
                    </select>
                </div>
            </template>
            <template v-else>
                <div class="col-2">
                    <label for="type_value_column" class="form-label">{{ $t("type_value_column") }}</label>
                    <select id="type_value_column" class="form-select " aria-label="Default select example" v-model="column.type_value_column">
                        <option value="LD">{{ $t("daily_report_type_value_column_ld") }}</option>
                        <option value="MD">{{ $t("daily_report_type_value_column_md") }}</option>
                        <option value="AD">{{ $t("daily_report_type_value_column_ad") }}</option>
                    </select>
                </div>
            </template>

            <div class="col-2">
                <label for="calculation_day" class="form-label">{{ $t("calculation_day") }}</label>
                <select id="calculation_day" class="form-select " aria-label="Default select example" v-model="column.calculation_day">
                    <option value="DB">{{ $t("daily_report_data_day_before") }}</option>
                    <option value="CD">{{ $t("daily_report_data_calculation_day") }}</option>
                </select>
            </div>
            
        </div>
        <div class="row">
            
            <div class="col-6 text-end">
                <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
            </div>
        </div>

    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
export default {
    name: 'EditDescriptionComponent',
    emits: [
        'copyDescription'
    ],
    props: {
        evaluationId: {
            type: Number,
            required: true,
        },
        
    },
    components: {
    },
    watch: {
        evaluationId: {
            deep: true,
            handler() {
                console.log('watch fired')
                this.getEvaluationFromBackend()
            }
        }
    },
    data() {
        return {    
            evaluation: {}
        }
    },
    async mounted(){
        await this.getEvaluationFromBackend()
    },
    
    methods: {
        getEvaluationFromBackend(){
            console.log('entry getEvaluationFromBackend')
            axios
                .get(`/api/v1/plant_evaluations/${this.evaluationId}/`, )
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    this.evaluation = response.data
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getEvaluationFromBackend')
        },
       
        
        submitForm() {
            axios
                .patch(`/api/v1/plant_evaluations/${this.evaluation.id}/`, this.evaluation)
                .then(response => {
                    
                    createToast({text: this.$t("evaluation altered")
                   ,type: 'success'})
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    }
    
}
</script>