<template>
    <div class="container-fluid my-3 py-3">
        <div class="row my-3">
            <div class="col-12">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="plantDashboardPosition.name" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                </div>
            </div>                
        </div>
        <div class="row my-3">
            <div class="col-12">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="plantDashboardPosition.name_display" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("name_display") }}</label>
                </div>
            </div>                
        </div>
        <div class="row my-3">
            <div class="col-12">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="is_chart" v-model="plantDashboardPosition.is_chart">
                    <label class="form-check-label" for="is_chart" v-tooltip title="Anzeige der Tagesdaten als Chart" >
                        {{$t("is_chart")}}
                    </label>
                </div>
            </div>                
        </div>
        <div class="text-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'AddPlantDashboardPosition',
    emits: ['closeModal'],
    props: {
        plantDashboardPosition: {
            type:Object,
            required: true
        },
    },
    data() {
        return {
            
            errors: [],
        }
    },
    mounted(){
        console.log('entry mounted')
        this.focusInput()
        console.log('exit mounted')
    },
    methods: {
        focusInput() {
            console.log('entry focusInput')
            console.log(this.$refs.name)
            this.$refs.name.focus();
            console.log('exit focusInput')
        },
        submitForm() {
            
            axios
                .patch(`/api/v1/plantdashboards_positions/${this.plantDashboardPosition.id}/`, this.plantDashboardPosition)
                .then(response => {
        
                    createToast({text: this.$t("position altered")
                        ,type: 'success'})

                this.$emit('closeModal')
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }

                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.log(JSON.stringify(error.message))
                    } else {
                        console.log(JSON.stringify(error))
                    }
                })
        }
    },
    computed:{
        
    }
}
</script>