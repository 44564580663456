<template>
    <div class="loading-block" v-if="!this.userLoaded"></div>
    <div class="alarmcodes-general">
        <div class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
            <NavUserGroupsSidebar v-if="allUserGroups" v-bind="currentUserGroupProperties" 
                v-on:setUserGroup="setUserGroup" v-on:newUserGroup="newUserGroup" v-on:editUserGroup="editUserGroup"
                v-on:editUserGroupUserMapping="editUserGroupUserMapping" v-on:editUserGroupPlantMapping="editUserGroupPlantMapping"
                v-on:deleteUserGroup="deleteUserGroup"></NavUserGroupsSidebar>
        </div>
        <div class="col-md-9 ms-sm-auto col-lg-10">
            <div class="row">
                <EditUserGroup v-if="this.currentUserGroup" v-bind="currentProperties"></EditUserGroup>
            </div>
            <div class="row">
                <div class="col-6">
                    <EditUserGroupPlantMapping ref="plantmapping" v-if="this.currentUserGroup" v-bind="currentProperties"></EditUserGroupPlantMapping>
                </div>
                <div class="col-6">
                    <EditUserGroupUserMapping ref="usermapping" v-if="this.currentUserGroup" v-bind="currentProperties"></EditUserGroupUserMapping>
                </div>
            </div>
        </div>
        <div class="row" v-if="this.currentUserGroup">
            <div class="col-6">
                
            </div>
            <div class="col-6 text-end">
                
                <button :disabled="!this.canWriteUserGroup" class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
            </div>
        </div>
    </div>
    <button id="modalOpen" style="display:none;" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modalUserGroups" aria-hidden="true">hidden</button>
    <div class="modal fade" id="modalUserGroups" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                    <button type="button" id="modalUserGroupsClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component v-if="allUserGroups" :is="currentComponent" v-bind="currentModalProperties" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import NavUserGroupsSidebar from '@/components/usergroups/NavUserGroupsSidebar.vue'
import EditUserGroupUserMapping from '@/components/usergroups/EditUserGroupUserMapping.vue'
import EditUserGroupPlantMapping from '@/components/usergroups/EditUserGroupPlantMapping.vue'
import AddUserGroup from '@/components/usergroups/AddUserGroup'
import DeleteUserGroup from '@/components/usergroups/DeleteUserGroup'
import EditUserGroup from '@/components/usergroups/EditUserGroup'
import axios from 'axios'
export default {
    name: 'UserGroups',
    components: {
        NavUserGroupsSidebar,
        AddUserGroup,
        EditUserGroup,
        DeleteUserGroup,
        EditUserGroupUserMapping,
        EditUserGroupPlantMapping
    },
    
    data() {
        return {
            currentUserGroup: "",
            currentTabComponent: "NavUserGroupsSidebar",
            currentComponent: "AddUserGroup",
            userSelectedSomething: false,
            userLoaded: true
        }
    },
    mounted(){
        this.checkAndLoadUserGroups()
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
        
    },
    methods: {
        deleteUserGroup(userGroup){
            console.log('entry deleteUserGroup')
            this.currentUserGroup = userGroup
            this.currentComponent = "DeleteUserGroup"
            this.userSelectedSomething = true
            document.getElementById('modalOpen').click();
            console.log('exit deleteUserGroup')
        },
        editUserGroupPlantMapping(userGroup){
            console.log('entry editUserGroupPlantMapping')
            this.currentUserGroup = userGroup
            this.currentTabComponent = "EditUserGroupPlantMapping"
            this.userSelectedSomething = true
            console.log('exit editUserGroupPlantMapping')
        }, 
        editUserGroupUserMapping(userGroup){
            console.log('entry editUserGroupUserMapping')
            this.currentUserGroup = userGroup
            this.currentTabComponent = "EditUserGroupUserMapping"
            this.userSelectedSomething = true
            console.log('exit editUserGroupUserMapping')
        },  
        editUserGroup(userGroup){
            console.log('entry editUserGroup')
            this.currentUserGroup = userGroup
            this.currentTabComponent = "EditUserGroup"
            console.log('exit editUserGroup')
        },
        closeModal(action, data){
            console.log('entry closeModal with action: ' + action)
            document.getElementById('modalUserGroupsClose').click();

            if(action === 'add'){
                if(this.currentComponent === 'AddUserGroup'){
                    this.$store.commit('addUserGroup', data)
                }
            }
            if(action === 'delete'){
                if(this.currentComponent === 'DeleteUserGroup'){
                    this.$store.commit('deleteUserGroup', data)
                }
            }
            console.log('exit closeModal')
        },
        newUserGroup(){
            console.log('entry newUserGroup')
            this.currentComponent = "AddUserGroup"
            document.getElementById('modalOpen').click();
            console.log('exit newUserGroup')
        },
        setUserGroup(userGroup){
            console.log('entry setUserGroup')
            this.userLoaded = false
            axios
                .get(`/api/v1/usergroup/${userGroup.id}/`)
                .then(response => {
                    console.log('usergroup loaded')
                    console.log(response.data)
                    this.currentUserGroup = response.data
                    this.userLoaded = true
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit setUserGroup')
        },  
        checkAndLoadUserGroups(){
            console.log('entry checkAndLoadUserGroups')
            if(this.allUserGroups.length < 1){
                axios
                    .get('/api/v1/usergroup/')
                    .then(response => {
                        console.log('usergroup loaded')
                        console.log(response.data)
                        this.$store.commit('setUserGroups', response.data)
                    
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
            }
            console.log('exit checkAndLoadUserGroups')
        },
        
        submitForm() {
            console.log('entry submitForm')
            this.$refs.usermapping.submitForm()
            this.$refs.plantmapping.submitForm()
            console.log('exit submitForm')
        }
    },
    computed:{
        canWriteUserGroup(){
            return this.$store.getters.userHasPermission('user_group', 'can_write')
        },
        allUserGroups(){
            return this.$store.getters.getUserGroups
        },
        
        currentUserGroupProperties(){
                return {userGroups: this.$store.getters.getUserGroups}
            },
        currentProperties(){
                return {userGroup: this.currentUserGroup
                      ,canWriteUserGroup: this.canWriteUserGroup}
            },
        currentModalProperties(){
                return {userGroup: this.currentUserGroup,}
            },
    }
}
</script>