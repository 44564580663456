<template>
    <div v-if="allColEvaluationsFormatted.length > 0">
      <div class="card shadow-sm">
        <div class="card-header d-flex align-items-center">
          <p class="text-center h5">{{ $t("chooseEvaluation") }}</p>
        </div>
        <div class="card-body">
          <div class="row">
            <template
              v-for="evaluations in allColEvaluationsFormatted"
              v-bind:key="evaluations[0].id"
            >
              <div :class="colClasses">
                <template v-for="evaluation in evaluations" v-bind:key="evaluation.id">
                  <div class="form-check" v-if="!evaluation.only_superuser_access || (evaluation.only_superuser_access && this.$store.state.me.is_superuser)">
                    <input
                      v-on:change="updateChoosenData(evaluation)"
                      class="form-check-input"
                      type="radio"
                      name="evaluation"
                      :value="evaluation.id"
                      :id="'radio-' + evaluation.id"
                    >
                    <label
                      class="form-check-label"
                      :for="'radio-' + evaluation.id"
                    >
                      {{ evaluation.name }}
                    </label>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  export default {
    name: "ChooseEvaluation",
    emits: ["updateChoosenEvaluation"],
    components: {},
    props: {
      type: {
        type: String,
        required: true,
      },
    },
    data() {
        return {
          plantType: '',
          
        }
    },
    mounted(){
        this.getEvaluationsForType(this.type)
    },
    computed: {
      reachedMaxDescriptions(){
        return this.checkedDescriptions.length >= 1
      },
      allColEvaluationsFormatted() {
        if (!this.plantType) {
          return [];
        }
        const { plant_type_evaluations } = this.plantType;
        const arrayLength = plant_type_evaluations.length;
        if (arrayLength < 2) {
          return [plant_type_evaluations];
        }
        const middle = Math.round(arrayLength / 2);
        return [
        plant_type_evaluations.slice(0, middle),
        plant_type_evaluations.slice(middle, arrayLength),
        ];
      },
      colClasses() {
        return this.allColEvaluationsFormatted.length > 1
          ? "col-6"
          : "col";
      },
    },
    methods: {
      getEvaluationsForType(type){
        console.log('entry getEvaluationsForType')
        axios
                .get(`/api/v1/planttypes/${type}`)
                .then(response => {
                    this.plantType = response.data
                    console.log('planttypes fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        console.log('exit getEvaluationsForType')
      },
      updateChoosenData(evaluation) {
        this.$emit("updateChoosenEvaluation", evaluation);
      },
    },
  };
  </script>
  