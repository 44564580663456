<template>
    <form>
        <div class="row">
            <div class="col-5">
                <div class="form-group">
                
                    <textarea class="form-control" rows="25" v-model="daily_report_html_content"></textarea>
                </div>  
            </div>
            <div class="col-5">
                <textarea class="form-control" rows="25" v-model="daily_report_plain_content"></textarea>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-3">
            <button class="btn btn-success float-start mt-3" @click="updateDailyReportMail">{{ $t("Tagesbericht speichern") }}</button>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'ConfigOrganisationDailyReportMail',
    components: {
    },
    data() {
        return {
            daily_report_html_content: '',
            daily_report_plain_content: ''
        }
    },
    mounted(){
        this.daily_report_html_content = this.$store.state.organisation.daily_report_html_content
        this.daily_report_plain_content = this.$store.state.organisation.daily_report_plain_text_content
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
    },
    methods: {
        updateDailyReportMail(){
            const formData = {
                daily_report_html_content: this.daily_report_html_content,
                daily_report_plain_text_content: this.daily_report_plain_content
            }
            axios
                .post(`/api/v1/organisation/${this.$store.state.organisation.id}/update_daily_report_mail_content/`, formData)
                .then(response => {
                    createToast({text: this.$t("Tagesbericht wurde geändert")
                              ,title: this.$t("Tagesbericht")
                              ,type: 'success'})
                    this.logoBase64 = this.previewImage
                    this.previewImage = null
                })
                .catch(error => {
                    createToast({text: this.$t("Fehler beim Ändern des Tagesberichts!")
                              ,title: this.$t("Tagesbericht")
                              ,type: 'error'})
                    console.log(JSON.stringify(error))
                })
        }
    },
    computed:{
        
        
    }
}
</script>