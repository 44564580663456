<template>
    <div class="page-config-live-data-header">
        <div class="row">
            <div class="col-6">
                <ul class="list-group ">
                    <li :class="{'active': this.activeHeader === header}" class="list-group-item" v-for="header in getLiveDataHeaders"
                    v-bind:key="header.id" >
                        <div class="row">
                            <div class="col-8 cursor-pointer" @click="setActiveHeader(header)">
                                {{header.name}}
                            </div>
                            <div class="col-1">
                                <i class="fa-solid fa-arrow-up" @click="moveHeaderUp(header)"></i>
                            </div>
                            <div class="col-1">
                                <i class="fa-solid fa-arrow-down" @click="moveHeaderDown(header)"></i>
                            </div>
                            <div class="col-1">
                                <i class="fa-solid fa-pencil" @click="editHeader(header)" data-bs-toggle="modal" data-bs-target="#modalHeader"></i>
                            </div>
                            <div class="col-1">
                                <i class="fa-solid fa-trash" @click="deleteHeader(header)"></i>
                            </div>
                        </div>
                        
                    </li>
                    <li class="list-group-item cursor-pointer" @click="setNewHeader()"
                                data-bs-toggle="modal" data-bs-target="#modalHeader">
                        <i class="fa-solid fa-plus" ></i>
                    </li>
                </ul>
            </div>
            <div class="col-6">
                <ConfigLiveDataHeaderPosition v-if="this.activeHeader" v-bind="currentProperties" v-on:refreshData="refreshPositions"/>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modalHeader" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                    <button type="button" id="modalHeaderClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component v-if="this.liveData.live_data_headers" :is="currentComponent" v-bind="currentPropertiesHeaders" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import ConfigLiveDataHeaderPosition from '@/components/livedata/ConfigLiveDataHeaderPosition.vue'
import AddLiveDataHeader from '@/components/livedata/AddLiveDataHeader.vue'
import EditLiveDataHeader from '@/components/livedata/EditLiveDataHeader.vue'
import axios from 'axios'
export default {
    name: 'ConfigLiveDataHeader',
    emits: ['refreshData',
            'deletePosition',
            'deleteHeader',
            'addPosition'
            ],
    components: {
        ConfigLiveDataHeaderPosition,
        AddLiveDataHeader,
        EditLiveDataHeader
        },
    props: {
        organisation: {
            type: Object,
            required: true,
        },
        liveData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            activeHeader: null,
            headerOld: null,
            currentComponent: "AddLiveDataHeader",
        }
    },
    mounted(){
        
    },
    updated(){
        console.log('entry updated')
        if(this.activeHeader){
            this.checkActiveHeaderExists()
        }
        console.log('exit updated')
    },
    methods: {
        checkActiveHeaderExists(){
            console.log('entry checkActiveHeaderExists')
            console.log('activeHeader')
            console.log(this.activeHeader)
            let position = this.liveData.live_data_headers.map(e => e.id).indexOf(this.activeHeader.id)
            console.log('position: ' + position)
            if(position === -1){
                this.activeHeader = null
            }
            console.log('exit checkActiveHeaderExists')
        },
        refreshPositions(action, element){
            console.log('entry refreshPositions with action: ' + action)
            if(action === 'delete'){
                this.$emit('deletePosition', this.activeHeader, element)
            }
            if(action === 'add'){
                this.$emit('addPosition', this.activeHeader, element)
            }
            console.log('exit refreshPositions')
        },
        editHeader(header){
            console.log('entry editHeader')
            this.currentComponent = 'EditLiveDataHeader'
            this.activeHeader = header
            console.log('exit editHeader')
        },
        deleteHeader(header){
            console.log('entry deleteHeader')
            axios
                .delete(`/api/v1/organisation_live_data_headers/${header.id}/`)
                .then(response => {
                    createToast({text: this.$t("header deleted")
                    ,type: 'success'})
                    this.$emit('deleteHeader', header)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            
            console.log('exit deleteHeader')
        },
        closeModal(){
            console.log("closeModal")
            document.getElementById('modalHeaderClose').click();
            this.$emit('refreshData')
        },
        sortHeaderArray(){
            console.log('entry sortHeaderArray')
            this.liveData.live_data_headers = this.liveData.live_data_headers.sort((a,b) => {
                if(a.position < b.position){
                    return -1
                }
                if (a.position > b.position){
                    return 1
                }
                return 0
            })
            this.updateHeadersBackend()
            console.log('exit sortHeaderArray')
        },
        setNewHeader(){
            console.log('entry setNewHeader')
            this.currentComponent = "AddLiveDataHeader",
            console.log('exit setNewHeader')
        },
        moveHeaderUp(header){
            console.log('entry moveHeaderUp')
            if(header.position < 3){
                return
            }
            let header_to_switch = this.liveData.live_data_headers.filter(array_header => array_header.position === header.position -1)

            header_to_switch.forEach((value,index) =>{
                value.position = header.position
            })
            header.position--
            this.sortHeaderArray()
            console.log('exit moveHeaderUp')
        },
        moveHeaderDown(header){
            console.log('entry moveHeaderDown')
            if(header.position >= this.liveData.live_data_headers.length){
                return
            }
            let header_to_switch = this.liveData.live_data_headers.filter(array_header => array_header.position === header.position +1)

            header_to_switch.forEach((value,index) =>{
                value.position = header.position
            })
            header.position++
            this.sortHeaderArray()
            console.log('exit moveHeaderDown')
        },
        setActiveHeader(header){
            console.log('entry setActiveHeader')
            this.activeHeader = header
            console.log('exit setActiveHeader')
        },
        updateHeadersBackend(){
            console.log('entry updateHeadersBackend')
            this.liveData.live_data_headers.forEach(element => {
                axios
                    .patch(`/api/v1/organisation_live_data_headers/${element.id}/`, element)
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        if (error.response) {
                            if ('detail' in error.response.data){
                                createToast({text: this.$t(error.response.data['detail'])
                                            ,type: 'error'})
                            }                        
                            console.error(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            console.error(JSON.stringify(error.message))
                        } else {
                            console.error(JSON.stringify(error))
                        }
                    })
                })
            
            console.log('exit updateHeadersBackend')
        }
    },
    computed:{
        getLiveDataHeaders(){
            console.log('entry getLiveDataHeaders')
            if(!this.liveData.live_data_headers){
                return null
            }
            console.log(this.liveData.live_data_headers)
            

            console.log('exit getLiveDataHeaders')
            return this.liveData.live_data_headers.filter(header => header.name != "Anlage")
        },
        currentProperties(){
                return {organisation: this.$store.state.organisation, liveDataHeader: this.activeHeader, liveDataId: this.liveData.id}
        },
        currentPropertiesHeaders(){
            if(!this.liveData.live_data_headers){
                return null
            }
            return {organisation: this.$store.state.organisation, 
                    liveData: this.liveData, 
                    position: this.liveData.live_data_headers.length +1,
                    header: this.activeHeader
                }
        },
    }
}
</script>