import { Toast } from 'bootstrap'
function createToast(options){
	console.log("entry createToast")
	// Select .toast-canvas that will wrap all .toast elements
	const toastCanvas = document.getElementById("toast-container");
	// Toast HTML content
	var date = new Date();
	var time = ("0"+date.getHours()).slice(-2) + ":" + ("0"+date.getMinutes()).slice(-2) + ":" + ("0"+date.getSeconds()).slice(-2);
	let toastContent = ''; 
    if(options['title']){
        toastContent =     `<div class="toast-header">
          <strong class="me-auto">` + options['title'] +`</strong>
          <small>` + time +`</small>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close">
          </button>
        </div>`
    }
    toastContent +=`
	  </div>
		<div class="toast-body">
			`+ options['text'] + ` 
		</div>`;

	// Declare variable that will store toast instance
	let toastInstance;
	// Create empty <div> element
	const toast = document.createElement("div");
    //toastCanvas.className = '';
    toastCanvas.classList.add("bottom-0", "end-0", "position-fixed");
	// Add "toast" and "animate" classes to recently created <div>
    if(options['type'] === 'success'){
        toast.classList.add("bg-success")
    }else if (options['type'] === 'warning'){
        toast.classList.add("bg-warning")
    }else if (options['type'] === 'error'){
        toast.classList.add("bg-danger")
    }

	toast.classList.add("toast", "animate");
	toast.setAttribute("role", "alert");
	toast.setAttribute("aria-atomic", "true");
	toast.setAttribute("aria-live", "assertive");

	// Add HTML content from "toastContent" function parameter
	toast.innerHTML = toastContent;
    toast.addEventListener('hidden.bs.toast', function () {
        toastCanvas.removeChild(toast)
      })

	// Insert the toast element as a first child of .toast-canvas and make sure,
	// that every new toast element will be inserted BEFORE the last one
	toastCanvas.prepend(toast);
	
	// Create Bootstrap Toast instance with some options
	toastInstance = new Toast(toast, {delay: 5000});
	//toastInstance = new bootstrap.Toast(toast, {autohide: false});

	// Use show() method to display a toast
	toastInstance.show();
	console.log("exit createToast")
}

export {createToast}