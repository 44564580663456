<script>
import { defineComponent } from 'vue'
import { Line } from '@/assets/js/BaseCharts.js'

export default defineComponent({
  name: 'LineChart',
  extends: Line,
   props: {
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: false
    },
    chartId:{
      type: String,
      required: false,
    },
    cssClasses: {
        type: String,
        required: false,
        default: ''
      },
    height: {
        type: Number,
        required: false,
        default: 250
      },
  },
  updated(){
    console.log('updated')
    this.renderChart(this.chartData, this.chartOptions)
  },
  methods:{
  }
})
</script>