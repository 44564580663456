<template>
    <div class="alarmcodes-general">
        <div class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
            <NavPlantSystemJournalsSidebar v-if="allPlantSystemJournals" v-bind="currentPlantSystemJournalsProperties" 
                v-on:setSystemJournal="setSystemJournal" ></NavPlantSystemJournalsSidebar>
        </div>
        <div class="col-md-9 ms-sm-auto col-lg-10">
            <component :is="currentTabComponent" v-if="this.currentSystem" v-bind="currentProperties"></component>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import NavPlantSystemJournalsSidebar from '@/components/navs/NavPlantSystemJournalsSidebar.vue'
import EditPlantJournalForSystem from '@/components/PlantJournals/EditPlantJournalForSystem'
import AddSystem from '@/components/PlantSystems/AddSystem'
import EditSystem from '@/components/PlantSystems/EditSystem'
import axios from 'axios'
export default {
    name: 'ConfigPlantJournals',
    components: {
        NavPlantSystemJournalsSidebar,
        EditPlantJournalForSystem,
        AddSystem,
        EditSystem
    },
    
    data() {
        return {
            currentSystem: "",
            currentTabComponent: "EditPlantJournalForSystem",
            currentComponent: "AddSystem"
        }
    },
    mounted(){
        this.checkAndLoadPlantSystemJournals()
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
    },
    methods: {
        setSystemJournal(system){
            console.log('entry setSystemJournal')
            this.currentSystem = system
            console.log('exit setSystemJournal')
        },  
        checkAndLoadPlantSystemJournals(){
            console.log('entry checkAndLoadPlantSystemJournals')
            if(this.allPlantSystemJournals.length < 1){
                axios
                    .get('/api/v1/plantsystemjournals/')
                    .then(response => {
                        console.log('plantsystemjournals loaded')
                        console.log(response.data)
                        this.$store.commit('setPlantSystemJournals', response.data)
                    
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
            }
            console.log('exit checkAndLoadPlantSystemJournals')
        }
    },
    computed:{
        allPlantSystemJournals(){
            return this.$store.getters.getPlantSystemJournals
        },
        
        currentPlantSystemJournalsProperties(){
                return {plantSystemJournals: this.$store.getters.getPlantSystemJournals,}
            },
        currentProperties(){
                return {plantSystemJournalId: this.currentSystem.id}
            },
    }
}
</script>