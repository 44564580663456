<template>
    <div class="page-config-live-data-header-position">
        <ul class="list-group ">
            <li :class="{'active': this.activePosition === position}" class="list-group-item" v-for="position in this.liveDataHeader.live_data_positions"
                v-bind:key="position.id" >
                <div class="row">
                    <div class="col-8 cursor-pointer" @click="setActivePosition(position)">
                        {{position.name}}
                    </div>
                    <div class="col-1">
                        <i class="fa-solid fa-arrow-up" @click="movePositionUp(position)"></i>
                    </div>
                    <div class="col-1">
                        <i class="fa-solid fa-arrow-down" @click="movePositionDown(position)"></i>
                    </div>
                    <div class="col-1">
                        <i class="fa-solid fa-pencil" @click="editPosition(position)" data-bs-toggle="modal" data-bs-target="#modal"></i>
                    </div>
                    <div class="col-1">
                        <i class="fa-solid fa-trash" @click="deletePosition(position)"></i>
                    </div>
                </div>
                        
            </li>
            <li class="list-group-item cursor-pointer" @click="setNewPosition()"
                        data-bs-toggle="modal" data-bs-target="#modal">
                <i class="fa-solid fa-plus" ></i>
            </li>
        </ul>
    </div>
    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                    <button type="button" id="modalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closePopup()"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component v-if="this.popupOpen" :is="currentComponent" v-bind="currentProperties" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closePopup()">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'

import AddLiveDataPosition from '@/components/livedata/AddLiveDataPosition.vue'
import EditLiveDataPosition from '@/components/livedata/EditLiveDataPosition.vue'
import axios from 'axios'
export default {
    name: 'ConfigLiveDataPosition',
    emits: ['refreshData'],
    components: {
        AddLiveDataPosition,
        EditLiveDataPosition
        },
    props: {
        organisation: {
            type: Object,
            required: true,
        },
        liveDataHeader: {
            type: Object,
            required: true,
        },
        liveDataId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            activePosition: null,
            popupOpen:false,
            currentComponent: "AddLiveDataPosition",
        }
    },
    mounted(){
        
    },
    methods: {
        closePopup(){
            console.log('entry closePopup')
            this.popupOpen = false
            console.log('exit closePopup')
        },
        editPosition(position){
            console.log('entry editPosition')
            this.currentComponent = 'EditLiveDataPosition'
            this.activePosition = position
            this.popupOpen = true
            console.log('exit editPosition')
        },
        deletePosition(position){
            console.log('entry deletePosition')
            axios
                .delete(`/api/v1/organisation_live_data_positions/${position.id}/`)
                .then(response => {

                    createToast({text: this.$t("header_position deleted")
                    ,type: 'success'})
                    this.$emit('refreshData', 'delete', position)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            
            console.log('exit deletePosition')
        },
        closeModal(action, position){
            console.log("closeModal")
            document.getElementById('modalClose').click();
            this.$emit('refreshData', action, position)
        },
        sortPositionArray(){
            console.log('entry sortPositionArray')
            this.liveDataHeader.live_data_positions = this.liveDataHeader.live_data_positions.sort((a,b) => {
                if(a.position < b.position){
                    return -1
                }
                if (a.position > b.position){
                    return 1
                }
                return 0
            })
            this.updatPositionsBackend()
            console.log('exit sortPositionArray')
        },
        setNewPosition(){
            console.log('entry setNewPosition')
            this.currentComponent = "AddLiveDataPosition"
            this.popupOpen = true
            console.log('exit setNewPosition')
        },
        movePositionUp(position){
            console.log('entry movePositionUp')
            if(position.position < 2){
                return
            }
            let position_to_switch = this.liveDataHeader.live_data_positions.filter(array_position => array_position.position === position.position -1)

            position_to_switch.forEach((value,index) =>{
                value.position = position.position
            })
            position.position--
            this.sortPositionArray()
            console.log('exit movePositionUp')
        },
        movePositionDown(position){
            console.log('entry movePositionDown')
            if(position.position >= this.liveDataHeader.live_data_positions.length){
                return
            }
            let position_to_switch = this.liveDataHeader.live_data_positions.filter(array_position => array_position.position === position.position +1)

            position_to_switch.forEach((value,index) =>{
                value.position = position.position
            })
            position.position++
            this.sortPositionArray()
            console.log('exit movePositionDown')
        },
        setActivePosition(position){
            console.log('entry setActivePosition')
            this.activePosition = position
            console.log('exit setActivePosition')
        },
        updatPositionsBackend(){
            console.log('entry updatPositionsBackend')
            this.liveDataHeader.live_data_positions.forEach(element => {
                axios
                    .patch(`/api/v1/organisation_live_data_positions/${element.id}/`, element)
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        if (error.response) {
                            if ('detail' in error.response.data){
                                createToast({text: this.$t(error.response.data['detail'])
                                            ,type: 'error'})
                            }                        
                            console.error(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            console.error(JSON.stringify(error.message))
                        } else {
                            console.error(JSON.stringify(error))
                        }
                    })
                })
            
            console.log('exit updatPositionsBackend')
        }
    },
    computed:{
        currentProperties(){
                return {organisation: this.$store.state.organisation, 
                       liveDataHeader: this.liveDataHeader,
                       position: this.liveDataHeader.live_data_positions.length +1,   
                       activePosition: this.activePosition,
                       liveDataId: this.liveDataId
                    }
        },
    }
}
</script>