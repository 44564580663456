<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

function navigateToLogin() {
  router.push({ name: 'LogIn' });
}
</script>

<template>
  <div class="landing-page">
    <!-- Hero Section -->
    <header class="bg-primary text-white py-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <h1 class="display-4 fw-bold mb-4">Anlagen Monitor</h1>
            <p class="lead mb-4">
              Überwachen Sie Ihre Anlagen in Echtzeit, erhalten Sie wichtige Benachrichtigungen und optimieren Sie Ihre Prozesse.
            </p>
            <button class="btn btn-light btn-lg" @click="navigateToLogin">
              Jetzt anmelden
            </button>
          </div>
          <div class="col-lg-6 d-none d-lg-block">
            <!-- Interactive Dashboard Preview -->
            <div class="dashboard-preview p-3">
              <!-- Top Stats Cards -->
              <div class="row g-3 mb-4">
                <div class="col-6">
                  <div class="card bg-white bg-opacity-10 border-0">
                    <div class="card-body p-3">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="text-white-50 mb-0">Anlagen Status</h6>
                        <div class="status-badge bg-success px-2 py-1 rounded-pill">
                          <small>Online</small>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="text-center">
                          <div class="status-dot bg-success mb-1"></div>
                          <small class="d-block text-white-50">12</small>
                        </div>
                        <div class="text-center">
                          <div class="status-dot bg-warning mb-1"></div>
                          <small class="d-block text-white-50">2</small>
                        </div>
                        <div class="text-center">
                          <div class="status-dot bg-danger mb-1"></div>
                          <small class="d-block text-white-50">1</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card bg-white bg-opacity-10 border-0">
                    <div class="card-body p-3">
                      <h6 class="text-white-50 mb-2">Systemgesundheit</h6>
                      <div class="progress bg-white bg-opacity-25 mb-2" style="height: 8px;">
                        <div class="progress-bar bg-success progress-bar-animated progress-bar-striped" style="width: 85%"></div>
                      </div>
                      <div class="text-end">
                        <span class="badge bg-success">85%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Main Content Area -->
              <div class="card bg-white bg-opacity-10 border-0 mb-4">
                <div class="card-header bg-white bg-opacity-10 border-0 py-3">
                  <div class="d-flex justify-content-between align-items-center">
                    <h6 class="text-white mb-0">Live Messwerte</h6>
                    <div class="pulse-dot"></div>
                  </div>
                </div>
                <div class="card-body p-3">
                  <div class="live-data">
                    <div class="data-row">
                      <span class="text-white-50">Temperatur</span>
                      <span class="text-white value-animate">75.2 °C</span>
                    </div>
                    <div class="data-row">
                      <span class="text-white-50">Druck</span>
                      <span class="text-white value-animate">2.8 bar</span>
                    </div>
                    <div class="data-row">
                      <span class="text-white-50">Durchfluss</span>
                      <span class="text-white value-animate">120 l/min</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Notifications -->
              <div class="notification-stack">
                <div class="alert bg-danger bg-opacity-25 text-white border-0 mb-2 notification-slide">
                  <i class="bi bi-exclamation-circle-fill me-2"></i>
                  Kritische Temperatur in Anlage A-101
                </div>
                <div class="alert bg-warning bg-opacity-25 text-white border-0 mb-0 notification-slide">
                  <i class="bi bi-exclamation-triangle-fill me-2"></i>
                  Wartung für Anlage B-202 fällig
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

     <!-- Features Section -->
     <section class="py-5">
      <div class="container">
        <h2 class="text-center mb-5">Funktionen</h2>
        <div class="row g-4">
          <div class="col-md-3">
            <div class="card h-100 border-0 shadow-sm">
              <div class="card-body text-center p-4">
                <i class="bi bi-grid-3x3-gap text-primary display-4 mb-3"></i>
                <h3 class="h5 mb-3">Zentrale Verwaltung</h3>
                <p class="text-secondary mb-0">
                  Verwalten Sie Anlagen verschiedener Hersteller und Typen in einem einheitlichen Dashboard.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card h-100 border-0 shadow-sm">
              <div class="card-body text-center p-4">
                <i class="bi bi-graph-up text-primary display-4 mb-3"></i>
                <h3 class="h5">Echtzeit-Monitoring</h3>
                <p class="text-secondary">
                  Überwachen Sie alle wichtigen Kennzahlen Ihrer Anlagen in Echtzeit mit unserem intuitiven Dashboard.
                </p>
              </div>
            </div>
          </div>
          
          <div class="col-md-3">
            <div class="card h-100 border-0 shadow-sm">
              <div class="card-body text-center p-4">
                <i class="bi bi-bell text-primary display-4 mb-3"></i>
                <h3 class="h5">Intelligente Benachrichtigungen</h3>
                <p class="text-secondary">
                  Konfigurierbare Alarmcodes und Benachrichtigungsgruppen für schnelle Reaktionen auf kritische Ereignisse.
                </p>
              </div>
            </div>
          </div>
          
          <div class="col-md-3">
            <div class="card h-100 border-0 shadow-sm">
              <div class="card-body text-center p-4">
                <i class="bi bi-search text-primary display-4 mb-3"></i>
                <h3 class="h5">Datenrecherche</h3>
                <p class="text-secondary">
                  Leistungsstarke Suchfunktionen und Visualisierungen für tiefgehende Analysen Ihrer Anlagendaten.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Integration Section -->
    <section class="py-5 bg-light">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 mb-4 mb-lg-0">
            <h2 class="mb-4">Herstellerunabhängige Integration</h2>
            <p class="lead mb-4">
              Verbinden Sie Anlagen verschiedener Hersteller in einer einzigen Plattform. Unsere Lösung unterstützt alle gängigen Protokolle und Schnittstellen.
            </p>
            <div class="row g-4">
              <div class="col-6">
                <div class="d-flex align-items-center">
                  <i class="bi bi-check-circle-fill text-success me-2"></i>
                  <span>Standardprotokolle</span>
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex align-items-center">
                  <i class="bi bi-check-circle-fill text-success me-2"></i>
                  <span>FTP/sFTP</span>
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex align-items-center">
                  <i class="bi bi-check-circle-fill text-success me-2"></i>
                  <span>Modbus</span>
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex align-items-center">
                  <i class="bi bi-check-circle-fill text-success me-2"></i>
                  <span>REST APIs</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card border-0 shadow-sm">
              <div class="card-body p-4">
                <div class="row g-4">
                  <div class="col-3">
                    <div class="vendor-logo bg-white p-3 rounded text-center">
                      <i class="bi bi-building display-6 text-primary"></i>
                      <div class="small text-secondary mt-2">Huawei</div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="vendor-logo bg-white p-3 rounded text-center">
                      <i class="bi bi-building display-6 text-primary"></i>
                      <div class="small text-secondary mt-2">MITA</div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="vendor-logo bg-white p-3 rounded text-center">
                      <i class="bi bi-building display-6 text-primary"></i>
                      <div class="small text-secondary mt-2">Sunny</div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="vendor-logo bg-white p-3 rounded text-center">
                      <i class="bi bi-building display-6 text-primary"></i>
                      <div class="small text-secondary mt-2">Plexlog</div>
                    </div>
                  </div>
                </div>
                <div class="row g-4">
                  <div class="col-3">
                    <div class="vendor-logo bg-white p-3 rounded text-center">
                      <i class="bi bi-building display-6 text-primary"></i>
                      <div class="small text-secondary mt-2">Biogas Saya</div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="vendor-logo bg-white p-3 rounded text-center">
                      <i class="bi bi-building display-6 text-primary"></i>
                      <div class="small text-secondary mt-2">INTELISYS</div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="vendor-logo bg-white p-3 rounded text-center">
                      <i class="bi bi-building display-6 text-primary"></i>
                      <div class="small text-secondary mt-2">Novatech</div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="vendor-logo bg-white p-3 rounded text-center">
                      <i class="bi bi-building display-6 text-primary"></i>
                      <div class="small text-secondary mt-2">Kago</div>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-4">
                  <i class="bi bi-plus-circle text-primary"></i>
                  <p class="small text-secondary mt-2">Weitere Hersteller integrierbar</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Key Benefits -->
    <section class="bg-light py-5">
      <div class="container">
        <h2 class="text-center mb-5">Ihre Vorteile</h2>
        <div class="row g-4">
          <div class="col-md-6 col-lg-3">
            <div class="text-center">
              <i class="bi bi-lightning-charge text-primary display-4 mb-3"></i>
              <h3 class="h5">Schnelle Reaktion</h3>
              <p class="text-secondary">
                Sofortige Benachrichtigungen bei kritischen Zuständen ermöglichen schnelles Handeln.
              </p>
            </div>
          </div>
          
          <div class="col-md-6 col-lg-3">
            <div class="text-center">
              <i class="bi bi-people text-primary display-4 mb-3"></i>
              <h3 class="h5">Team-Koordination</h3>
              <p class="text-secondary">
                Flexible Meldegruppen für optimale Zusammenarbeit und klare Zuständigkeiten.
              </p>
            </div>
          </div>
          
          <div class="col-md-6 col-lg-3">
            <div class="text-center">
              <i class="bi bi-graph-up-arrow text-primary display-4 mb-3"></i>
              <h3 class="h5">Effizienzsteigerung</h3>
              <p class="text-secondary">
                Detaillierte KPIs und Analysen helfen bei der Optimierung Ihrer Anlagen.
              </p>
            </div>
          </div>
          
          <div class="col-md-6 col-lg-3">
            <div class="text-center">
              <i class="bi bi-shield-check text-primary display-4 mb-3"></i>
              <h3 class="h5">Zuverlässigkeit</h3>
              <p class="text-secondary">
                Kontinuierliche Überwachung und Systemstatus-Informationen für maximale Betriebssicherheit.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Statistics Section -->
    <section class="py-5 bg-light">
      <div class="container">
        <div class="row g-4 text-center">
          <div class="col-6 col-md-3">
            <div class="display-4 fw-bold text-primary mb-2">99.9%</div>
            <p class="text-secondary mb-0">Systemverfügbarkeit</p>
          </div>
          <div class="col-6 col-md-3">
            <div class="display-4 fw-bold text-primary mb-2">< 1m</div>
            <p class="text-secondary mb-0">Reaktionszeit</p>
          </div>
          <div class="col-6 col-md-3">
            <div class="display-4 fw-bold text-primary mb-2">300+</div>
            <p class="text-secondary mb-0">Aktive Anlagen</p>
          </div>
          <div class="col-6 col-md-3">
            <div class="display-4 fw-bold text-primary mb-2">24/7</div>
            <p class="text-secondary mb-0">Monitoring</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Feature Details -->
    <section class="py-5">
      <div class="container">
        <div class="row g-4 align-items-center">
          <div class="col-lg-6">
            <h2 class="mb-4">Umfassendes Monitoring</h2>
            <div class="mb-4">
              <h4 class="h6 text-primary">
                <i class="bi bi-speedometer2 me-2"></i>
                Echtzeit-Dashboard
              </h4>
              <p class="text-secondary">
                Übersichtliche Darstellung aller wichtigen Anlagenkennzahlen mit verschiedenen Ansichtsmodi.
              </p>
            </div>
            <div class="mb-4">
              <h4 class="h6 text-primary">
                <i class="bi bi-exclamation-triangle me-2"></i>
                Alarmcode-Management
              </h4>
              <p class="text-secondary">
                Konfigurierbare Alarmcodes mit verschiedenen Schweregraden und automatischen Benachrichtigungen.
              </p>
            </div>
            <div class="mb-4">
              <h4 class="h6 text-primary">
                <i class="bi bi-pc-display me-2"></i>
                Systemüberwachung
              </h4>
              <p class="text-secondary">
                Detaillierte Systemstatistiken und Ressourcenauslastung für optimale Performance.
              </p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card border-0 shadow-lg">
              <div class="card-body p-4">
                <div class="row g-3">
                  <div class="col-6">
                    <div class="p-3 bg-light rounded text-center">
                      <i class="bi bi-exclamation-circle text-danger display-6"></i>
                      <p class="small mt-2 mb-0">Kritische Zustände</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="p-3 bg-light rounded text-center">
                      <i class="bi bi-exclamation-triangle text-warning display-6"></i>
                      <p class="small mt-2 mb-0">Warnungen</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="p-3 bg-light rounded text-center">
                      <i class="bi bi-check-circle text-success display-6"></i>
                      <p class="small mt-2 mb-0">Normalbetrieb</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="p-3 bg-light rounded text-center">
                      <i class="bi bi-graph-up text-primary display-6"></i>
                      <p class="small mt-2 mb-0">Leistungsdaten</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    

    <!-- Use Cases Section -->
    <section class="py-5">
      <div class="container">
        <h2 class="text-center mb-5">Anwendungsbereiche</h2>
        <div class="row g-4">
          <div class="col-md-6 col-lg-3">
            <div class="card h-100 border-0 shadow-sm">
              <div class="card-body p-4">
                <div class="text-primary mb-3">
                  <i class="bi bi-water display-6"></i>
                </div>
                <h3 class="h5 mb-3">Wasseraufbereitung</h3>
                <ul class="list-unstyled text-secondary mb-0">
                  <li class="mb-2"><i class="bi bi-check2 me-2"></i>Durchflussmessung</li>
                  <li class="mb-2"><i class="bi bi-check2 me-2"></i>Drucküberwachung</li>
                  <li><i class="bi bi-check2 me-2"></i>Qualitätskontrolle</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="card h-100 border-0 shadow-sm">
              <div class="card-body p-4">
                <div class="text-primary mb-3">
                  <i class="bi bi-lightning-charge display-6"></i>
                </div>
                <h3 class="h5 mb-3">Energieerzeugung</h3>
                <ul class="list-unstyled text-secondary mb-0">
                  <li class="mb-2"><i class="bi bi-check2 me-2"></i>Leistungsmessung</li>
                  <li class="mb-2"><i class="bi bi-check2 me-2"></i>Temperaturüberwachung</li>
                  <li><i class="bi bi-check2 me-2"></i>Effizienzanalyse</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="card h-100 border-0 shadow-sm">
              <div class="card-body p-4">
                <div class="text-primary mb-3">
                  <i class="bi bi-building display-6"></i>
                </div>
                <h3 class="h5 mb-3">Gebäudetechnik</h3>
                <ul class="list-unstyled text-secondary mb-0">
                  <li class="mb-2"><i class="bi bi-check2 me-2"></i>Klimasteuerung</li>
                  <li class="mb-2"><i class="bi bi-check2 me-2"></i>Energieverbrauch</li>
                  <li><i class="bi bi-check2 me-2"></i>Wartungsplanung</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="card h-100 border-0 shadow-sm">
              <div class="card-body p-4">
                <div class="text-primary mb-3">
                  <i class="bi bi-gear-wide-connected display-6"></i>
                </div>
                <h3 class="h5 mb-3">Produktion</h3>
                <ul class="list-unstyled text-secondary mb-0">
                  <li class="mb-2"><i class="bi bi-check2 me-2"></i>Maschinenauslastung</li>
                  <li class="mb-2"><i class="bi bi-check2 me-2"></i>Qualitätssicherung</li>
                  <li><i class="bi bi-check2 me-2"></i>Prozessoptimierung</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Call to Action -->
    <section class="bg-primary text-white py-5">
      <div class="container text-center">
        <h2 class="mb-4">Bereit für effizientes Anlagenmonitoring?</h2>
        <p class="lead mb-4">
          Starten Sie jetzt mit unserem leistungsstarken Dashboard und optimieren Sie Ihre Anlagenüberwachung.
        </p>
        <button class="btn btn-light btn-lg" @click="navigateToLogin">
          <i class="bi bi-box-arrow-in-right me-2"></i>
          Zum Login
        </button>
      </div>
    </section>

    <!-- Footer -->
    <footer class="bg-dark text-white-50 py-4">
      <div class="container">
        <div class="row g-4">
          <div class="col-md-6">
            <h5 class="text-white mb-3">Anlagen Monitor</h5>
            <p class="small mb-0">
              Professionelles Monitoring-System für industrielle Anlagen mit Echtzeit-Überwachung, 
              intelligenten Benachrichtigungen und umfassenden Analysemöglichkeiten.
            </p>
          </div>
          <div class="col-md-6 text-md-end">
            <div class="d-flex justify-content-md-end gap-3">
              <button class="btn btn-outline-light btn-sm" @click="navigateToLogin">
                Login
              </button>
              <button class="btn btn-primary btn-sm" @click="navigateToLogin">
                Demo starten
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<style scoped>
/* Vendor Logo Styles */
.vendor-logo {
  transition: transform 0.3s ease;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem !important;
}

.vendor-logo:hover {
  transform: translateY(-5px);
}

.vendor-logo-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  filter: grayscale(100%);
  opacity: 0.7;
  transition: all 0.3s ease;
}

.vendor-logo:hover .vendor-logo-img {
  filter: grayscale(0%);
  opacity: 1;
}

.card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card:hover {
  transform: translateY(-5px);
}

/* Icon animations */
.bi {
  transition: transform 0.3s ease;
}

.card:hover .bi {
  transform: scale(1.1);
}

/* Custom spacing for sections */
section {
  position: relative;
  overflow: hidden;
}

/* Dashboard Preview Styles */
.dashboard-preview {
  position: relative;
  transform: perspective(1500px) rotateY(-10deg) rotateX(5deg);
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.2),
    0 5px 15px rgba(0, 0, 0, 0.1);
}

.dashboard-preview:hover {
  transform: perspective(1500px) rotateY(0deg) rotateX(0deg);
}

/* Status Indicators */
.status-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

.pulse-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #10b981;
  position: relative;
  animation: pulse 2s infinite;
}

.pulse-dot::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: #10b981;
  animation: pulse-ring 2s infinite;
}

/* Live Data Styles */
.data-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.data-row:last-child {
  border-bottom: none;
}

.value-animate {
  animation: value-pulse 2s infinite;
}

/* Notification Styles */
.notification-stack {
  position: relative;
}

.notification-slide {
  animation: slide-in 0.5s ease-out forwards;
}

/* Animations */
@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

@keyframes pulse-ring {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes value-pulse {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Progress Bar Animation */
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

/* Status Badge */
.status-badge {
  font-size: 0.75rem;
  letter-spacing: 0.5px;
}

/* Responsive adjustments */
@media (max-width: 991.98px) {
  .display-4 {
    font-size: 2.5rem;
  }
  
  .display-1 {
    font-size: 4rem;
  }
}

/* Footer styling */
footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

footer .btn-outline-light {
  border-color: rgba(255, 255, 255, 0.2);
}

footer .btn-outline-light:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
}
</style>
