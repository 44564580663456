<template>
    <div class="justify-content-center row my-3"
        v-for="data_description in this.component.component_data_description"
        v-bind:key="data_description.id">
                
        <div class="col-4 col-lg-3">
            <label for="inputDescCol" class="form-label" >{{ $t("desc_col") }}</label>
            <input disabled id="inputDescCol" type="text" name="inputDescCol" class="form-control"  v-model="data_description.col_description" >
        </div>
                
        <div class="col-2 col-xl-1">
            <label for="inputUnitCol" class="form-label" >{{ $t("unit_col") }}</label>   
            <input disabled id="inputDescCol" type="text" name="inputDescCol" class="form-control" v-model="data_description.col_unit" >
        </div>
        <div class="col-3 col-lg-2">
            <label for="inputCol" class="form-label" >{{ $t("col_limit_low") }}</label>
            <input :disabled="!this.canWritePlant" id="inputCol" type="text" name="inputDescCol" class="form-control"  v-model="data_description.col_limit_low" >
        </div>
        <div class="col-3 col-lg-2">
            <label for="inputCol" class="form-label" >{{ $t("col_limit_upper") }}</label>
            <input :disabled="!this.canWritePlant" id="inputCol" type="text" name="inputDescCol" class="form-control"  v-model="data_description.col_limit_upper" >
        </div>
    </div>
    <div class="row">
                
        <div class="col-12 text-end">
            <button :disabled="!this.canWritePlant" class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
export default {
    name: 'EditLimitsForComponent',
    props: {
        componentId: {
            type: Number,
            required: true,
        },
        canWritePlant: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    components: {
    },
    data() {
        return {    
            componentIdOld: 0,
            component: {}
        }
    },
    mounted(){
        this.getComponentDataFromBackend()
    },
    updated(){
        console.log('entry updated')
        if(this.componentIdOld === 0){
            this.componentIdOld = this.componentId
            
        }
        if(this.componentIdOld != this.componentId){
            this.getComponentDataFromBackend()
            this.componentIdOld = this.componentId
        }
        console.log('exit updated')
    },
    methods: {
        getComponentDataFromBackend(){
            console.log('entry getComponentDataFromBackend')
            axios
                .get(`/api/v1/components/${this.componentId}/`, )
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    this.component = response.data
                    this.manipulateComponentDataDescription()
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getComponentDataFromBackend')
        },
        manipulateComponentDataDescription(){
            console.log('entry manipulateComponentDataDescription')
            for (let i = 0; i < this.component.component_data_description.length; i++) {
                this.component.component_data_description[i].nr_pos = i
                this.component.component_data_description[i].uuid = uuidv4()
                
                delete this.component.component_data_description[i].component
            }
            console.log('exit manipulateComponentDataDescription')
        },
        submitForm() {
            for (let i = 0; i < this.component.component_data_description.length; i++) {
                
                delete this.component.component_data_description[i].component
            }
            axios
                .patch(`/api/v1/components/${this.component.id}/`, this.component)
                .then(response => {
                    
                    createToast({text: this.$t("limit altered")
                   ,type: 'success'})
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    }
    
}
</script>