<script setup>
import { ref, onMounted } from 'vue';
import apiClient from "@/services/apiClient";
const systemInfo = ref(null);
const loading = ref(true);
const error = ref(null);

async function fetchSystemInfo() {
  try {
    loading.value = true;
    error.value = null;

    // Real API call
    const response = await apiClient.get('/api/v1/actvity_log/get_system_info/');
    console.log('response', response)
    systemInfo.value = response.data
  } catch (err) {
    error.value = 'Fehler beim Laden der Systeminformationen';
    console.error(err);
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  fetchSystemInfo();
});

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
</script>

<template>
  <div class="container py-4">
    <div class="row g-4">
      <!-- Loading State -->
      <div v-if="loading" class="col-12 text-center py-5">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Laden...</span>
        </div>
        <p class="mt-2 text-secondary">Systeminformationen werden geladen...</p>
      </div>

      <!-- Error State -->
      <div v-else-if="error" class="col-12">
        <div class="alert alert-danger">
          {{ error }}
          <button class="btn btn-sm btn-outline-danger ms-3" @click="fetchSystemInfo">
            Erneut versuchen
          </button>
        </div>
      </div>

      <!-- System Information -->
      <template v-else-if="systemInfo">
        <!-- System Overview -->
        <div class="col-md-6">
          <div class="card h-100">
            <div class="card-header bg-white py-3">
              <h2 class="h5 mb-0">
                <i class="bi bi-pc-display me-2"></i>
                Systemübersicht
              </h2>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-sm">
                  <tbody>
                    <tr>
                      <td class="text-secondary" style="width: 150px;">Betriebssystem</td>
                      <td class="fw-medium">{{ systemInfo.system_info.system }}</td>
                    </tr>
                    <tr>
                      <td class="text-secondary">Version</td>
                      <td class="fw-medium">{{ systemInfo.system_info.version }}</td>
                    </tr>
                    <tr>
                      <td class="text-secondary">Hostname</td>
                      <td class="fw-medium">{{ systemInfo.system_info.node_name }}</td>
                    </tr>
                    <tr>
                      <td class="text-secondary">Prozessor</td>
                      <td class="fw-medium">{{ systemInfo.system_info.cpu_brand }}</td>
                    </tr>
                    <tr>
                      <td class="text-secondary">IP-Adresse</td>
                      <td class="fw-medium">{{ systemInfo.system_info.ip_address }}</td>
                    </tr>
                    <tr>
                      <td class="text-secondary">MAC-Adresse</td>
                      <td class="fw-medium">{{ systemInfo.system_info.mac_address }}</td>
                    </tr>
                    <tr>
                      <td class="text-secondary">Systemstart</td>
                      <td class="fw-medium">{{ systemInfo.boot_info.boot_time }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- CPU Information -->
        <div class="col-md-6">
          <div class="card h-100">
            <div class="card-header bg-white py-3">
              <h2 class="h5 mb-0">
                <i class="bi bi-cpu me-2"></i>
                CPU-Auslastung
              </h2>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-6">
                  <div class="text-secondary small">Physische Kerne</div>
                  <div class="h4 mb-0">{{ systemInfo.cpu_info.physical_cores }}</div>
                </div>
                <div class="col-6">
                  <div class="text-secondary small">Logische Kerne</div>
                  <div class="h4 mb-0">{{ systemInfo.cpu_info.total_cores }}</div>
                </div>
              </div>

              <div class="mb-4">
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <span class="text-secondary small">Gesamtauslastung</span>
                  <span class="badge bg-primary">{{ systemInfo.cpu_info.total_cpu_usage }}</span>
                </div>
                <div class="progress" style="height: 8px;">
                  <div 
                    class="progress-bar" 
                    role="progressbar" 
                    :style="{ width: systemInfo.cpu_info.total_cpu_usage }"
                  ></div>
                </div>
              </div>

              <div class="mb-3">
                <div class="text-secondary small mb-2">Kern-Auslastung</div>
                <div class="row g-2">
                  <div 
                    v-for="(core, index) in systemInfo.cpu_info.core_usage" 
                    :key="index"
                    class="col-6"
                  >
                    <div class="card bg-light">
                      <div class="card-body p-2">
                        <div class="d-flex justify-content-between align-items-center small">
                          <span>Kern {{ index }}</span>
                          <span class="badge bg-primary">{{ core.split(': ')[1] }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-secondary small">
                Aktuelle Frequenz: {{ systemInfo.cpu_info.current_frequency }}
              </div>
            </div>
          </div>
        </div>

        <!-- Memory Information -->
        <div class="col-md-6">
          <div class="card h-100">
            <div class="card-header bg-white py-3">
              <h2 class="h5 mb-0">
                <i class="bi bi-memory me-2"></i>
                Arbeitsspeicher
              </h2>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-4">
                  <div class="text-secondary small">Gesamt</div>
                  <div class="h5 mb-0">{{ systemInfo.memory_info.total }}</div>
                </div>
                <div class="col-4">
                  <div class="text-secondary small">Verwendet</div>
                  <div class="h5 mb-0">{{ systemInfo.memory_info.used }}</div>
                </div>
                <div class="col-4">
                  <div class="text-secondary small">Verfügbar</div>
                  <div class="h5 mb-0">{{ systemInfo.memory_info.available }}</div>
                </div>
              </div>

              <div class="mb-4">
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <span class="text-secondary small">Auslastung</span>
                  <span class="badge bg-primary">{{ systemInfo.memory_info.percentage }}</span>
                </div>
                <div class="progress" style="height: 8px;">
                  <div 
                    class="progress-bar" 
                    role="progressbar" 
                    :style="{ width: systemInfo.memory_info.percentage }"
                  ></div>
                </div>
              </div>

              <div class="card bg-light">
                <div class="card-body">
                  <h6 class="card-title h6 mb-3">Swap-Speicher</h6>
                  <div class="row">
                    <div class="col-4">
                      <div class="text-secondary small">Gesamt</div>
                      <div>{{ systemInfo.swap_info.total }}</div>
                    </div>
                    <div class="col-4">
                      <div class="text-secondary small">Verwendet</div>
                      <div>{{ systemInfo.swap_info.used }}</div>
                    </div>
                    <div class="col-4">
                      <div class="text-secondary small">Frei</div>
                      <div>{{ systemInfo.swap_info.free }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Disk Information -->
        <div class="col-md-6">
          <div class="card h-100">
            <div class="card-header bg-white py-3">
              <h2 class="h5 mb-0">
                <i class="bi bi-hdd me-2"></i>
                Festplatten
              </h2>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th>Mount</th>
                      <th>Größe</th>
                      <th>Verwendet</th>
                      <th>Frei</th>
                      <th>Auslastung</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="partition in systemInfo.disk_info.partitions" :key="partition.device">
                      <td>
                        <div class="fw-medium">{{ partition.mountpoint }}</div>
                        <div class="small text-secondary">{{ partition.device }}</div>
                      </td>
                      <td>{{ partition.total_size }}</td>
                      <td>{{ partition.used }}</td>
                      <td>{{ partition.free }}</td>
                      <td>
                        <div class="progress" style="height: 6px; width: 60px;">
                          <div 
                            class="progress-bar" 
                            role="progressbar" 
                            :style="{ width: partition.percentage }"
                          ></div>
                        </div>
                        <div class="small text-secondary mt-1">{{ partition.percentage }}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="mt-3 pt-3 border-top">
                <div class="row">
                  <div class="col-6">
                    <div class="text-secondary small">Gesamt gelesen</div>
                    <div class="fw-medium">{{ systemInfo.disk_info.io_stats.total_read }}</div>
                  </div>
                  <div class="col-6">
                    <div class="text-secondary small">Gesamt geschrieben</div>
                    <div class="fw-medium">{{ systemInfo.disk_info.io_stats.total_write }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
.progress {
  background-color: #e9ecef;
}

.card {
  transition: transform 0.2s ease;
}

.card:hover {
  transform: translateY(-3px);
}

.table th {
  font-weight: 500;
  color: #6c757d;
  border-top: none;
}

.table td {
  vertical-align: middle;
}
</style>