<template>
    <div class="loading-block" v-if="!this.loadingDone"></div>
    <div class="row justify-content-evenly mt-3">        
        <div class="col-12 col-md-3" v-for="statusPlants in this.statusGroupedPlants"
                    v-bind:key="statusPlants.status">
        
                <div class="card shadow-sm my-2 " :class="'plant-status-'+getMaxPlantStatus(plant)" v-for="plant in statusPlants.plants"
                    v-bind:key="plant.id" @click="openInfoWindow(plant)">
                    <div class="card-body">
                        <div class="fw-bold text-center">
                            <div class="fw-bold">
                                {{plant.name}} 
                            </div>
                            <div class="fw-light" v-if="plant.system">
                                <img :src="require('@/assets/icons/'+ plant.type + '.png')"/>
                                {{ plant.system_bez }}
                                <img :src="require('@/assets/icons/'+ plant.type + '.png')"/>
                            </div>
                            <div class="fw-light" v-if="$store.state.me.is_superuser && plant.organisation != $store.state.me.organisation">
                                <i class="fa-solid fa-people-group"></i>
                                {{ plant.organisation_bez }}
                                <i class="fa-solid fa-people-group"></i>
                            </div>
                        </div>
                        <hr>
                        <div v-if="plant.time_last_data">
                            <div class="row">
                                <div class="col-6">
                                    {{ $t('time_last_data')}}
                                </div>
                                <div class="col-6 text-end">
                                    {{this.getTimeFormatted(plant.time_last_data)}}
                                </div>
                            </div>
                            <hr v-if="plant.plant_prio_values.length > 0">
                            <div class="row" v-for="prio_value in plant.plant_prio_values"
                                v-bind:key="prio_value.id" v-tooltip :title="this.getTimeFormatted(prio_value.created_at)">
                                <div class="col-6">
                                    {{prio_value.name}}
                                </div>
                                <div class="col-6 text-end">
                                    {{prio_value.value}} <span class="small">{{ prio_value.unit }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="row">
                                <div class="col-12">
                                    {{ $t('plant_no_data')}}
                                </div>
                                
                            </div>
                        </div>
                        <hr>
                        <div v-if="plant.last_alarm">
                            <div class="row">
                                <div class="col-6">
                                    {{ $t('time_last_alarm')}}
                                </div>
                                <div class="col-6 text-end">
                                    {{this.getTimeFormatted(plant.time_last_alarm)}}<br>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col">
                                    {{plant.last_alarm}}
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="row">
                                <div class="col-12">
                                    {{ $t('plant_no_alarm')}}
                                </div>
                            </div>
                           
                        </div>
                        <div v-if="plant.last_data_warn">
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    {{ $t(plant.last_data_warn)}}
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div v-if="plant.evu">
                            <div class="row">
                                <div class="col-6">
                                    {{ $t('evu')}}
                                </div>
                                <div class="col-6 text-end">
                                    {{plant.evu}}
                                </div>
                            </div>
                        </div>
                        
                        <hr v-if="plant.evu">
                    </div>
                </div>
        </div>
        
        
    </div>

    <button id="modalOpen" style="display:none;" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modal" aria-hidden="true">hidden</button>
    <button id="modalOpenChangeESN" style="display:none;" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#changeESNPlant" aria-hidden="true">hidden</button>
    <button id="modal2Close" style="display:none;" class="btn btn-sm btn-primary" data-bs-dismiss="modal" data-bs-target="#changeStatusPlant" aria-hidden="true">hidden</button>
    <button id="modal3Close" style="display:none;" class="btn btn-sm btn-primary" data-bs-dismiss="modal" data-bs-target="#changeESNPlant" aria-hidden="true">hidden</button>
    
    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-xxl">
        <div class="modal-content ">
            <div class="modal-header">
                <h5 class="modal-title" id="modalLabel">{{currentPlant.name}}</h5>
                
                <button type="button" id="modalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetComponent()"></button>
            </div>
            <div class="modal-body modal-textarea">
                <template v-if="currentOverviewComponent != ''">
                    <component ref="overview" :is="currentOverviewComponent" :id='"test"' v-bind="currentProperties" v-on:closeModal="closeModal" v-on:updateTime="updateTime"></component>
                </template>
            </div>
            <div class="modal-footer justify-content-between">
                <p class="small">{{ $t("updatedAt") }} {{time}}</p>
                <div class="footer-buttons justify-content-between">
                    <button type="button" class="btn btn-outline-secondary" @click="generatePdf('test')"><i class="fa-solid fa-file-pdf"></i> {{ $t("export_pdf") }}</button>
                    <button type="button" class="btn btn-outline-secondary" @click="generateExcel()"><i class="fa-solid fa-file-excel"></i> {{ $t("export_excel") }}</button>
                    <button type="button" class="btn btn-outline-secondary" data-bs-target="#changeStatusPlant" data-bs-toggle="modal">{{ $t("change_status") }}</button>
                    <button type="button" class="btn btn-outline-secondary" @click="openChangeESNModal()">{{ $t("change_esn") }}</button>

                </div>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="resetComponent()">{{ $t("close") }}</button>
            </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="changeStatusPlant" aria-hidden="true" aria-labelledby="changeStatusPlant" tabindex="-1">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="changeStatusPlant">{{currentPlant.name}} - {{ $t("change_status") }}</h5>
                    <button type="button" class="btn-close" data-bs-target="#modal" data-bs-toggle="modal"></button>
                </div>
                <div class="modal-body">
                    <ManualAlarmForPlant v-bind="currentManualAlarmForPlantProperties" v-on:closeManualAlarmForPlant="closeManualAlarmForPlant"/>
                </div>
                <div class="modal-footer">
                    <button id="closeModalChangeStatusPlant" class="btn btn-secondary" data-bs-target="#modal" data-bs-toggle="modal">{{ $t("back") }}</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="changeESNPlant" aria-hidden="true" aria-labelledby="changeESNPlant" tabindex="-1">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="changeESNPlant">{{currentPlant.name}} - {{ $t("change_esn") }}</h5>
                    <button type="button" class="btn-close" data-bs-target="#modal" data-bs-toggle="modal"></button>
                </div>
                <div class="modal-body" v-if="changeESNOpen">
                    <ManualChangeESNForPlant v-bind="currentManualChangeESNForPlantProperties" v-on:closeManualChangeESNForPlant="closeManualChangeESNForPlant"/>
                </div>
                <div class="modal-footer">
                    <button id="closeModalChangeESNPlant" class="btn btn-secondary" data-bs-target="#modal" data-bs-toggle="modal" @click="closeModalChangeESN()">{{ $t("back") }}</button>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import { jsPDF } from "jspdf";
import apiClient from "@/services/apiClient";
import BiogasOverview from '@/components/dashboard/BiogasOverview.vue'
import DashboardRowView from '@/components/dashboard/DashboardRowView.vue'
import ManualAlarmForPlant from '@/components/Alarmmessages/ManualAlarmForPlant.vue'

import ManualChangeESNForPlant from '@/components/dashboard/ManualChangeESNForPlant.vue'
import axios from 'axios'
export default {
    name: 'TileDashboard',
    emits: [],
    props: {
        plants: {
            type: Object,
            required: true,
        },
        
    },
    components: {
        BiogasOverview,
        DashboardRowView,
        ManualAlarmForPlant,
        ManualChangeESNForPlant
    },
    data() {
        return {
            loadingDone: true,
            currentPlant: {},
            time: '',
            currentOverviewComponent: '',
            polling: null,
            infoWindowOpen: false,
            changeESNOpen: false
        }
    },
    mounted(){
        console.log('entry mounted')
        this.pollData()
        console.log('exit mounted')
    },
    unmounted(){
          console.log('component unmounted')
          
          clearInterval(this.polling)
    },
    methods: {
        closeManualAlarmForPlant(){
            console.log('entry closeManualAlarmForPlant')
            this.getPlants()
            document.getElementById('modal2Close').click();
            this.resetComponent()
            console.log('exit closeManualAlarmForPlant')
        },
        closeModalChangeESN(){
            console.log('entry closeModalChangeESN')
            this.changeESNOpen = false
            console.log('exit closeModalChangeESN')
        },
        closeManualChangeESNForPlant(){
            console.log('entry closeManualChangeESNForPlant')
            this.getPlants()
            document.getElementById('modal3Close').click();
            this.changeESNOpen = false
            this.resetComponent()
            console.log('exit closeManualChangeESNForPlant')
        },
        generateExcel(){
            console.log('entry generateExcel')
            this.$refs.overview.getDataForPlantExcel()
            console.log('exit generateExcel')
        },
        generatePdf(){
            console.log('entry generatePDF')
            this.$refs.overview.getDataForPlantPdf()
            console.log('exit genetarePdf')
        },
        getMaxPlantStatus(plant){
            return Math.max(plant.status_anlage, plant.status_data)
        },
        resetComponent(){
            console.log('entry resetComponent')
            this.currentOverviewComponent = ''
            this.infoWindowOpen = false
            console.log('exit resetComponent')
        },
        pollData () {
              
            this.polling = setInterval(() => {
                console.log('timer hat gefeuert. erstmal schlafen')
                setTimeout(() => {
                    if(!this.infoWindowOpen){
                        this.getPlants()
                    }
                    
                }, 2000)
            }, process.env.VUE_APP_REFRESH_INTERVALL_OVERVIEW)

          },
        async getPlants(){
            this.loadingDone = false
            try {
                const response_dashboard =  await apiClient.get("/api/v1/dashboard/")
                console.log('plants loaded')
                console.log(response_dashboard.data)
                this.$store.commit('setPlants', response_dashboard.data)
                this.loadingDone = true
            }catch (error) {
                console.info("Fehler beim Laden von dashboard");

                console.log(JSON.stringify(error))
            } 
        },
        getTimeFormatted(time){
            if(time){
                let updatedTime = new Date(time)
                return updatedTime.toLocaleString()

            }
            return ''
        },
        updateTime(time){
                this.time = time
            },
        closeModal(){
                console.log("closeModal")
                document.getElementById('modalClose').click();
                this.resetComponent()
                
            },
        openInfoWindow(plant){
            console.log('entry openInfoWindow')
            console.log('plant: ')
            console.log(plant)
            this.currentPlant = plant

            if(this.currentPlant.dashboard_type == 'table')
            {
                this.currentOverviewComponent = 'BiogasOverview'
            }else if(this.currentPlant.dashboard_type == 'row'){
                this.currentOverviewComponent = 'DashboardRowView'
            }
            this.infoWindowOpen = true
            document.getElementById('modalOpen').click();
            console.log('exit openInfoWindow')
        },
        openChangeESNModal(){
            console.log('entry openChangeESNModal')
            this.changeESNOpen = true
            document.getElementById('modalOpenChangeESN').click();
            console.log('exit openChangeESNModal')
        }
    },
    computed:{
        currentProperties(){
            return {plant: this.currentPlant}
        },
        currentManualAlarmForPlantProperties(){
            return {plant: this.currentPlant}
        },
        currentManualChangeESNForPlantProperties(){
            return {plant: this.currentPlant}
        },
        statusGroupedPlants(){
            var statusSortedPlants = this.plants.sort((a,b) => {
                if(Math.max(a.status_anlage, a.status_data) < Math.max(b.status_anlage, b.status_data)){
                    return 1
                }
                if (Math.max(a.status_data, a.status_anlage) > Math.max(b.status_data, b.status_anlage)){
                    return -1
                }
                return 0
            })

            var groupedArray = []
            var status = 'a'
            var plantArray = []
            var plantArrayObject = {}
            statusSortedPlants.forEach(plant =>{
                if(status === 'a'){
                    status = Math.max(plant.status_anlage, plant.status_data)
                }
                if(status !== Math.max(plant.status_anlage, plant.status_data)){
                    plantArrayObject.status = status
                    plantArrayObject.plants = plantArray
                    groupedArray.push(plantArrayObject)
                    plantArray = []
                    plantArrayObject = {}
                    status = Math.max(plant.status_anlage, plant.status_data)
                }
                plantArray.push(plant)
            })
            plantArrayObject.status = status
            plantArrayObject.plants = plantArray
            groupedArray.push(plantArrayObject)

            console.log('groupedArray')
            console.log(groupedArray)

            return groupedArray
        }
    }
}
</script>