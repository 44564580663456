<template>
    <div class="card shadow-sm">
        <div class="card-header card-header-space-between">
            <p class="text-center h5">{{ $t("currentselection") }}</p>
            <div class="text-end mb-2">
                {{ allCheckedPlantComponentDescriptions.length }} / {{ maxDescriptions }}   
            </div>
        </div>
        <div class="card-body">
            <div class="row my-3"
                v-for="element in allCheckedPlantComponentDescriptions"
                            v-bind:key="element.description_id"
            >
                <div class="col-12">
                    
                    <button class="btn btn-sm btn-secondary position-relative"
                        @click="removeElement(element)"
                    >
                        {{ element.plant_name }} | {{ element.component_name }} | {{ element.description_name }}
                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            x
                            <span class="visually-hidden">unread messages</span>
                        </span>

        
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'ListAllChoosenData',
    emits: [
        'removeDescription'
    ],
    components: {
    },
    props: {

        allCheckedPlantComponentDescriptions: {
            type: Array,
            required: true
        },
        maxDescriptions: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
        }
    },
    mounted(){
    },
    updated(){
        console.log('entry updated')

        console.log('exit updated')
    },
    methods: {
        removeElement(element){
            console.log('entry removeElement')
            console.log(element)
            this.$emit('removeDescription', element)
            console.log('exit removeElement')
        }
       

    },
    computed:{
        
    }
}
</script>
