<template>
    <ul class="mt-3">
        <li :set="permission_live_data = getPermissionsForDialog('live_data')">
            <div class="row">
                <div class="col-2">
                    {{$t(permission_live_data.dialog)}}
                </div>
                <div class="col-2">
                    <div class="form-check">
                        <input class="form-check-input" @click="setCanRead(permission_live_data)" :disabled="!this.canWritePermissions" type="checkbox" :value="permission_live_data.can_read" :id="permission_live_data.dialog + 'can_read'" v-model="permission_live_data.can_read">
                        <label class="form-check-label" :for="permission_live_data.dialog + 'can_read'" >
                            {{ $t("can_read")}}
                        </label>
                    </div>
                </div>
            </div>
        </li>
        <li>
            {{ $t("analyse")}}
            <ul>
                <li :set="permission_analyse_plant = getPermissionsForDialog('analyse_plant')">
                    <div class="row">
                        <div class="col-2">
                            {{$t(permission_analyse_plant.dialog)}}
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" @click="setCanRead(permission_analyse_plant)" :disabled="!this.canWritePermissions" type="checkbox" :value="permission_analyse_plant.can_read" :id="permission_analyse_plant.dialog + 'can_read'" v-model="permission_analyse_plant.can_read">
                                <label class="form-check-label" :for="permission_analyse_plant.dialog + 'can_read'" >
                                    {{ $t("can_read")}}
                                </label>
                            </div>
                        </div>
                    </div>
                </li>
                <li :set="permission_alarmmessages_plant = getPermissionsForDialog('alarmmessages_plant')">
                    <div class="row">
                        <div class="col-2">
                            {{$t(permission_alarmmessages_plant.dialog)}}
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" @click="setCanRead(permission_alarmmessages_plant)" :disabled="!this.canWritePermissions" type="checkbox" :value="permission_alarmmessages_plant.can_read" :id="permission_alarmmessages_plant.dialog + 'can_read'" v-model="permission_alarmmessages_plant.can_read">
                                <label class="form-check-label" :for="permission_alarmmessages_plant.dialog + 'can_read'">
                                    {{ $t("can_read")}}
                                </label>
                            </div>
                        </div>
                    </div>
                </li>
                <li :set="permission_daily_report = getPermissionsForDialog('organisation_daily_report')">
                    <div class="row">
                        <div class="col-2">
                            {{$t(permission_daily_report.dialog)}}
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" @click="setCanRead(permission_daily_report)" :disabled="!this.canWritePermissions" type="checkbox" :value="permission_daily_report.can_read" :id="permission_daily_report.dialog + 'can_read'" v-model="permission_daily_report.can_read">
                                <label class="form-check-label" :for="permission_daily_report.dialog + 'can_read'">
                                    {{ $t("can_read")}}
                                </label>
                            </div>
                        </div>
                    </div>
                </li>
                
            </ul>
        </li>
        <li>
            {{ $t("settings") }}
            <ul>
                <li :set="permission_organisation = getPermissionsForDialog('organisation')">
                    <div class="row">
                        <div class="col-2">
                            {{$t(permission_organisation.dialog)}}
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" @click="setCanRead(permission_organisation)" :disabled="!this.canWritePermissions" type="checkbox" :value="permission_organisation.can_read" :id="permission_organisation.dialog + 'can_read'" v-model="permission_organisation.can_read">
                                <label class="form-check-label" :for="permission_organisation.dialog + 'can_read'" >
                                    {{ $t("can_read")}}
                                </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" :disabled="!this.canWritePermissions || !permission_organisation.can_read" type="checkbox" :value="permission_organisation.can_write" :id="permission_organisation.dialog + 'can_write'" v-model="permission_organisation.can_write">
                                <label class="form-check-label" :for="permission_organisation.dialog + 'can_write'" >
                                    {{ $t("can_write")}}
                                </label>
                            </div>
                        </div>
                    </div>
                </li>
                <li :set="permission_live_data_config = getPermissionsForDialog('live_data_config')">
                    <div class="row">
                        <div class="col-2">
                            {{$t(permission_live_data_config.dialog)}}
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" @click="setCanRead(permission_live_data_config)" :disabled="!this.canWritePermissions" type="checkbox" :value="permission_live_data_config.can_read" :id="permission_live_data_config.dialog + 'can_read'" v-model="permission_live_data_config.can_read">
                                <label class="form-check-label" :for="permission_live_data_config.dialog + 'can_read'" >
                                    {{ $t("can_read")}}
                                </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" :disabled="!this.canWritePermissions || !permission_live_data_config.can_read" type="checkbox" :value="permission_live_data_config.can_write" :id="permission_live_data_config.dialog + 'can_write'" v-model="permission_live_data_config.can_write">
                                <label class="form-check-label" :for="permission_live_data_config.dialog + 'can_write'" >
                                    {{ $t("can_write")}}
                                </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" :disabled="!this.canWritePermissions || !permission_live_data_config.can_read" type="checkbox" :value="permission_live_data_config.can_create" :id="permission_live_data_config.id + 'can_create'" v-model="permission_live_data_config.can_create">
                                <label class="form-check-label" :for="permission_live_data_config.id + 'can_create'" >
                                    {{ $t("can_create")}}
                                </label>
                            </div>
                        </div>
                    </div>
                </li>
                <li :set="permission_plant = getPermissionsForDialog('plant')">
                    <div class="row">
                        <div class="col-2">
                            {{$t(permission_plant.dialog)}}
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" @click="setCanRead(permission_plant)" :disabled="!this.canWritePermissions" type="checkbox" :value="permission_plant.can_read" :id="permission_plant.dialog + 'can_read'" v-model="permission_plant.can_read">
                                <label class="form-check-label" :for="permission_plant.dialog + 'can_read'" >
                                    {{ $t("can_read")}}
                                </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" :disabled="!this.canWritePermissions || !permission_plant.can_read" type="checkbox" :value="permission_plant.can_write" :id="permission_plant.dialog + 'can_write'" v-model="permission_plant.can_write">
                                <label class="form-check-label" :for="permission_plant.dialog + 'can_write'" >
                                    {{ $t("can_write")}}
                                </label>
                            </div>
                        </div>
                        <div class="col-2">

                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" :disabled="!this.canWritePermissions || !permission_plant.can_read" type="checkbox" :value="permission_plant.can_edit_user_mapping" :id="permission_plant.dialog + 'can_edit_user_mapping'" v-model="permission_plant.can_edit_user_mapping">
                                <label class="form-check-label" :for="permission_plant.dialog + 'can_edit_user_mapping'" >
                                    {{ $t("can_edit_user_mapping")}}
                                </label>
                            </div>
                        </div>
                    </div>
                </li>
                <li :set="permission_limits = getPermissionsForDialog('limits')">
                    <div class="row">
                        <div class="col-2">
                            {{$t(permission_limits.dialog)}}
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" @click="setCanRead(permission_limits)" :disabled="!this.canWritePermissions" type="checkbox" :value="permission_limits.can_read" :id="permission_limits.dialog + 'can_read'" v-model="permission_limits.can_read">
                                <label class="form-check-label" :for="permission_limits.dialog + 'can_read'" >
                                    {{ $t("can_read")}}
                                </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" :disabled="!this.canWritePermissions || !permission_limits.can_read" type="checkbox" :value="permission_limits.can_write" :id="permission_limits.dialog + 'can_write'" v-model="permission_limits.can_write">
                                <label class="form-check-label" :for="permission_limits.dialog + 'can_write'" >
                                    {{ $t("can_write")}}
                                </label>
                            </div>
                        </div>
                    </div>
                </li>
                <li :set="permission_user_group = getPermissionsForDialog('user_group')">
                    <div class="row">
                        <div class="col-2">
                            {{$t(permission_user_group.dialog)}}
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" @click="setCanRead(permission_user_group)" :disabled="!this.canWritePermissions" type="checkbox" :value="permission_user_group.can_read" :id="permission_user_group.dialog + 'can_read'" v-model="permission_user_group.can_read">
                                <label class="form-check-label" :for="permission_user_group.dialog + 'can_read'">
                                    {{ $t("can_read")}}
                                </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" :disabled="!this.canWritePermissions || !permission_user_group.can_read" type="checkbox" :value="permission_user_group.can_write" :id="permission_user_group.dialog + 'can_write'" v-model="permission_user_group.can_write">
                                <label class="form-check-label" :for="permission_user_group.dialog + 'can_write'" >
                                    {{ $t("can_write")}}
                                </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" :disabled="!this.canWritePermissions || !permission_user_group.can_read" type="checkbox" :value="permission_user_group.can_create" :id="permission_user_group.id + 'can_create'" v-model="permission_user_group.can_create">
                                <label class="form-check-label" :for="permission_user_group.id + 'can_create'" >
                                    {{ $t("can_create")}}
                                </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" :disabled="!this.canWritePermissions || !permission_user_group.can_read" type="checkbox" :value="permission_user_group.can_edit_user_mapping" :id="permission_user_group.dialog + 'can_edit_user_mapping'" v-model="permission_user_group.can_edit_user_mapping">
                                <label class="form-check-label" :for="permission_user_group.dialog + 'can_edit_user_mapping'" >
                                    {{ $t("can_edit_user_mapping")}}
                                </label>
                            </div>
                        </div>
                    </div>
                </li>
                <li :set="permission_permission = getPermissionsForDialog('permission')">
                    <div class="row">
                        <div class="col-2">
                            {{$t(permission_permission.dialog)}}
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" @click="setCanRead(permission_permission)" :disabled="!this.canWritePermissions || this.authGroup.grp_name === 'Orga-Manager'" type="checkbox" :value="permission_permission.can_read" :id="permission_permission.dialog + 'can_read'" v-model="permission_permission.can_read">
                                <label class="form-check-label" :for="permission_permission.dialog + 'can_read'" >
                                    {{ $t("can_read")}}
                                </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" :disabled="!this.canWritePermissions || !permission_permission.can_read || this.authGroup.grp_name === 'Orga-Manager'" type="checkbox" :value="permission_permission.can_write" :id="permission_permission.dialog + 'can_write'" v-model="permission_permission.can_write">
                                <label class="form-check-label" :for="permission_permission.dialog + 'can_write'" >
                                    {{ $t("can_write")}}
                                </label>
                            </div>
                        </div>
                    </div>
                </li>
                <li :set="permission_alarmcodes = getPermissionsForDialog('alarmcodes')">
                    <div class="row">
                        <div class="col-2">
                            {{$t(permission_alarmcodes.dialog)}}
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" @click="setCanRead(permission_alarmcodes)" :disabled="!this.canWritePermissions" type="checkbox" :value="permission_alarmcodes.can_read" :id="permission_alarmcodes.dialog + 'can_read'" v-model="permission_alarmcodes.can_read">
                                <label class="form-check-label" :for="permission_alarmcodes.dialog + 'can_read'" >
                                    {{ $t("can_read")}}
                                </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" :disabled="!this.canWritePermissions || !permission_alarmcodes.can_read" type="checkbox" :value="permission_alarmcodes.can_write" :id="permission_alarmcodes.dialog + 'can_write'" v-model="permission_alarmcodes.can_write">
                                <label class="form-check-label" :for="permission_alarmcodes.dialog + 'can_write'" >
                                    {{ $t("can_write")}}
                                </label>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </li>
    </ul>
    <div class="text-end">
        <button class="btn btn-success mb-0 ms-auto" :disabled="!this.canWritePermissions" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'PermissionsForAuthGroup',
    emits: [
    ],
    components: {
    },
    props: {
        authGroup: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            
        }
    },
    mounted(){
        
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
        
    },
    methods: {
        setCanRead(permission){
            console.log('entry setCanRead')
            console.log(permission.can_read)
            if(permission.can_read){
                console.log('switching from true to false')
                permission.can_write = false
                permission.can_edit_user_mapping = false
                permission.can_create = false
            }
            console.log('exit setCanRead')
        },
        submitForm() {
            
            console.log('entry submitForm')
            axios
                .patch(`/api/v1/allAuthgroup/${this.authGroup.id}/`, this.authGroup)
                .then(response => {
                    createToast({text: this.$t("authGroup_altered")
                    ,type: 'success'})
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit submitForm')
        },
        getPermissionsForDialog(dialog){
            console.log('entry getPermissionsForDialog: ' + dialog)
            var dialog_permissions = this.authGroup.permissions.filter(permission => permission.dialog === dialog)
            var return_value = ""

            if(dialog_permissions.length > 0){
                return_value = dialog_permissions[0]
            }
            console.log('exit getPermissionsForDialog')
            return return_value
        }
       
    },
    computed:{
        canWritePermissions(){
            return this.$store.getters.userHasPermission('permission', 'can_write')
        }
    }
}
</script>