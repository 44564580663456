<template>
    <ul class="nav nav-sidebar flex-column ms-2">
        <li class="nav-item" v-if="this.canCreatePlant">
            <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#modalPlant" @click="newPlant()"><i class="fa-solid fa-plus" ></i> {{ $t("new_plant") }}</a>
        </li>
        <li class="nav-item" v-for="plant in this.plants"
            v-bind:key="plant.id">
            <a class="nav-link nav-toggle collapsed" :class="{'active': this.currentPlant.name === plant.name}" href="#" 
                data-bs-toggle="collapse" :data-bs-target="'#'+plant.name.replaceAll(' ', '_').replaceAll('.', '_').replaceAll('&', '_').replaceAll('/', '_').replaceAll('(', '_').replaceAll(')', '_')" aria-expanded="true"
                @click="setPlant(plant)">
                {{ plant.name }} <template v-if="plant.system_bez"><small>({{ plant.system_bez }})</small></template>
                <i class="fa-solid fa-pencil" v-if="this.canWritePlant" @click="editPlant(plant)" data-bs-toggle="modal" data-bs-target="#modalPlant"></i>
                <i class="fa-solid fa-file-import" v-if="this.$store.state.me.is_superuser" @click="importDataPlant(plant)" ></i>
            </a>
            <div class="collapse" :id="plant.name.replaceAll(' ', '_').replaceAll('.', '_').replaceAll('&', '_').replaceAll('/', '_').replaceAll('(', '_').replaceAll(')', '_')">
                <ul class="nav flex-column offset-1" v-if="showPlantComponents">
                    <li class="nav-item" v-if="canEditPlantUserMapping">
                        <a class="nav-link" :class="{'active': this.currentSubList === 'plant_user_mapping' && this.currentPlant === plant}" href="#" @click="editPlantUserMapping(plant)">
                            {{ $t("plant_user_mapping") }}
                        </a>
                    </li>
                    <li class="nav-item" v-if="showPlantComponents">
                        <a class="nav-link nav-toggle collapsed" href="#" 
                            data-bs-toggle="collapse" :class="{'active': this.currentSubList === 'components' && this.currentPlant === plant}" :data-bs-target="'#component'+plant.name.replaceAll(' ', '_').replaceAll('.', '_').replaceAll('&', '_').replaceAll('/', '_').replaceAll('(', '_').replaceAll(')', '_')" aria-expanded="true" @click="setSubList('components')">
                            {{ $t("components") }}
                        </a>
                    </li>
                    <div class="collapse" :id="'component'+plant.name.replaceAll(' ', '_').replaceAll('.', '_').replaceAll('&', '_').replaceAll('/', '_').replaceAll('(', '_').replaceAll(')', '_')">
                        <ul class="nav flex-column ps-3" >
                            <li class="nav-item" v-if="this.canCreateComponent">
                                <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#modalPlant" @click="newComponent(plant)"><i class="fa-solid fa-plus" ></i> {{ $t("addComponent") }}</a>
                            </li>
                            <li class="nav-item" v-for="component in plant.plant_components"
                                v-bind:key="component.id">
                                <a class="nav-link" :class="{'active': this.currentComponent === component}" href="#" @click="setComponent(plant, component)" v-tooltip :title="getTooltip(component)">
                                    {{component.name}} <template v-if="!component.has_actual_data"><small class="text-danger"><i class="fa-solid fa-exclamation"></i></small></template>
                                    <i class="fa-solid fa-pencil" v-if="this.canWriteComponent" @click="editComponent(component)" data-bs-toggle="modal" data-bs-target="#modalPlant"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <li class="nav-item" v-if="showPlantEvaluations && this.$store.state.me.is_superuser">
                        <a class="nav-link nav-toggle collapsed" href="#" 
                            data-bs-toggle="collapse" :class="{'active': this.currentSubList === 'plant_evaluations' && this.currentPlant === plant}" :data-bs-target="'#plant_evaluations'+plant.name.replaceAll(' ', '_').replaceAll('.', '_').replaceAll('&', '_').replaceAll('/', '_').replaceAll('(', '_').replaceAll(')', '_')" aria-expanded="true" @click="setSubList('plant_evaluations')">
                            {{ $t("plant_evaluations") }}
                        </a>
                    </li>
                    <div class="collapse" :id="'plant_evaluations'+plant.name.replaceAll(' ', '_').replaceAll('.', '_').replaceAll('&', '_').replaceAll('/', '_').replaceAll('(', '_').replaceAll(')', '_')">
                        <ul class="nav flex-column ps-3" >
                            
                            <li class="nav-item" v-for="evaluation in plant.plant_evaluations"
                                v-bind:key="evaluation.id">
                                <a class="nav-link" :class="{'active': this.currentEvaluation === evaluation}" href="#" @click="setEvaluation(plant, evaluation)">
                                    {{evaluation.name}} 
                                    
                                </a>
                            </li>
                        </ul>
                    </div>
                </ul>
            </div>
        </li>   
    </ul>       
</template>
<script>
import axios from 'axios'
import AddPlant from '@/components/masterdata/AddPlant.vue'
import UpdatePlant from '@/components/masterdata/UpdatePlant.vue'
export default {
    name: 'NavPlantsSidebar',
    emits: [
        'setPlant',
        'updatePlant',
        'editPlant',
        'newPlant',
        'newComponent',
        'setComponent',
        'editComponent',
        'editPlantUserMapping',
        'setEvaluation'
            ],
    components: {
        AddPlant,
        UpdatePlant
        },
    props: {
        plants: {
            type: Object,
            required: true
        },
        showPlantComponents:{
            type: Boolean,
            required: false,
            default: false
        },
        showPlantEvaluations:{
            type: Boolean,
            required: false,
            default: false
        },
        editPlantComponents:{
            type: Boolean,
            required: false,
            default: false
        },
        editPlants:{
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            currentPlant: "",
            currentComponent: "",
            currentEvaluation: "",
            currentSubList: "",
        }
    },
    mounted(){
    },
    updated(){
        
    },
    methods: {
        importDataPlant(plant){
            console.log('entry importDataPlant')
            axios
                .post(`/api/v1/plants/${plant.id}/import_data/`)
                .then(response => {
                    console.log('plant imported')
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })

            console.log('exit importDataPlant')
        },
        getTooltip(component){
            if(!component.has_actual_data){
                return this.$t("component_no_data")
            }
            return ""
        },
        loadCompletePlant(plant){
            console.log('entry loadCompletePlant')
            axios
                .get(`/api/v1/plants/${plant.id}/`)
                .then(response => {
                    console.log('plant loaded')
                    console.log(response.data)
                    this.$store.commit('updatePlant', response.data)
                    this.$emit('updatePlant', response.data)
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit loadCompletePlant')
        },
        
        editPlantUserMapping(plant){
            console.log('entry editPlantUserMapping')
            this.currentSubList = 'plant_user_mapping'
            this.currentComponent = ""
            this.currentEvaluation = ''
            this.currentPlant = plant
            this.$emit('editPlantUserMapping', plant)
            console.log('exit editPlantUserMapping')
        },
        setSubList(subList){
            console.log('entry setSubList: ' +subList)
            this.currentSubList = subList
            console.log('exit setSubList')
        },
        editPlant(plant){
            console.log('entry editPlant')
            this.currentPlant = plant
            this.$emit('editPlant', plant)
            console.log('exit editPlant')
        },
        setPlant(plant){
            console.log('entry setPlant')
            if(this.currentPlant != plant){
                if(this.showPlantComponents || this.showPlantEvaluations){
                    this.loadCompletePlant(plant)
                }
                
                this.currentPlant = plant
                this.currentComponent = ""
                this.currentSubList = ""
            }
            
            this.$emit('setPlant', plant)

            console.log('exit setPlant')
        },
        newPlant(){
            console.log('entry newPlant')
            this.$emit('newPlant')
            console.log('exit newPlant')
        },
        newComponent(plant){
            console.log('entry newComponent for plant')
            console.log(plant)
            this.currentPlant = plant
            this.$emit('setPlant', plant)
            this.$emit('newComponent')
            console.log('exit newComponent')
        },
        setComponent(plant, component){
            console.log('entry setComponent')
            this.currentComponent = component
            this.currentEvaluation = ''
            this.currentPlant = plant
            this.currentSubList = 'components'
            this.$emit('setComponent', plant, component)
            console.log('exit setComponent')
        },
        setEvaluation(plant, evaluation){
            console.log('entry setEvaluation')
            this.currentComponent = ''
            this.currentEvaluation =evaluation
            this.currentPlant = plant
            this.currentSubList = 'plant_evaluations'
            this.$emit('setEvaluation', plant, evaluation)
            console.log('exit setEvaluation')
        },
        editComponent(component){
            console.log('entry editComponent')
            this.currentComponent = component
            this.$emit('editComponent', component)
            console.log('exit editComponent')
        },
    },
    computed:{
        
        canCreatePlant(){
            console.log('entry canCreatePlant')
            if(!this.editPlants){
                console.log('view doesnt want to editPlants')
                return false
            }
            console.log('exit canCreatePlant')
            return this.$store.state.me.is_superuser
        },
        canEditPlantUserMapping(){
            console.log('entry canEditPlantUserMapping')
            if(!this.editPlants){
                console.log('view doesnt want to editPlants')
                return false
            }
            console.log('exit canEditPlantUserMapping')
            return this.$store.getters.userHasPermission('plant', 'can_edit_user_mapping')
        },
        canWritePlant(){
            console.log('entry canWritePlant')
            if(!this.editPlants){
                console.log('view doesnt want to editPlants')
                return false
            }
            console.log('exit canWritePlant')
            return this.$store.getters.userHasPermission('plant', 'can_write')
        },
        canCreateComponent(){
            console.log('entry canCreateComponent')
            if(!this.editPlantComponents){
                console.log('view doesnt want to editPlantComponents')
                return false
            }
            console.log('exit canCreateComponent')
            
            return this.$store.state.me.is_superuser
        },
        canWriteComponent(){
            console.log('entry canWriteComponent')
            if(!this.editPlantComponents){
                console.log('view doesnt want to editPlantComponents')
                return false
            }
            console.log('exit canWriteComponent')
            return this.$store.state.me.is_superuser //this.$store.getters.userHasPermission('plant', 'can_write')
        },
        currentProperties(){
                return {organisation: this.$store.getters.getOrganisation, plant: this.currentPlant}
            },
    }
}
</script>