<template>
    <div class="page-add-live-data-position">

        <div class="container-fluid my-3 py-3">
            <div class="row my-3">
                <div class="col-8">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="liveDataHeaderPosition.name" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                    </div>
                </div>                
            </div>
            <div class="row mt-2" v-if="this.plantDescriptions.length === 0">
                {{ $t("loading plants") }}
            </div>
            <div class="row mt-2" v-for="plant in this.plantDescriptions.plants"
            v-bind:key="plant.id">
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputPlant" type="text" name="name" class="form-control"  v-model="plant.name" placeholder="name@example.com" disabled>
                        <label for="inputPlant" class="form-label" >{{ $t("plant") }}</label>
                    </div>
                    
                </div>
                <div class="col-6">
                    <select class="form-select " aria-label="Default select example" v-model="plant.act_component_data_description">
                        <option  v-for="description in plant.data_description" 
                        v-bind:key="description.id" v-bind:value="description.id">{{description.col_description}}</option>
                    </select>
                </div>
            </div>
            <button class="btn btn-success mt-2 mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'AddLiveDataPosition',
    emits: ['closeModal'],
    props: {
        organisation: {
            type: Object,
            required: true,
        },
        liveDataHeader: {
            type: Object,
            required: true,
        },
        position: {
            type: Number,
            required: true,
        },
        liveDataId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            liveDataHeaderPosition: {
                name: ''
            },
            plantDescriptions: []
        }
    },
    mounted(){
        this.getPlantDescriptions()
    },
    methods: {
        getPlantDescriptions(){
            console.log('entry getPlantDescriptions')
            axios
                .get(`/api/v1/organisations/${this.organisation.id}/${this.liveDataId}/0/get_plant_descriptions/`)
                .then(response => {
                    this.plantDescriptions = response.data
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantDescriptions')
        },
        submitForm() {
            this.liveDataHeaderPosition.organisation_live_data_header = this.liveDataHeader.id
            this.liveDataHeaderPosition.created_by = this.$store.state.me.id
            this.liveDataHeaderPosition.modified_by = this.$store.state.me.id
            this.liveDataHeaderPosition.position = this.position
            this.liveDataHeaderPosition.component_data_description = []

            this.liveDataHeaderPosition.component_data_description.length = 0
            this.plantDescriptions.plants.forEach(element => {
                console.log('element:')
                console.log(element)
                if(element.act_component_data_description){
                    this.liveDataHeaderPosition.component_data_description.push(element.act_component_data_description)
                }
            })
            axios
                .post(`/api/v1/organisation_live_data_positions/`, this.liveDataHeaderPosition)
                .then(response => {
        
                    createToast({text: this.$t("header_position added")
                        ,type: 'success'})
                    this.liveDataHeaderPosition.name = ''

                this.$emit('closeModal', 'add', response.data)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    },
    computed:{
    }
}
</script>