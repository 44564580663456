<template>
    <div class="live-data-id mt-2 scroll-y">
        <table class="table table-striped table-hover table-bordered">
            <thead>
                <tr>
                    <template
                          v-for="head in liveDataHeader.headers"
                          v-bind:key="head.name"
                    >
                        <th :colspan="head.colspan" :rowspan="head.rowspan" class="text-center">
                          {{head.name}}
                        </th>
                      
                    </template>
                </tr>
                <tr>
                    <template
                          v-for="position in liveDataHeader.positions"
                          v-bind:key="position.name"
                    >
                        <th class="" >
                          {{position.name}}
                        </th>
                      
                    </template>
                </tr>
            </thead>
            <tbody>
                <template
                    v-for="plant in liveDataPlants"
                    v-bind:key="plant.id"
                >
                    <tr @click="openInfoWindow(plant)">
                        <td class="cursor-pointer">
                            {{plant.name}}
                        </td>
                        <template
                            v-for="value in plant.values"
                            v-bind:key="value.id"
                        >
                            <td :class="{ 'td-limit-reached': isLimitReached(value)}">
                                {{value.value}}
                                <i class="fa-solid fa-down-long" v-if="isLimitLowReached(value)"></i>
                                <i class="fa-solid fa-up-long" v-if="isLimitUpperReached(value)"></i>
                                <template v-if="value.created_at">
                                    <small><br>
                                    ({{ getTimeFormatted(value.created_at) }})</small>
                                </template>

                            </td>
                        </template>
                    </tr>
                </template>
            </tbody>
  
        </table>
    </div>

    <button id="modalOpen" style="display:none;" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modal" aria-hidden="true">hidden</button>

    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content ">
            <div class="modal-header">
                <h5 class="modal-title" id="modalLabel">{{currentPlant.name}}</h5>
                
                <button type="button" id="modalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetComponent"></button>
            </div>
            <div class="modal-body modal-textarea">
                <template v-if="currentOverviewComponent != ''">
                    <component ref="overview" :is="currentOverviewComponent" v-bind="currentProperties" v-on:closeModal="closeModal" v-on:updateTime="updateTime"></component>
                </template>
            </div>
            <div class="modal-footer justify-content-between">
                <p class="small">{{ $t("updatedAt") }} {{time}}</p>
                <div class="footer-buttons justify-content-between">
                    <button type="button" class="btn btn-outline-secondary" @click="generatePdf('test')"><i class="fa-solid fa-file-pdf"></i> {{ $t("export_pdf") }}</button>
                    <button type="button" class="btn btn-outline-secondary" @click="generateExcel()"><i class="fa-solid fa-file-excel"></i> {{ $t("export_excel") }}</button>
                </div>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="resetComponent">{{ $t("close") }}</button>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { createToast } from '@/assets/js/myToast.js'
  
  import BiogasOverview from '@/components/dashboard/BiogasOverview.vue'
  import axios from 'axios'
  export default {
      name: 'LiveDataForId',
      props: {
        liveDataId: {
            type: Number,
            required: true,
            },
        },
        components: {
            BiogasOverview
        },
      data() {
          return {
              liveData: {},
              liveDataHeader: {},
              liveDataPlants: {},
              polling: null,
              isStartup: true,
              loading: false,
              liveDataOld: 0,
              time: '',
              currentPlant: {},
              currentOverviewComponent: '',
              infoWindowOpen: false
          }
      },
      async mounted(){
          await this.getLiveDataHeader()
          this.pollData()
      },
      updated(){
        console.log('entry updated')
        if(this.liveDataOld === 0){
            this.liveDataOld = this.liveDataId
        }
        if(this.liveDataOld != this.liveDataId){

            this.getLiveDataHeader()
            this.liveDataOld = this.liveDataId
        }
        console.log('this.liveDataId: ' + this.liveDataId)
        console.log('exit updated')
    },
      unmounted(){
          console.log('component unmounted')
          
          clearInterval(this.polling)
      },
      methods: {
        getTimeFormatted(time){
            if(time){
                let updatedTime = new Date(time)
                var options = { month: '2-digit', year: '2-digit', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
                return updatedTime.toLocaleString('de-DE', options)

            }
            return ''
        },
        generateExcel(){
            console.log('entry generateExcel')
            this.$refs.overview.getDataForPlantExcel()
            console.log('exit generateExcel')
        },
        generatePdf(){
            console.log('entry generatePDF')
            this.$refs.overview.getDataForPlantPdf()
            console.log('exit genetarePdf')
        },
        isLimitLowReached(value){
            if(!value.limit_low)
                return false
            
            return Number(value.value) <= Number(value.limit_low)
        },  
        isLimitUpperReached(value){
            if(!value.limit_high)
                return false
            
            return Number(value.value) >= Number(value.limit_high)
        }, 
        isLimitReached(value){
            if(!value.limit_low || !value.limit_high){
                return false
            }
            return Number(value.value) <= Number(value.limit_low) || Number(value.value) >= Number(value.limit_high)
        },
            pollData () {
              
                this.polling = setInterval(() => {
                    console.log('timer hat gefeuert. erstmal schlafen')
                    setTimeout(() => {
                        if(!this.infoWindowOpen){
                            this.getLiveData()
                        }
                      
                    }, 2000)
                }, process.env.VUE_APP_REFRESH_INTERVALL_OVERVIEW)
  
            },
            getLiveDataHeader(){
                console.log('entry getLiveDataHeader: ' )
                axios
                    .get(`/api/v1/live_data/${this.liveDataId}/get_live_data_headers/`)
                    .then(response => {
                        console.log('fertig')
                        console.log(response.data)
                        this.processDataHeader(response.data)
                        this.getLiveData()
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error)) 
                        createToast({text: this.$t("error_loading_live_data_header")
                        ,type: 'error'})
                    })
                console.log('exit getLiveDataHeader')
            },
            getLiveData(){
                console.log('entry getLiveData: ')
                console.log('length: ' + this.liveDataPlants.length)
                if(this.liveDataPlants.length === undefined){
                    return
                }
                console.log(this.liveDataPlants)
                this.liveDataPlants.forEach((value,index) =>{
                    console.log('anfrage fuer plant: ')
                    console.log(value)
                    axios
                        .get(`/api/v1/live_data/${this.liveDataId}/get_live_data/${value.id}`)
                        .then(response => {
                            console.log('fertig')
                            console.log(response.data)
                            this.processData(response.data, value)
                        })
                        .catch(error => {
                            console.log(JSON.stringify(error))
                            createToast({text: this.$t("error_loading_live_data")
                            ,type: 'error'})
                        })
                })
              
                console.log('exit getLiveData')
            },
            processDataHeader(data){
                console.log('entry processDataHeader')
                this.liveDataHeader = data
                this.liveDataPlants = data.plants
                console.log('exit processDataHeader')
            },
            processData(data, plant){
                console.log('entry processData: ' +plant.name)
                this.liveDataPlants.forEach((value,index) =>{
                    if(value.id === plant.id){
                        console.log('zuweisung gefunden')
                        value.values = data.values
                    }
                })

                console.log('exit processData')
            },
            updateTime(time){
                this.time = time
            },
            closeModal(){
                console.log("closeModal")
                document.getElementById('modalClose').click();
                this.resetComponent()
            },
            openInfoWindow(plant){
                console.log('entry openInfoWindow')
                console.log('plant: ')
                console.log(plant)
                this.currentPlant = plant
                this.currentOverviewComponent = 'BiogasOverview'
                this.infoWindowOpen = true
                document.getElementById('modalOpen').click();
                console.log('exit openInfoWindow')
            },
            resetComponent(){
                console.log('entry resetComponent')
                this.currentOverviewComponent = ''
                this.infoWindowOpen = false
                this.getLiveData()
                console.log('exit resetComponent')
            },
        },
        computed:{
            currentProperties(){
            
                return {plant: this.currentPlant}
            },
            getModalName(){
            
            }
        }
    }
  </script>
  