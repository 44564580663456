<template>
    <div class="my-3">
        <div class="row">
            <div class="col-6">
                <div class="card shadow-sm">
                    <div class="card-header d-flex align-items-center">
                        <p class="text-center h5">{{ $t("plantsystem_orga_mapping") }} - {{plantSystem.name}}</p>
                            
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <template
                                v-for="organs in getAllSystemOrganisationsFormatted"
                                v-bind:key="organs.id"
                            >
                                <div :class="{'col-6': getAllSystemOrganisationsFormatted.length > 1, 'col': getAllSystemOrganisationsFormatted.length === 1}">
                                    <template
                                        v-for="orga in organs"
                                        v-bind:key="orga.id"
                                    >
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" :value="orga.id" :id="orga.id" v-model="plantSystem.organisations">
                                            <label class="form-check-label" :for="orga.id" >
                                                {{orga.name}}
                                            </label>
                                        </div>

                                    </template>
                                </div>
                        
                            </template>
                        </div>
                        <div class="text-end">
                            <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'EditSystemOrganisationMapping',
    props: {
        plantSystem: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            allOrganisations: []
        }
    },
    async mounted(){
       await this.getAllOrganisations()
    },
    updated(){
        
    },
    methods: {
        getAllOrganisations(){
            console.log('entry getAllOrganisations')
            axios
                .get('/api/v1/allOrganisations/')
                .then(response => {
                    this.allOrganisations = response.data
                    console.log('organisations fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getAllOrganisations')
        },
        submitForm() {
            console.log('entry submitForm')
            axios
                .patch(`/api/v1/plantsystems/${this.plantSystem.id}/`, this.plantSystem)
                .then(response => {
                    createToast({text: this.$t("plantsystem_altered")
                    ,type: 'success'})
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit submitForm')
        }
    },
    computed:{
        getAllSystemOrganisationsFormatted(){
            console.log('entry getAllSystemOrganisationsFormatted')
            console.log('activeComponent:')
            console.log(this.allOrganisations)
            let array_length = this.allOrganisations.length
            let formatted_array = []

            if(array_length < 2){
                formatted_array[0] = this.allOrganisations
            }else{
                let orgas = this.allOrganisations.slice(0, Math.round(array_length/2))
                let orgas2 = this.allOrganisations.slice(Math.round(array_length/2), array_length)
                formatted_array[0] = orgas
                formatted_array[1] = orgas2
                console.log('orgas')
                console.log(orgas)
                console.log('orgas2')
                console.log(orgas2)
            }
            console.log('formatted_array')
            console.log(formatted_array)
            console.log('exit getAllSystemOrganisationsFormatted')
            return formatted_array
        },
    }
}
</script>