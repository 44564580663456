<template>
    <ul class="nav nav-sidebar flex-column ms-2">
        <li class="nav-item" v-if="$store.state.me.is_superuser && this.editPlantType">
            <a class="nav-link" href="#" @click="newType()"><i class="fa-solid fa-plus" ></i> {{ $t("new_type") }}</a>
        </li>
        <li class="nav-item" v-for="typ in plantTypes"
            v-bind:key="typ.name">
            <a class="nav-link nav-toggle collapsed" :class="{'active': this.currentType === typ}" href="#" @click="setType(typ)"
            data-bs-toggle="collapse" :data-bs-target="'#'+typ.name.replaceAll(' ', '_').replaceAll('/', '_').replaceAll('(', '_').replaceAll(')', '_')" aria-expanded="true"
            >
            <img class="me-2" :src="require('@/assets/icons/'+ typ.name + '.png')"/>{{typ.name}}
                <i class="fa-solid fa-pencil" v-if="$store.state.me.is_superuser && this.editPlantType" @click="editType(typ)"></i>
            </a>

            <div class="collapse show" :id="typ.name.replaceAll(' ', '_').replaceAll('/', '_').replaceAll('(', '_').replaceAll(')', '_')">
                <ul class="nav flex-column offset-1" v-if="showTypEvaluation">
                    <li class="nav-item" v-if="$store.state.me.is_superuser && this.createEvaluation">
                        <a class="nav-link" href="#" @click="newEvaluation(typ)"><i class="fa-solid fa-plus" ></i> {{ $t("new_evaluation") }}</a>
                    </li>
                    <li class="nav-item" v-for="evaluation in typ.plant_type_evaluations"
                        v-bind:key="evaluation.id">
                        <a class="nav-link" :class="{'active': this.currentEvaluation === evaluation}" href="#" @click="setEvaluation(typ, evaluation)">
                            {{evaluation.name}}
                            <template v-if="this.createEvaluation"><i class="fa-solid fa-pencil" @click="editEvaluation(evaluation)" data-bs-toggle="modal" data-bs-target="#modalPlantTypeEval"></i></template>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'NavPlantTypesSidebar',
    emits: [
        'setType',
        'newType',
        'editType',
        'newEvaluation',
        'setEvaluation',
        'editEvaluation'
    ],
    components: {
    },
    props: {
        plantTypes: {
            type: Object,
            required: true
        },
        editPlantType:{
            type: Boolean,
            required: false,
            default: false
        },
        showTypEvaluation:{
            type: Boolean,
            required: false,
            default: false
        },
        createEvaluation:{
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            currentType: "",
            currentEvaluation: ""
        }
    },
    mounted(){
        
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
        
    },
    methods: {
        newEvaluation(type){
            console.log('entry newEvaluation')
            console.log(type)
            this.currentType = type
            this.$emit('setType', type)
            this.$emit('newEvaluation')
            console.log('exit newEvaluation')
        },
        setEvaluation(type, evaluation){
            console.log('entry setEvaluation')
            this.currentEvaluation = evaluation
            this.currentType = type
            this.$emit('setEvaluation', type, evaluation)
            console.log('exit setEvaluation')
        },
        editEvaluation(evaluation){
            console.log('entry editEvaluation')
            this.currentEvaluation = evaluation
            this.$emit('editEvaluation', evaluation)
            console.log('exit editEvaluation    ')
        },
        editType(type){
            console.log('entry editType')
            this.currentType = type
            this.$emit('editType', type)
            console.log('exit editType')
        },
        newType(){
            console.log('entry newType')
            this.$emit('newType')
            console.log('exit newType')
        },
        newEvaluation(){
            console.log('entry newEvaluation')
            this.$emit('newEvaluation')
            console.log('exit newEvaluation')
        },
        setType(type){
            console.log('entry setType')
            this.currentType = type
            this.currentEvaluation = ''
            this.$emit('setType', type)
            console.log('exit setType')
        },
       
    },
    computed:{
        
    }
}
</script>